import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStatuteStyles } from 'styles/pages/LargeFamilyStatute';
import { texts } from './texts';

const LargeFamilyStatute = () => {
  const { h1, h2, paragraph, statuteWrapper } = makeStatuteStyles();

  return (
    <Box className={statuteWrapper}>
      <Typography
        className={h1}
        component="h1"
        variant="h1"
        dangerouslySetInnerHTML={{ __html: texts.statute.title }}
      />
      {texts.statute.sections.map(({ id, paragraphs, title }) => (
        <section key={id}>
          <Typography className={h2} component="h2" dangerouslySetInnerHTML={{ __html: title }} />
          {paragraphs.map(({ id, text }) => (
            <Typography
              key={id}
              className={paragraph}
              component="p"
              dangerouslySetInnerHTML={{ __html: text }}
            />
          ))}
        </section>
      ))}
    </Box>
  );
};

export default LargeFamilyStatute;
