import { CreativeIdeas } from './CreativeIdeas';
import { SpendingTimeWithChild } from './SpendingTimeWithChild';
import { WaysOfMotivatingChild } from './WaysOfMotivatingChild';
import { urls } from 'common/urls';

export const texts = {
  [urls.articles.creativeIdeas]: CreativeIdeas,
  [urls.articles.spendingTimeWithChild]: SpendingTimeWithChild,
  [urls.articles.waysOfMotivatingChild]: WaysOfMotivatingChild,
};
