import React, { Fragment } from 'react';
import { Description, Introduction, Tiles } from 'pages/Subject';
import Container from '@material-ui/core/Container';
import { Grid } from '@material-ui/core';
import { getUrlFragment } from 'common/getUrlFragment';
import Wrapper from 'components/Wrapper';
import Box from "@material-ui/core/Box";

const Subject = ({ location }) => {
  const subject = getUrlFragment(location, 2);

  return (
    <Fragment>
      <Container>
        <Grid container justify="center">
          <Grid item xs={11}>
            <Box mb={2}>
              <Introduction subject={subject} />
            </Box>
            <Tiles subject={subject} />
          </Grid>
        </Grid>
      </Container>
      <Wrapper>
        <Description subject={subject} />
      </Wrapper>
    </Fragment>
  );
};

export default Subject;
