import coloring1 from 'assets/download/kolorowanka_1.png';
import coloring2 from 'assets/download/kolorowanka_2.png';
import coloring3 from 'assets/download/kolorowanka_3.png';
import coloring4 from 'assets/download/kolorowanka_4.png';
import coloring5 from 'assets/download/kolorowanka_5.png';
import coloring6 from 'assets/download/kolorowanka_6.png';
import coloring7 from 'assets/download/kolorowanka_7.png';
import coloring8 from 'assets/download/kolorowanka_8.png';
import exercise1 from 'assets/img/cwiczenie_1.jpg';
import exercise2 from 'assets/img/cwiczenie_2.jpg';
import exercise3 from 'assets/img/cwiczenie_3.jpg';
import exercise4 from 'assets/img/cwiczenie_4.jpg';
import exercise5 from 'assets/img/cwiczenie_5.jpg';
import exercise6 from 'assets/img/cwiczenie_6.jpg';
import exercise7 from 'assets/img/cwiczenie_7.jpg';
import exercise8 from 'assets/img/cwiczenie_8.jpg';
import exercise9 from 'assets/img/cwiczenie_9.jpg';
import exercise10 from 'assets/img/cwiczenie_10.jpg';
import pdf1 from 'assets/download/dodawanie_w_zakresie_10.pdf';
import pdf2 from 'assets/download/dodawanie_w_zakresie_10_uzupełnianka.pdf';
import pdf3 from 'assets/download/dodawanie_w_zakresie_20_rozpisywanie.pdf';
import pdf4 from 'assets/download/dodawanie_w_zakresie_30_uzupełnianka.pdf';
import pdf5 from 'assets/download/dodawanie_w_zakresie_30_rozpisywanie.pdf';
import pdf6 from 'assets/download/odejmowanie_w_zakresie_10.pdf';
import pdf7 from 'assets/download/odejmowanie_w_zakresie_20_rozpisywanie.pdf';
import pdf8 from 'assets/download/odejmowanie_w_zakresie_30_rozpisywanie.pdf';
import pdf9 from 'assets/download/mnożenie_i_dzielenie_w_zakresie_20.pdf';
import pdf10 from 'assets/download/mnożenie_i_dzielenie_w_zakresie_30.pdf';
import lessonPlan1 from 'assets/download/plan_lekcji_1.png';
import lessonPlan2 from 'assets/download/plan_lekcji_2.png';

const paths = {
  'plan-lekcji': {
    alt: 'Plan lekcji',
    h2: 'plan lekcji',
    tiles: [
      {
        id: 1,
        download: lessonPlan1,
        filename: 'plan_lekcji.png',
        src: lessonPlan1,
      },
      {
        id: 2,
        download: lessonPlan2,
        filename: 'plan_lekcji.png',
        src: lessonPlan2,
      },
    ],
  },
  kolorowanki: {
    alt: 'Kolorowanka',
    h2: 'kolorowankę',
    tiles: [
      {
        id: 1,
        download: coloring1,
        filename: 'kolorowanka.png',
        src: coloring1,
      },
      {
        id: 2,
        download: coloring2,
        filename: 'kolorowanka.png',
        src: coloring2,
      },
      {
        id: 3,
        download: coloring3,
        filename: 'kolorowanka.png',
        src: coloring3,
      },
      {
        id: 4,
        download: coloring4,
        filename: 'kolorowanka.png',
        src: coloring4,
      },
      {
        id: 5,
        download: coloring5,
        filename: 'kolorowanka.png',
        src: coloring5,
      },
      {
        id: 6,
        download: coloring6,
        filename: 'kolorowanka.png',
        src: coloring6,
      },
      {
        id: 7,
        download: coloring7,
        filename: 'kolorowanka.png',
        src: coloring7,
      },
      {
        id: 8,
        download: coloring8,
        filename: 'kolorowanka.png',
        src: coloring8,
      },
    ],
  },
  cwiczenia: {
    alt: 'Ćwiczenie do pobrania',
    h2: 'ćwiczenia',
    tiles: [
      {
        id: 1,
        download: pdf1,
        filename: 'Dodawanie w zakresie 10.pdf',
        src: exercise1,
      },
      {
        id: 2,
        download: pdf2,
        filename: 'Dodawanie w zakresie 10 - uzupełnianka.pdf',
        src: exercise2,
      },
      {
        id: 3,
        download: pdf3,
        filename: 'Dodawanie w zakresie 20 - rozpisywanie.pdf',
        src: exercise3,
      },
      {
        id: 4,
        download: pdf4,
        filename: 'Dodawanie w zakresie 30 - uzupełnianka.pdf',
        src: exercise4,
      },
      {
        id: 5,
        download: pdf5,
        filename: 'Dodawanie w zakresie 30 - rozpisywanie.pdf',
        src: exercise5,
      },
      {
        id: 6,
        download: pdf6,
        filename: 'Odejmowanie w zakresie 10.pdf',
        src: exercise6,
      },
      {
        id: 7,
        download: pdf7,
        filename: 'Odejmowanie w zakresie 20 - rozpisywanie.pdf',
        src: exercise7,
      },
      {
        id: 8,
        download: pdf8,
        filename: 'Odejmowanie w zakresie 30 - rozpisywanie.pdf',
        src: exercise8,
      },
      {
        id: 9,
        download: pdf9,
        filename: 'Mnożenie i dzielenie w zakresie 20.pdf',
        src: exercise9,
      },
      {
        id: 10,
        download: pdf10,
        filename: 'Mnożenie i dzielenie w zakresie 30.pdf',
        src: exercise10,
      },
    ],
  },
};

export const texts = path => ({
  alt: paths[path].alt,
  introduction: {
    h1: 'Darmowe materiały edukacyjne dla Twojego dziecka',
    h2: `Kliknij wybrany obrazek, aby pobrać ${paths[path].h2}.`,
  },
  tiles: [...paths[path].tiles],
});
