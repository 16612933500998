import { History } from 'common/texts';
import { Informatics } from 'common/texts';
import { Informatyka } from 'common/texts';
import { Math } from 'common/texts';
import { Histlandia } from 'common/texts';
import { Matlandia } from 'common/texts';
import { MatlandiaJunior } from 'common/texts';
import { Polish } from 'common/texts';
import { PowtorkoMat } from 'common/texts';
import { WladcySlow } from 'common/texts';

export const texts = {
  subjects: {
    historia: {
      h1: 'Najlepsze ćwiczenia z&nbsp;historii<br/>dla dzieci w&nbsp;wieku 9–14 lat',
      programs: [
        {
          id: 1,
          ...Histlandia().programPresentation,
          subtitleExtension: 'szkoła podstawowa,<br>wiek 9–14 lat',
        },
      ],
      programsDescriptions: [...History],
    },
    informatyka: {
      h1: 'Informatyka',
      h2: 'Nauka programowania dla dzieci w&nbsp;wieku 6–9&nbsp;lat',
      programs: [
        {
          id: 1,
          ...Informatyka().programPresentation.common,
          buyLink: 'https://gwo.pl/ksiegarnia/search?categories%5B552%5D=1&s=informatyka&limit=10',
          description: `Pracując z&nbsp;<i>Informatyką</i>, dzieci poznają podstawy programowania 
            (m.in. w&nbsp;języku Scratch), a&nbsp;także uczą się sprawnego posługiwania się myszką, 
            szybkiego pisania na klawiaturze oraz pracy z&nbsp;edytorami tekstu i&nbsp;grafiki.
          `,
          subtitleExtension: 'klasy 1, 2 i 3 szkoły podstawowej<br>wiek 6–9 lat',
        },
      ],
      programsDescriptions: [...Informatics],
    },
    'jezyk-polski': {
      h1: 'Język polski',
      h2: 'Najlepsze ćwiczenia z języka polskiego <br/>dla dzieci w wieku 8–12 lat',
      programs: [
        {
          id: 1,
          ...WladcySlow().programPresentation,
          subtitleExtension: 'klasy 4, 5 i 6 szkoły podstawowej<br>wiek 9–12 lat',
        },
      ],
      programsDescriptions: [...Polish],
    },
    matematyka: {
      h1: 'Matematyka',
      h2: 'Najlepsze gry matematyczne i logiczne <br/>dla dzieci wieku 6–14 lat',
      programs: [
        {
          id: 1,
          ...MatlandiaJunior().programPresentation,
          subtitleExtension: 'klasy 1, 2 i 3 szkoły podstawowej<br>wiek 6–9 lat',
        },
        {
          id: 2,
          ...Matlandia().programPresentation.common,
          buyLink: 'https://gwo.pl/ksiegarnia/search?s=matlandia&limit=10',
          subtitleExtension: 'klasy 4, 5, 6, 7 i 8 szkoły podstawowej<br>wiek 9–14 lat',
        },
        {
          id: 3,
          ...PowtorkoMat().programPresentation.common,
          buyLink: 'https://gwo.pl/ksiegarnia/search?s=powt%C3%B3rkomat&limit=10',
          description: `Program umożliwia powtórki z&nbsp;matematyki oraz przygotowuje do egzaminu ósmoklasisty. 
          Uczy, jak rozwiązywać różne typy zadań i pozwala na szybkie sprawdzenie zdobytych umiejętności. `,
          price: '30 zł na rok | bezpłatna przesyłka',
          subtitleExtension: 'klasy 6 i 8 szkoły podstawowej<br>wiek 11–14 lat',
        },
      ],
      programsDescriptions: [...Math],
    },
  },
};
