import Advantages1 from 'assets/img/advantages_1.svg';
import Advantages2 from 'assets/img/advantages_2.svg';
import Advantages3 from 'assets/img/advantages_3.svg';
import Advantages4 from 'assets/img/advantages_4.svg';
import Advantages5 from 'assets/img/advantages_5.svg';
import Advantages6 from 'assets/img/advantages_6.svg';
import BigFamilyCard from 'assets/img/karta-duzej-rodziny.png';

export const texts = {
  advantages: {
    blocks: [
      {
        id: 1,
        img: Advantages1,
        text: 'ponad <b>2300<br/>animowanych&nbsp;ćwiczeń</b>',
      },
      {
        id: 2,
        img: Advantages2,
        text: 'materiał przygotowany przez <b>doświadczonych nauczycieli</b>',
      },
      {
        id: 3,
        img: Advantages3,
        text: '<b>zmieniające się przykłady</b> w&nbsp;zadaniach',
      },

      {
        id: 4,
        img: Advantages4,
        text: 'motywujące do nauki <b>komentarze animowanych bohaterów i&nbsp;medale</b>',
      },
      {
        id: 5,
        img: Advantages5,
        text: '<b>natychmiastowa informacja</b>, czy zadanie zostało zrobione dobrze czy źle',
      },
      {
        id: 6,
        img: Advantages6,
        text: 'możliwość <b>kontroli wyników</b> dziecka',
      },
    ],
    header: 'Zalety eduGier',
    tiles: [
      {
        id: 1,
        className: 'tile-left',
        header: 'Bezpieczna nauka i&nbsp;zabawa',
        text: `W naszych eduGrach nie ma żadnych reklam, a&nbsp;zawarte w&nbsp;każdej eduGrze
        treści są dopasowane do wieku gracza.`,
      },
      {
        id: 2,
        className: 'tile-middle',
        header: 'Niska cena i&nbsp;bezpłatna przesyłka',
        text: `Roczny dostęp do jednej eduGry dla jednego dziecka kosztuje maksymalnie
        48 zł (czyli <b>tylko 4 zł na miesiąc!</b>). Ponieważ eduGry to produkty elektroniczne,
        nic nie płacisz za&nbsp;przesyłkę.`,
        img: {
          src: BigFamilyCard,
          alt: 'Karta Dużej Rodziny',
        },
      },
      {
        id: 3,
        additionalText: `* Systemy operacyjne, na których można uruchomić nasze eduGry:
        Windows, OS X oraz Linux. Na komputerze powinna być zainstalowana najnowsza wersja przeglądarki
        internetowej.`,
        className: 'tile-right',
        header: 'Prosta obsługa',
        text: `Nasze eduGry nie wymagają instalacji – wystarczy komputer* z&nbsp;dostępem do&nbsp;internetu!
        Aby rozwiązywać zadania, dziecko loguje się na naszej stronie za&nbsp;pomocą swojego loginu
        (lub adresu e-mail) oraz hasła.`,
      },
    ],
  },
  introduction: {
    h1: 'Poznaj najlepsze gry edukacyjne&nbsp;online<br/>dla dzieci',
    h2: `Nasze eduGry to animowane zadania z&nbsp;<b>matematyki</b>,
      <b>języka&nbsp;polskiego</b> oraz <b>informatyki</b> dla dzieci
      z&nbsp;klas 1–8 (wiek 6–14 lat). Każda eduGra zamienia naukę w&nbsp;pełną emocji
      zabawę i&nbsp;pomaga uzyskać lepsze wyniki.`,
    childrenQuantity: `Od 2006 roku z naszych eduGier
      <br />
      skorzystało ponad 900 000 dzieci!`,
  },
};
