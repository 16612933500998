import matlandiaJuniorScreen1 from 'assets/img/Matlandia_Junior/zadania-matematyczne-dla-klasy-1-mj-1.png';
import matlandiaJuniorScreen2 from 'assets/img/Matlandia_Junior/zadania-matematyczne-dla-klasy-1-mj-2.png';
import matlandiaJuniorScreen3 from 'assets/img/Matlandia_Junior/zadania-matematyczne-dla-klasy-2-mj-3.png';
import matlandiaJuniorScreen4 from 'assets/img/Matlandia_Junior/zadania-matematyczne-dla-klasy-2-mj-4.png';
import matlandia4Screen1 from 'assets/img/Matematyka/Matlandia_4/zadania-matematyczne-klasa-4-m1.jpg';
import matlandia4Screen2 from 'assets/img/Matematyka/Matlandia_4/zadania-matematyczne-klasa-4-m2.jpg';
import matlandia4Screen3 from 'assets/img/Matematyka/Matlandia_4/zadania-matematyczne-klasa-4-m3.jpg';
import matlandia4Screen4 from 'assets/img/Matematyka/Matlandia_4/zadania-matematyczne-klasa-4-m4.jpg';
import matlandia5Screen1 from 'assets/img/Matematyka/Matlandia_5/zadania-matematyczne-klasa-5-m1.jpg';
import matlandia5Screen2 from 'assets/img/Matematyka/Matlandia_5/zadania-matematyczne-klasa-5-m2.jpg';
import matlandia5Screen3 from 'assets/img/Matematyka/Matlandia_5/zadania-matematyczne-klasa-5-m3.jpg';
import matlandia5Screen4 from 'assets/img/Matematyka/Matlandia_5/zadania-matematyczne-klasa-5-m4.jpg';
import matlandia6Screen1 from 'assets/img/Matematyka/Matlandia_6/zadania-matematyczne-klasa-6-m1.jpg';
import matlandia6Screen2 from 'assets/img/Matematyka/Matlandia_6/zadania-matematyczne-klasa-6-m2.jpg';
import matlandia6Screen3 from 'assets/img/Matematyka/Matlandia_6/zadania-matematyczne-klasa-6-m3.jpg';
import matlandia6Screen4 from 'assets/img/Matematyka/Matlandia_6/zadania-matematyczne-klasa-6-m4.jpg';
import matlandia7Screen1 from 'assets/img/Matematyka/Matlandia_7/zadania-matematyczne-klasa-7-m1.jpg';
import matlandia7Screen2 from 'assets/img/Matematyka/Matlandia_7/zadania-matematyczne-klasa-7-m2.jpg';
import matlandia7Screen3 from 'assets/img/Matematyka/Matlandia_7/zadania-matematyczne-klasa-7-m3.jpg';
import matlandia7Screen4 from 'assets/img/Matematyka/Matlandia_7/zadania-matematyczne-klasa-7-m4.jpg';
import matlandia8Screen1 from 'assets/img/Matematyka/Matlandia_8/zadania-matematyczne-klasa-8-m1.jpg';
import matlandia8Screen2 from 'assets/img/Matematyka/Matlandia_8/zadania-matematyczne-klasa-8-m2.jpg';
import matlandia8Screen3 from 'assets/img/Matematyka/Matlandia_8/zadania-matematyczne-klasa-8-m3.jpg';
import matlandia8Screen4 from 'assets/img/Matematyka/Matlandia_8/zadania-matematyczne-klasa-8-m4.jpg';
import powtorkomat6Screen1 from 'assets/img/Powtorkomat/Powtorkomat_6/zadania-powtorkowe-klasa6-powtorkomat-1.jpg';
import powtorkomat6Screen2 from 'assets/img/Powtorkomat/Powtorkomat_6/zadania-powtorkowe-klasa6-powtorkomat-2.jpg';
import powtorkomat6Screen3 from 'assets/img/Powtorkomat/Powtorkomat_6/zadania-powtorkowe-klasa6-powtorkomat-3.jpg';
import powtorkomat6Screen4 from 'assets/img/Powtorkomat/Powtorkomat_6/zadania-powtorkowe-klasa6-powtorkomat-4.jpg';
import powtorkomat8Screen1 from 'assets/img/Powtorkomat/Powtorkomat_8/zadania-powtorkowe-do-egzaminu-osmoklasisty-matematyka-8-1.jpg';
import powtorkomat8Screen2 from 'assets/img/Powtorkomat/Powtorkomat_8/zadania-powtorkowe-do-egzaminu-osmoklasisty-matematyka-8-2.jpg';
import powtorkomat8Screen3 from 'assets/img/Powtorkomat/Powtorkomat_8/zadania-powtorkowe-do-egzaminu-osmoklasisty-matematyka-8-3.jpg';
import powtorkomat8Screen4 from 'assets/img/Powtorkomat/Powtorkomat_8/zadania-powtorkowe-do-egzaminu-osmoklasisty-matematyka-8-4.jpg';
import informatyka1Screen1 from 'assets/img/Informatyka/Informatyka_1/nauka-programowania-dla-dzieci-inf1-1.png';
import informatyka1Screen2 from 'assets/img/Informatyka/Informatyka_1/nauka-pisania-na-klawiaturze-inf1-2.png';
import informatyka1Screen3 from 'assets/img/Informatyka/Informatyka_1/nauka-programowania-dla-dzieci-inf1-3.png';
import informatyka1Screen4 from 'assets/img/Informatyka/Informatyka_1/cwiczenie-pisania-na-klawiaturze-inf1-4.png';
import informatyka2Screen1 from 'assets/img/Informatyka/Informatyka_2/cwiczenie-pisania-na-klawiaturze-inf2-1.png';
import informatyka2Screen2 from 'assets/img/Informatyka/Informatyka_2/nauka-pisania-na-klawiaturze-inf2-2.png';
import informatyka2Screen3 from 'assets/img/Informatyka/Informatyka_2/nauka-programowania-dla-dzieci-inf2-3.png';
import informatyka2Screen4 from 'assets/img/Informatyka/Informatyka_2/nauka-programowania-dla-dzieci-inf2-4.png';
import informatyka3Screen1 from 'assets/img/Informatyka/Informatyka_3/cwiczenie-pisania-na-klawiaturze-inf3-1.png';
import informatyka3Screen2 from 'assets/img/Informatyka/Informatyka_3/nauka-pisania-na-klawiaturze-inf3-2.png';
import informatyka3Screen3 from 'assets/img/Informatyka/Informatyka_3/nauka-programowania-dla-dzieci-inf3-3.png';
import informatyka3Screen4 from 'assets/img/Informatyka/Informatyka_3/nauka-programowania-dla-dzieci-inf3-4.png';
import wladcySlowScreen1 from 'assets/img/Jezyk_polski/cwiczenia-z-jezyka-polskiego-dla-dzieci-ws1.png';
import wladcySlowScreen2 from 'assets/img/Jezyk_polski/cwiczenia-ortograficzne-online-ws2.png';
import wladcySlowScreen3 from 'assets/img/Jezyk_polski/cwiczenia-ortograficzne-online-ws3.png';
import wladcySlowScreen4 from 'assets/img/Jezyk_polski/cwiczenia-ortograficzne-online-ws4.png';
import Advantages1 from "assets/img/advantages_1.svg";
import Advantages2 from "assets/img/advantages_2.svg";
import Advantages4 from "assets/img/advantages_4.svg";
import Advantages7 from "assets/img/brak_reklam.svg";
import Advantages8 from "assets/img/czytane_naglos_polecenia.svg";
import Advantages9 from "assets/img/jednorazowa_opłata_za_dostep_na_rok_pol_roku_miesiac_lub_tydzien.svg";
import Advantages10 from "assets/img/rankingi_z_wynikami_graczy_z_calej_polski.svg";
import Advantages11 from "assets/img/wirtualne_medale_i_nagrody_za_osiagniecia.svg";
import Advantages12 from "assets/img/wskazowki_do_zadan_i_filmy_z_rozwiazaniami.svg";
import Advantages13 from "assets/img/zegar_odliczajacy_czas_do_konca_rozwiazywania_zestawu_zadan.svg";
import Advantages14 from "assets/img/zroznicowany_poziom_trudnosci.svg";
import matlandiaJuniorpdf from "assets/download/Applications/spis_tresci_matlandia_junior.pdf";
import matlandia4pdf from "assets/download/Applications/Matlandia4_przewodnik_po_zadaniach.pdf";
import matlandia5pdf from "assets/download/Applications/Matlandia5_przewodnik_po_zadaniach.pdf";
import matlandia6pdf from "assets/download/Applications/Matlandia6_przewodnik_po_zadaniach.pdf";
import matlandia7pdf from "assets/download/Applications/Matlandia7_przewodnik_po_zadaniach.pdf";
import matlandia8pdf from "assets/download/Applications/Matlandia8_przewodnik_po_zadaniach.pdf";
import informatyka1pdf from "assets/download/Applications/spis-dzialow-i-tematow-informatyka-1.pdf";
import informatyka2pdf from "assets/download/Applications/spis-dzialow-i-tematow-informatyka-2.pdf";
import informatyka3pdf from "assets/download/Applications/spis-dzialow-i-tematow-informatyka-3.pdf";

export const texts = {
  apps: {
    'matlandia-junior': {
      h1: '<i>Matlandia Junior</i>',
      h2: 'zadania matematyczne dla klasy 1, klasy 2 i klasy 3',
      h3: '38 zł na rok | bezpłatna przesyłka',
      description: `Wciągająca <b>gra edukacyjna <i>Matlandia Junior</i></b> zawiera <b>zadania matematyczne</b> dla dzieci z&nbsp;<b>klasy&nbsp;1</b>, 
      <b>klasy&nbsp;2</b> i&nbsp;<b>klasy&nbsp;3</b> (wiek <b>5–9 lat</b>). Jaki szyfr otwiera skrzynię ze skarbami? Ile waży jajo strusia? Co można 
      kupić za 5&nbsp;złotych? Matematyka towarzyszy dzieciom na każdym kroku, a&nbsp;z&nbsp;<i>Matlandią Junior</i> liczenie staje się 
      wciągającą zabawą! Program <b>doskonali biegłość rachunkową</b>, <b>ćwiczy pamięć</b>, <b>rozwija wyobraźnię przestrzenną</b> oraz 
      <b>umiejętność logicznego myślenia</b>.
      `,
      advantagesDescriptionTitle: `<b>Zalety <i>Matlandii Junior</i></b>`,
      advantagesDescription: `<i>Matlandię Junior</i> polecamy zarówno uzdolnionym dzieciom, jak i&nbsp;tym,
        którym nauka sprawia trudności. Nasza eduGra w&nbsp;prosty i&nbsp;przyjazny sposób <b>pomoże</b> każdemu dziecku
        <b>uzupełnić wiedzę</b>, <b>zdobyć nowe umiejętności</b>, <b>uwierzyć we własne siły i&nbsp;podnieść samoocenę!</b>`,
      pdfButton: matlandiaJuniorpdf,
      gallery: [
        {
          img: matlandiaJuniorScreen1,
          alt: 'Zadania matematyczne dla klasy 1 widok gry Matlandia Junior - liczenie pieniędzy',
        },
        {
          img: matlandiaJuniorScreen2,
          alt: 'Zadania matematyczne dla klasy 1 widok gry Matlandia Junior - miary, linijka',
        },
        {
          img: matlandiaJuniorScreen3,
          alt: 'Zadania matematyczne dla klasy 2 widok gry Matlandia Junior - cyfry rzymskie',
        },
        { img: matlandiaJuniorScreen4,
          alt: 'Zadania matematyczne dla klasy 3 widok gry Matlandia Junior - zegar, ustawianie czasu',
        },
      ],
      reviewLink: 'https://www.szkolneinspiracje.pl/interaktywne-zabawy-matematyczne-dla-klas-i-iii/',
      advantages: [
        {
          id: 1,
          img: Advantages1,
          text: `100 zadań ze zmieniającymi się przykładami`,
        },
        {
          id: 2,
          img: Advantages14,
          text: 'zróżnicowany poziom trudności',
        },
        {
          id: 3,
          img: Advantages8,
          text: 'czytane na głos polecenia',
        },

        {
          id: 4,
          img: Advantages11,
          text: 'wirtualne medale za osiągnięcia',
        },
        {
          id: 5,
          img: Advantages7,
          text: 'brak reklam',
        },
        {
          id: 6,
          img: Advantages9,
          text: 'jednorazowa opłata za dostęp na rok, pół roku, miesiąc lub tydzień',
        },
      ],
      demoLink: 'https://matlandiajunior.apps.gwo.pl/0',
      buyLink: 'https://ksiegarnia.gwo.pl/product/matlandia-junior',
      descriptionTitle: ``,
      descriptionParagraphs: [
        {
          id: 1,
          subheading: `Nauka poprzez zabawę`,
          text: `
            <b><i>Matlandia Junior</i></b> zawiera <b>zadania matematyczne dla klasy&nbsp;1, klasy&nbsp;2 i klasy&nbsp;3</b> szkoły podstawowej 
            (chociaż dzieciaki chodzące do <b>zerówki</b> też powinny sobie z&nbsp;wieloma zadaniami doskonale poradzić!). 
            <b>Gra edukacyjna online</b> <i>Matlandia Junior</i> w&nbsp;atrakcyjny sposób zachęca dzieci do tego, by sprawdziły swoje 
            umiejętności matematyczne oraz do podejmowania prób, by osiągnąć jeszcze lepsze wyniki: wykonać zadanie
            w&nbsp;jeszcze krótszym czasie, bez żadnych błędów. Nasze matlandiowe zadania matematyczne dla 5-latka, 6-latka, 
            7-latka, 8-latka i&nbsp;9-latka to sprytny sposób na zazwyczaj nudne powtórki i&nbsp;utrwalanie wiedzy. Przy kolejnych 
            uruchomieniach tego samego zadania w&nbsp;<i>Matlandii Junior</i> pojawiają się inne dane. Dzięki temu nie można „wykuć” 
            odpowiedzi – program sprawdza faktyczne opanowanie danego zagadnienia.
          `,
        },
        {
          id: 2,
          subheading: `Motywacja do nauki`,
          text: `
          Dzieci uwielbiają otrzymywać nagrody za podjęty wysiłek. W&nbsp;eduGrze <i>Matlandia Junior</i> dziecko może 
          zdobyć <b>medale za biegłość w&nbsp;rozwiązywaniu zadań</b>. Stanowią one zachętę dla gracza do kolejnych prób polepszenia 
          wyniku. Za każde zadanie można uzyskać brązowy, srebrny lub złoty medal, w&nbsp;zależności od czasu, w&nbsp;jakim je wykonano. 
          Na złoty medal naprawdę trzeba się postarać!`,
        },
        {
          id: 3,
          subheading: null,
          text: `
          Treści wszystkich poleceń czytane są przez lektora, co ułatwia dzieciom ich zrozumienie. Przez nasze zadania matematyczne 
          dla klasy&nbsp;1, klasy&nbsp;2 i&nbsp;klasy&nbsp;3 przeprowadzają dzieci <b>animowani bohaterowie</b> – rezolutny krokodyl i&nbsp;sympatyczny ptaszek. 
          Ich wspierające komentarze oraz sygnały, czy udzielona odpowiedź jest poprawna, zachęcają dzieci do gry i&nbsp;zabawy.`,
        },
        {
          id: 4,
          subheading: `Zagadnienia w <i>Matlandii Junior</i>`,
          text: `
          <i>Matlandia Junior</i> pomaga dzieciom bezstresowo przećwiczyć zagadnienia, których znajomości wymaga się na tym etapie edukacji. 
          Zadania matematyczne dla klasy&nbsp;1, klasy&nbsp;2 i&nbsp;klasy&nbsp;3 podzielone są na siedem działów, a&nbsp;poszczególne ćwiczenia mają oznaczenia 
          trudności. Z&nbsp;<i>Matlandią Junior</i> dzieci przećwiczą takie zagadnienia, jak <b>rozpoznawanie liczb</b>, <b>dodawanie</b>, <b>odejmowanie</b> oraz 
          <b>mnożenie i&nbsp;dzielenie</b> (również: <b>tabliczka mnożenia</b> i&nbsp;<b>dzielenie z&nbsp;resztą</b>). Z&nbsp;naszymi interaktywnymi zadaniami łatwo też można 
          utrwalać umiejętności z zakresu <b>osi liczbowej</b> i <b>cyfr rzymskich</b> czy doskonalić rozpoznawanie <b>liczb parzystych i nieparzystych</b>. 
          W&nbsp;Matlandii Junior znajdują się także praktyczne zadania ćwiczące orientację przestrzenną (m.in. <b>prawa i&nbsp;lewa strona, kierunki 
          świata</b>), <b>odczytywanie godzin na zegarze wskazówkowym i&nbsp;elektronicznym</b>, jak również przybliżające <b>jednostki czasu i&nbsp;zapisywanie 
          dat</b>. W&nbsp;animowanych zadaniach w&nbsp;atrakcyjny dla dziecka sposób poćwiczyć można <b>obliczenia pieniężne</b>, przeliczanie <b>litrów</b>, <b>kilogramów 
          i&nbsp;dekagramów</b> oraz mierzenie <b>centymetrów i&nbsp;milimetrów</b> na linijce.`,
        },
        {
          id: 5,
          subheading: `Bezpieczna nauka i&nbsp;zabawa`,
          text: `
          W&nbsp;<i>Matlandii Junior</i> nie ma żadnych reklam, więc bez obaw można pozwolić dziecku na samodzielną naukę z&nbsp;programem. Treści zawarte w&nbsp;ćwiczeniach 
          zostały stworzone przez doświadczonych specjalistów i&nbsp;są one dopasowane do wieku gracza.`,
        },
      ],
    },
    'matlandia-4': {
      h1: '<i>Matlandia 4</i>',
      h2: 'gra edukacyjna dla dzieci (online)',
      h3: '48 zł na rok | bezpłatna przesyłka',
      description: `<b>Gra na komputer</b> zawierająca <b>zadania matematyczne</b> dla <b>dzieci w&nbsp;wieku 9&nbsp;i&nbsp;10&nbsp;lat</b> (<b>klasa 4</b>). Gracze odwiedzają różne bajkowe krainy, 
        np. zaczarowany las, średniowieczne królestwo, kopalnię diamentów, podwodny świat. Wraz z&nbsp;sympatycznymi mieszkańcami każdego z&nbsp;tych miejsc 
        podejmują <b>ciekawe matematyczne wyzwania</b>.`,
      advantagesDescriptionTitle: `<b>Zalety <i>Matlandii 4</i></b>`,
      advantagesDescription: `<i>Matlandię 4</i> polecamy zarówno uzdolnionym dzieciom, jak i&nbsp;tym, którym nauka sprawia
        trudności. Nasza eduGra w&nbsp;prosty i&nbsp;przyjazny sposób <b>pomoże</b> każdemu dziecku <b>uzupełnić wiedzę</b>, <b>zdobyć nowe
        umiejętności</b>, <b>uwierzyć we własne siły i&nbsp;podnieść samoocenę!</b>`,
      pdfButton: matlandia4pdf,
      gallery: [
        {
          img: matlandia4Screen1,
          alt: 'Zadania matematyczne klasa 4 Matlandia, widok gry - odejmowanie',
        },
        {
          img: matlandia4Screen2,
          alt: 'Zadania matematyczne klasa 4 Matlandia, widok gry - dzielenie',
        },
        {
          img: matlandia4Screen3,
          alt: 'Zadania matematyczne klasa 4 Matlandia, widok gry - system dziesiątkowy',
        },
        {
          img: matlandia4Screen4,
          alt: 'Zadania matematyczne klasa 4 Matlandia, widok gry - miary',
        },
      ],
      reviewLink: 'https://mojedziecikreatywnie.pl/2019/09/matlandia-nauka-matematyki-przez-zabawe',
      advantages: [
        {
          id: 1,
          img: Advantages1,
          text: 'ponad 240 zadań ze zmieniającymi się przykładami',
        },
        {
          id: 2,
          img: Advantages2,
          text: 'zadania przygotowane przez nauczycieli i&nbsp;autorów podręczników',
        },
        {
          id: 3,
          img: Advantages11,
          text: 'wirtualne medale i&nbsp;nagrody za osiągnięcia',
        },

        {
          id: 4,
          img: Advantages10,
          text: 'rankingi z&nbsp;wynikami graczy z&nbsp;całej Polski',
        },
        {
          id: 5,
          img: Advantages7,
          text: 'brak reklam',
        },
        {
          id: 6,
          img: Advantages9,
          text: 'jednorazowa opłata za dostęp na rok, pół roku, miesiąc lub tydzień',
        },
      ],
      demoLink: 'https://matlandia4.apps.gwo.pl/0',
      buyLink: 'https://ksiegarnia.gwo.pl/product/matlandia-klasa-4',
      descriptionTitle: ``,
      descriptionParagraphs: [
        {
          id: 1,
          subheading: `Zadania matematyczne klasa&nbsp;4 – nauka poprzez zabawę`,
          text: `<b><i>Matlandia 4</i></b> to wciągająca <b>gra matematyczna</b>, która została stworzona z&nbsp;myślą, że najskuteczniejsza nauka to 
          <b>nauka poprzez zabawę</b>. <b>Zadania matematyczne</b> klasa&nbsp;4 w&nbsp;naszej eduGrze mają zróżnicowany poziom trudności, dlatego są 
          idealne zarówno dla uzdolnionego <b>9-latka</b> lub <b>10-latka</b>, jak i&nbsp;takiego, któremu nauka matematyki sprawia trudności.
          `,
        },
        {
          id: 2,
          subheading: null,
          text: `<b>Gra edukacyjna dla dzieci</b> (online) <b><i>Matlandia&nbsp;4</i></b> w&nbsp;atrakcyjny sposób zachęca do sprawdzania swoich umiejętności matematycznych 
          gracza oraz do podejmowania prób, by osiągnąć jeszcze lepszy wynik: wykonać zadanie w&nbsp;jeszcze krótszym czasie, bez żadnych błędów. 
          Nasze matlandiowe zadania matematyczne klasa&nbsp;4 to sprytna metoda na zamianę zazwyczaj nudnych powtórek we wciągającą rozrywkę. 
          Przy kolejnych uruchomieniach tego samego zadania w&nbsp;<i>Matlandii&nbsp;4</i> pojawiają się inne dane. Dzięki temu nie można „wykuć” 
          odpowiedzi – program sprawdza faktyczne opanowanie danego zagadnienia.
          `,
        },
        {
          id: 3,
          subheading: null,
          text: `Dzieci uwielbiają otrzymywać nagrody za podjęty wysiłek. EduGra <i>Matlandia&nbsp;4</i> zapewnia możliwość zdobywania całej gamy <b>motywujących 
          odznak</b> za rozwiązywanie zadań. Doceniana jest systematyczna praca, podejmowanie prób w&nbsp;osiągnięciu coraz lepszego rezultatu, można też 
          otrzymać nagrodę za bezbłędną odpowiedź czy szybkie rozwiązanie zadania. Dodatkowo co kilka rozwiązanych zadań odblokowywany jest krótki 
          filmik animowany z&nbsp;bohaterami z&nbsp;gry.
          `,
        },
        {
          id: 4,
          subheading: null,
          text: `Sposobem na dodatkowe zaangażowanie gracza w&nbsp;rozwijanie jego umiejętności matematycznych z&nbsp;naszą grą edukacyjną online mogą stać się 
          również punkty zdobywane przy każdym rozwiązaniu zadania. Punkty te umieszczane są w&nbsp;ogólnopolskim rankingu gry i&nbsp;można tam porównać swoje 
          wyniki z&nbsp;osiągnięciami innych graczy.
          `,
        },
        {
          id: 5,
          subheading: `Zagadnienia w&nbsp;<i>Matlandii&nbsp;4</i>`,
          text: `<i>Matlandia 4</i> pomaga dzieciom bezstresowo przećwiczyć zagadnienia, których znajomości wymaga się w&nbsp;<b>4 klasie</b>. Zadania matematyczne klasa&nbsp;4
          podzielone są na sześć głównych działów i&nbsp;ponad 40&nbsp;tematów. Dziecko może poćwiczyć rozwiązywanie zadań z&nbsp;<b>dodawania i&nbsp;odejmowania</b>, <b>tabliczki mnożenia</b>, 
          <b>dzielenia</b> (również dzielenia z&nbsp;resztą). Animowane ćwiczenia pozwalają w&nbsp;atrakcyjny sposób utrwalić wiedzę z&nbsp;zakresu <b>kwadratów</b> i&nbsp;<b>sześcianów liczb</b>, 
          <b>kolejności wykonywania działań</b> czy <b>osi liczbowej</b>. Dzięki zmiennym danym liczbowym w&nbsp;zadaniach dzieci mogą sprawdzić, w&nbsp;jakim stopniu opanowały 
          <b>system dziesiątkowy i&nbsp;rzymski</b>, a&nbsp;także umiejętność <b>porównywania liczb</b>. Program oferuje również ćwiczenia odnoszące się do matematyki na co dzień 
          (<b>obliczenia pieniężne</b>, <b>kilogramy i&nbsp;dekagramy</b>, <b>centymetry i&nbsp;milimetry</b>, <b>odczytywanie godzin na zegarze wskazówkowym i&nbsp;elektronicznym</b>, <b>jednostki czasu</b>, 
          <b>zapisywanie dat oraz skala i&nbsp;plan</b>). <i>Matlandia&nbsp;4</i> zawiera także działy poświęcone geometrii – <b>kąty i&nbsp;mierzenie kątów</b>, <b>wielokąty i&nbsp;okręgi</b>, <b>obwody figur</b>, 
          <b>pola figur</b>, <b>prostopadłościany i&nbsp;sześciany</b> (siatki prostopadłościanów, pole powierzchni prostopadłościanu). W&nbsp;programie znajdują się też zadania 
          matematyczne obejmujące <b>ułamki zwykłe</b> oraz <b>ułamki dziesiętne</b>.
          `,
        },
        {
          id: 6,
          subheading: `Bezpieczna nauka i&nbsp;zabawa`,
          text: `W <i>Matlandii 4</i> nie ma żadnych reklam, więc bez obaw można pozwolić dziecku na samodzielną naukę z&nbsp;programem. Treści zawarte w&nbsp;ćwiczeniach zostały 
          stworzone przez doświadczonych specjalistów i&nbsp;są one dopasowane do wieku gracza.
          `,
        },
      ],
    },
    'matlandia-5': {
      h1: '<i>Matlandia 5</i>',
      h2: 'gra edukacyjna dla dzieci (online)',
      h3: '48 zł na rok | bezpłatna przesyłka',
      description: `<b>Gra na komputer</b> zawierająca <b>zadania matematyczne</b> dla <b>dzieci w&nbsp;wieku 10 i&nbsp;11 lat (klasa&nbsp;5)</b>. 
      Bohaterowie gry lądują awaryjnie na egzotycznej wyspie. Gracze towarzyszą jednemu z&nbsp;podróżników – wraz z&nbsp;nim 
      przeczesują wyspę, poznają ekscentrycznych tubylców i&nbsp;pomagają im rozwiązać matematyczne problemy. Dzięki 
      temu zdobywają skarby oraz części, które pozwolą naprawić uszkodzony samolot.
      `,
      advantagesDescriptionTitle: `<b>Zalety <i>Matlandii 5</i></b>`,
      advantagesDescription: `<i>Matlandię 5</i> polecamy zarówno uzdolnionym dzieciom, jak i&nbsp;tym, którym nauka sprawia
        trudności. Nasza eduGra w&nbsp;prosty i&nbsp; przyjazny sposób <b>pomoże</b> każdemu dziecku <b>uzupełnić wiedzę</b>, <b>zdobyć nowe
        umiejętności</b>, <b>uwierzyć we własne siły i&nbsp;podnieść samoocenę!</b>`,
      pdfButton: matlandia5pdf,
      gallery: [
        {
          img: matlandia5Screen1,
          alt: 'Zadania matematyczne klasa 5 Matlandia, widok gry - dodawanie dużych liczb',
        },
        {
          img: matlandia5Screen2,
          alt: 'Zadania matematyczne klasa 5 Matlandia, widok gry - bryły geometryczne',
        },
        {
          img: matlandia5Screen3,
          alt: 'Zadania matematyczne klasa 5 Matlandia, widok gry - miary i jednostki',
        },
        {
          img: matlandia5Screen4,
          alt: 'Zadania matematyczne klasa 5 Matlandia, widok gry - pole figury',
        },
      ],
      reviewLink: 'https://mojedziecikreatywnie.pl/2019/09/matlandia-nauka-matematyki-przez-zabawe',
      advantages: [
        {
          id: 1,
          img: Advantages1,
          text: `ponad 200 zadań ze zmieniającymi się przykładami`,
        },
        {
          id: 2,
          img: Advantages2,
          text: 'zadania przygotowane przez nauczycieli i&nbsp;autorów podręczników',
        },
        {
          id: 3,
          img: Advantages11,
          text: 'wirtualne medale i&nbsp;nagrody za osiągnięcia',
        },

        {
          id: 4,
          img: Advantages10,
          text: 'rankingi z wynikami graczy z&nbsp;całej Polski',
        },
        {
          id: 5,
          img: Advantages7,
          text: 'brak reklam',
        },
        {
          id: 6,
          img: Advantages9,
          text: 'jednorazowa opłata za dostęp na rok, pół roku, miesiąc lub tydzień',
        },
      ],
      demoLink: 'https://matlandia5.apps.gwo.pl/0',
      buyLink: 'https://ksiegarnia.gwo.pl/product/matlandia-klasa-5',
      descriptionTitle: ``,
      descriptionParagraphs: [
        {
          id: 1,
          subheading: `Zadania matematyczne klasa&nbsp;5 – nauka poprzez zabawę`,
          text: `<b><i>Matlandia 5</i></b> to wciągająca <b>gra matematyczna</b>, która została stworzona z&nbsp;myślą, 
          że najskuteczniejsza nauka to <b>nauka poprzez zabawę</b>. <b>Zadania matematyczne</b> klasa&nbsp;5 w&nbsp;naszej 
          eduGrze mają zróżnicowany poziom trudności, dlatego są idealne zarówno dla uzdolnionego 
          <b>10-latka</b> i&nbsp;<b>11-latka</b>, jak i&nbsp;takiego, któremu nauka matematyki sprawia trudności.
          `,
        },
        {
          id: 2,
          subheading: null,
          text: `<b>Gra edukacyjna dla dzieci (online) <i>Matlandia&nbsp;5</i></b> w&nbsp;atrakcyjny sposób zachęca do sprawdzania swoich 
          umiejętności matematycznych oraz do podejmowania prób, by osiągnąć jeszcze lepszy wynik: wykonać zadanie 
          w&nbsp;jeszcze krótszym czasie, bez żadnych błędów. Nasze matlandiowe zadania matematyczne klasa&nbsp;5 to sprytna 
          metoda na zamianę zazwyczaj nudnych powtórek we wciągającą rozrywkę. Przy kolejnych uruchomieniach tego 
          samego zadania w&nbsp;<i>Matlandii&nbsp;5</i> pojawiają się inne dane. Dzięki temu nie można „wykuć” odpowiedzi – program 
          sprawdza faktyczne opanowanie danego zagadnienia.
          `,
        },
        {
          id: 3,
          subheading: null,
          text: `Dzieci uwielbiają otrzymywać nagrody za podjęty wysiłek. EduGra <i>Matlandia&nbsp;5</i> zapewnia możliwość 
          zdobywania całej gamy <b>motywujących odznak</b> za rozwiązywanie zadań. Doceniana jest systematyczna praca, 
          podejmowanie prób w&nbsp;osiągnięciu coraz lepszego rezultatu, można też otrzymać nagrodę za bezbłędną odpowiedź 
          czy szybkie rozwiązanie zadania. Dodatkowo, po rozwiązaniu zadania w&nbsp;wyznaczonym czasie, dziecko w nagrodę 
          odblokowuje śmieszne <b>skarby</b>, które można podziwiać w&nbsp;jaskini na wyspie.
          `,
        },
        {
          id: 4,
          subheading: null,
          text: `Sposobem na dodatkowe zaangażowanie gracza w&nbsp;rozwijanie jego umiejętności matematycznych z&nbsp;naszą grą 
          edukacyjną online mogą stać się również punkty zdobywane przy każdym rozwiązaniu zadania. Punkty te umieszczane 
          są w&nbsp;ogólnopolskim rankingu gry i&nbsp;można tam porównać swoje wyniki z&nbsp;osiągnięciami innych graczy.
          `,
        },
        {
          id: 5,
          subheading: `Zagadnienia w <i>Matlandii&nbsp;5</i>`,
          text: `<b>Gra edukacyjna dla dzieci online</b> <i>Matlandia&nbsp;5</i> pomaga dzieciom bezstresowo przećwiczyć zagadnienia, 
          których znajomości wymaga się w&nbsp;<b>5 klasie</b> (m.in. <b>dodawanie i&nbsp;odejmowanie</b>, <b>tabliczka mnożenia</b>, <b>dzielenie</b> – również dzielenie 
          z&nbsp;resztą). Rozwiązywanie interaktywnych zadań pomaga dziecku łatwo utrwalić wiedzę dotyczącą <b>osi liczbowej</b> czy 
          <b>dzielników liczb</b> (również: największego wspólnego dzielnika). Animowane ćwiczenia pozwalają w&nbsp;atrakcyjny sposób 
          zrobić powtórkę z&nbsp;takich działów, jak: <b>liczby pierwsze</b>, <b>liczby złożone</b> i&nbsp;<b>liczby ujemne</b>. Dzięki zmiennym danym liczbowym
          w&nbsp;zadaniach dzieci mogą sprawdzić umiejętności z&nbsp;zakresu <b>rozkładu na czynniki pierwsze</b> oraz <b>wielokrotności liczb</b>. 
          Zabawni bohaterowie uprzyjemnią także powtórki z&nbsp;<b>ułamków zwykłych</b> i&nbsp;<b>dziesiętnych</b>. <i>Matlandia&nbsp;5</i> zawiera również zadania 
          z&nbsp;geometrii, w&nbsp;których można przećwiczyć <b>mierzenie kątów</b>, obliczanie <b>pola figur</b> (pole trójkąta, pole prostokąta, 
          pole trapezu, pole równoległoboku, pole rombu, pole wielokąta), <b>obwodów figur</b>, i&nbsp;udoskonalić znajomość <b>graniastosłupów</b> 
          (siatki graniastosłupów, pole powierzchni graniastosłupa, objętość graniastosłupa).
          `,
        },
        {
          id: 6,
          subheading: `Bezpieczna nauka i&nbsp;zabawa`,
          text: `W&nbsp;<i>Matlandii&nbsp;5</i> nie ma żadnych reklam, więc bez obaw można pozwolić dziecku na samodzielną naukę z&nbsp;programem. 
          Treści zawarte w&nbsp;ćwiczeniach zostały stworzone przez doświadczonych specjalistów i&nbsp;są one dopasowane do wieku gracza.
          `,
        },
      ],
    },
    'matlandia-6': {
      h1: '<i>Matlandia 6</i>',
      h2: 'gra edukacyjna dla dzieci (online)',
      h3: '48 zł na rok | bezpłatna przesyłka',
      description: `<b>Gra matematyczna na komputer</b> dla <b>6-klasistów</b> (wiek <b>11 i&nbsp;12&nbsp;lat</b>). Gracze wyruszają w&nbsp;podróż do odległej 
      galaktyki, w&nbsp;której każda planeta poświęcona jest innemu działowi matematyki. Na planetach tych nie sposób się nudzić. 
      Zamieszkują je sympatyczni kosmici, których codzienne życie kręci się wokół geometrii, pierwiastków czy wyrażeń algebraicznych.
      `,
      advantagesDescriptionTitle: `<b>Zalety <i>Matlandii 6</i></b>`,
      advantagesDescription: `<i>Matlandię 6</i> polecamy zarówno uzdolnionym dzieciom, jak i&nbsp;tym, którym nauka sprawia
        trudności. Nasza eduGra w&nbsp;prosty i&nbsp;przyjazny sposób <b>pomoże</b> każdemu dziecku <b>uzupełnić wiedzę</b>, <b>zdobyć
        nowe umiejętności</b>, <b>uwierzyć we własne siły i&nbsp;podnieść samoocenę!</b>
      `,
      pdfButton: matlandia6pdf,
      gallery: [
        {
          img: matlandia6Screen1,
          alt: 'Zadania matematyczne klasa 6 Matlandia, widok gry - jednostki i miary',
        },
        {
          img: matlandia6Screen2,
          alt: 'Zadania matematyczne klasa 6 Matlandia, widok gry - dodawanie',
        },
        {
          img: matlandia6Screen3,
          alt: 'Zadania matematyczne klasa 6 Matlandia, widok gry - kąty i miary kątów',
        },
        {
          img: matlandia6Screen4,
          alt: 'Zadania matematyczne klasa 6 Matlandia, widok gry - obliczanie procentów',
        },
      ],
      reviewLink: 'https://mojedziecikreatywnie.pl/2019/09/matlandia-nauka-matematyki-przez-zabawe/',
      advantages: [
        {
          id: 1,
          img: Advantages1,
          text: `ponad 150 zadań ze zmieniającymi się przykładami`,
        },
        {
          id: 2,
          img: Advantages2,
          text: 'zadania przygotowane przez nauczycieli i&nbsp;autorów podręczników',
        },
        {
          id: 3,
          img: Advantages11,
          text: 'wirtualne medale i&nbsp;nagrody za osiągnięcia',
        },

        {
          id: 4,
          img: Advantages10,
          text: 'rankingi z wynikami graczy z&nbsp;całej Polski',
        },
        {
          id: 5,
          img: Advantages7,
          text: 'brak reklam',
        },
        {
          id: 6,
          img: Advantages9,
          text: 'jednorazowa opłata za dostęp na rok, pół roku, miesiąc lub tydzień',
        },
      ],
      demoLink: 'https://matlandia6.apps.gwo.pl/0',
      buyLink: 'https://ksiegarnia.gwo.pl/product/matlandia-klasa-6',
      descriptionTitle: ``,
      descriptionParagraphs: [
        {
          id: 1,
          subheading: `Nauka poprzez zabawę`,
          text: `<b><i>Matlandia 6</i></b> to wciągająca <b>gra matematyczna</b>, która została stworzona z&nbsp;myślą, że najskuteczniejsza 
          nauka to <b>nauka poprzez zabawę</b>. <b>Zadania matematyczne</b> w&nbsp;naszej eduGrze mają zróżnicowany poziom trudności, 
          dlatego są idealne zarówno dla uzdolnionego <b>11-latka i&nbsp;12-latka</b>, jak i&nbsp;takiego, któremu nauka matematyki sprawia trudności. 
          `,
        },
        {
          id: 2,
          subheading: null,
          text: `<b>Gra edukacyjna dla dzieci online <i>Matlandia 6</i></b> w&nbsp;atrakcyjny sposób zachęca graczy do sprawdzania umiejętności matematycznych 
          oraz do podejmowania prób, by osiągnąć jeszcze lepszy wynik: wykonać zadanie w&nbsp;jeszcze krótszym czasie, bez żadnych błędów. 
          Nasze matlandiowe zadania matematyczne klasa&nbsp;6 to sprytna metoda na zamianę zazwyczaj nudnych powtórek we wciągającą rozrywkę. 
          Przy kolejnych uruchomieniach tego samego zadania w&nbsp;<i>Matlandii&nbsp;6</i> pojawiają się inne dane. Dzięki temu nie można „wykuć” 
          odpowiedzi – program sprawdza faktyczne opanowanie danego zagadnienia. 
          `,
        },
        {
          id: 3,
          subheading: null,
          text: `Dzieci uwielbiają otrzymywać nagrody za podjęty wysiłek. EduGra <i>Matlandia&nbsp;6</i> zapewnia możliwość zdobywania 
          całej gamy <b>motywujących odznak</b> za rozwiązywanie zadań. Doceniana jest systematyczna praca, podejmowanie prób 
          w&nbsp;osiągnięciu coraz lepszego rezultatu, można też otrzymać nagrodę za bezbłędną odpowiedź czy szybkie rozwiązanie 
          zadania. Dodatkowo rozwiązywanie zadań odblokowuje kolejne części filmu animowanego z&nbsp;perypetiami bohaterów gry. 
          `,
        },
        {
          id: 4,
          subheading: null,
          text: `Sposobem na dodatkowe zaangażowanie gracza w&nbsp;rozwijanie jego umiejętności matematycznych z&nbsp;naszą grą edukacyjną 
          online mogą stać się również punkty zdobywane przy każdym rozwiązaniu zadania. Punkty te umieszczane są w&nbsp;ogólnopolskim 
          rankingu gry i&nbsp;można tam porównać swoje wyniki z&nbsp;osiągnięciami innych graczy. 
          `,
        },
        {
          id: 5,
          subheading: `Zagadnienia w <i>Matlandii 6</i>`,
          text: `<i>Matlandia&nbsp;6</i> pomaga dzieciom bezstresowo przećwiczyć zagadnienia, których znajomości wymaga 
          się w&nbsp;<b>klasie&nbsp;6</b> (m.in. <b>dodawanie i&nbsp;odejmowanie</b>, <b>tabliczka mnożenia</b>, czy <b>dzielenie</b>). 
          Świetnie przygotowane zadania uprzyjemnią powtórki z&nbsp;<b>ułamków zwykłych</b>, <b>ułamków dziesiętnych</b>, 
          <b>potęg</b> oraz <b>procentów</b>. Program oferuje także ćwiczenia odnoszące się do matematyki 
          na co dzień – <b>obliczeń pieniężnych</b>, <b>kalendarza</b> i&nbsp;<b>czasu</b> 
          (m.in. lata przestępne; określanie, który to wiek). Pozwala także udoskonalić umiejętności 
          związane z&nbsp;<b>przeliczaniem jednostek długości</b> i&nbsp;<b>masy</b>. W&nbsp;<i>Matlandii&nbsp;6</i> znajdują się również 
          niezwykle praktyczne zadania ze <b>skali na planie</b> i&nbsp;<b>na mapie</b>. Dzięki zabawnym bohaterom 
          <b>odczytywanie diagramów</b>, <b>odczytywanie wykresów</b> czy zagadnienia związane z&nbsp;<b>prędkością</b> 
          i&nbsp;<b>drogą</b> stają się przyjemną zabawą. <i>Matlandia&nbsp;6</i> zawiera również zagadnienia z&nbsp;zakresu 
          geometrii: w&nbsp;zadaniach można przećwiczyć <b>mierzenie kątów</b>, obliczanie <b>pól figur</b> 
          (pole trójkąta, pole prostokąta, pole trapezu, pole równoległoboku, pole rombu, pole wielokąta) 
          i&nbsp;udoskonalić znajomość <b>graniastosłupów</b> (siatki graniastosłupów, pole powierzchni graniastosłupa, 
          objętość graniastosłupa), <b>ostrosłupów</b>, <b>walców</b>, <b>stożków</b> i&nbsp;<b>kul</b>. 
          Dzięki zmiennym danym liczbowym w&nbsp;zadaniach dzieci mogą sprawdzić, w&nbsp;jakim stopniu opanowały 
          <b>wyrażenia algebraiczne</b> i&nbsp;<b>równania</b>. 
          `,
        },
        {
          id: 6,
          subheading: `Bezpieczna nauka i&nbsp;zabawa`,
          text: `W&nbsp;<i>Matlandii&nbsp;6</i> nie ma żadnych reklam, więc bez obaw można pozwolić dziecku na samodzielną naukę z&nbsp;programem. Treści zawarte 
          w&nbsp;ćwiczeniach zostały stworzone przez doświadczonych specjalistów i&nbsp;są one dopasowane do wieku gracza. 
          `,
        },
      ],
    },
    'matlandia-7': {
      h1: '<i>Matlandia 7</i>',
      h2: 'gra edukacyjna online',
      h3: '48 zł na rok | bezpłatna przesyłka',
      description: `<b>Gra matematyczna na komputer</b> dla <b>7-klasistów</b> (wiek <b>12 i&nbsp;13&nbsp;lat</b>). Gracze trafiają na teren nowocześnie 
      wyposażonej szkoły i&nbsp;rozwiązują zadania w&nbsp;towarzystwie komiksowych bohaterów – Siemka i&nbsp;Eli.
      `,
      advantagesDescriptionTitle: `<b>Zalety <i>Matlandii 7</i></b>`,
      advantagesDescription: `<i>Matlandię 7</i> polecamy zarówno uzdolnionym dzieciom, jak i&nbsp;tym, którym nauka sprawia
        trudności. Nasza eduGra w&nbsp;prosty i&nbsp;przyjazny sposób <b>pomoże</b> każdemu dziecku <b>uzupełnić wiedzę</b>, <b>zdobyć nowe
        umiejętności</b>, <b>uwierzyć we własne siły i&nbsp;podnieść samoocenę!</b>`,
      pdfButton: matlandia7pdf,
      gallery: [
        {
          img: matlandia7Screen1,
          alt: 'Zadania matematyczne klasa 7 Matlandia, widok gry - działy',
        },
        {
          img: matlandia7Screen2,
          alt: 'Zadania matematyczne klasa 7 Matlandia, widok gry - miary kątów',
        },
        {
          img: matlandia7Screen3,
          alt: 'Zadania matematyczne klasa 7 Matlandia, widok gry - obliczanie procentów',
        },
        {
          img: matlandia7Screen4,
          alt: 'Zadania matematyczne klasa 7 Matlandia, widok gry - siatki brył',
        },
      ],
      reviewLink: null,
      advantages: [
        {
          id: 1,
          img: Advantages1,
          text: `ponad 330 zadań ze zmieniającymi się przykładami`,
        },
        {
          id: 2,
          img: Advantages2,
          text: 'zadania przygotowane przez nauczycieli i autorów podręczników',
        },
        {
          id: 3,
          img: Advantages11,
          text: 'wirtualne medale i nagrody za osiągnięcia',
        },

        {
          id: 4,
          img: Advantages10,
          text: 'rankingi z wynikami graczy z całej Polski',
        },
        {
          id: 5,
          img: Advantages7,
          text: 'brak reklam',
        },
        {
          id: 6,
          img: Advantages9,
          text: 'jednorazowa opłata za dostęp na rok, pół roku, miesiąc lub tydzień',
        },
      ],
      demoLink: 'https://matlandia7.apps.gwo.pl/0',
      buyLink: 'https://ksiegarnia.gwo.pl/product/matlandia-klasa-7',
      descriptionTitle: ``,
      descriptionParagraphs: [
        {
          id: 1,
          subheading: `Interaktywne ćwiczenia – atrakcyjny sposób na naukę`,
          text: `<b><i>Matlandia 7</i></b> to wciągająca <b>gra matematyczna</b>, która została stworzona z&nbsp;myślą, że najskuteczniejsza nauka to 
          <b>nauka poprzez zabawę</b>. <b>Zadania matematyczne</b> w&nbsp;naszej eduGrze mają zróżnicowany poziom trudności, dlatego są idealne 
          zarówno dla uzdolnionego <b>12-latka i&nbsp;13-latka</b>, jak i&nbsp;takiego, któremu nauka matematyki sprawia trudności.
          `,
        },
        {
          id: 2,
          subheading: null,
          text: `<b>Gra edukacyjna dla dzieci online <i>Matlandia&nbsp;7</i></b> w&nbsp;atrakcyjny sposób zachęca gracza do sprawdzania umiejętności matematycznych 
          oraz do podejmowania prób, by osiągnąć jeszcze lepszy wynik: wykonać zadanie w&nbsp;jeszcze krótszym czasie, bez żadnych błędów. 
          Nasze matlandiowe zadania matematyczne klasa&nbsp;7 to sprytna metoda na zamianę zazwyczaj nudnych powtórek we wciągającą rozrywkę. 
          Przy kolejnych uruchomieniach tego samego zadania w&nbsp;<i>Matlandii&nbsp;7</i> pojawiają się inne dane. Dzięki temu nie można „wykuć” 
          odpowiedzi – program sprawdza faktyczne opanowanie danego zagadnienia.
          `,
        },
        {
          id: 3,
          subheading: null,
          text: `Nawet starsze dzieci uwielbiają otrzymywać nagrody za podjęty wysiłek. EduGra <i>Matlandia&nbsp;7</i> zapewnia możliwość zdobywania 
          całej gamy <b>motywujących odznak</b> za rozwiązywanie zadań. Doceniana jest systematyczna praca, podejmowanie prób w&nbsp;osiągnięciu 
          coraz lepszego rezultatu, można też otrzymać nagrodę za bezbłędną odpowiedź czy szybkie rozwiązanie zadania.
          `,
        },
        {
          id: 4,
          subheading: null,
          text: `Sposobem na dodatkowe zaangażowanie gracza w&nbsp;rozwijanie jego umiejętności matematycznych z&nbsp;naszą grą edukacyjną online 
          mogą stać się również punkty zdobywane przy każdym rozwiązaniu zadania. Punkty te umieszczane są w&nbsp;ogólnopolskim rankingu gry 
          i&nbsp;można tam porównać swoje wyniki z&nbsp;osiągnięciami innych graczy.
          `,
        },
        {
          id: 5,
          subheading: `Zagadnienia w <i>Matlandii 7</i>`,
          text: `<b>Gra edukacyjna dla dzieci online</b> <i>Matlandia&nbsp;7</i> pomaga bezstresowo przećwiczyć zagadnienia, których znajomości wymaga 
          się w&nbsp;<b>klasie&nbsp;7</b> (m.in. <b>dodawanie i&nbsp;odejmowanie</b>, <b>tabliczka mnożenia</b> i&nbsp;<b>dzielenie</b>). Komiksowi bohaterowie uprzyjemnią powtórki 
          z&nbsp;<b>liczb wymiernych</b> i&nbsp;<b>liczb ujemnych</b> oraz z&nbsp;zakresu <b>rozwinięcia dziesiętnego liczb</b> i&nbsp;<b>zaokrąglania liczb</b>. Rozwiązując 
          interaktywne zadania, 7-klasiści łatwo utrwalą wiedzę dotyczącą <b>osi liczbowej</b>, <b>ułamków zwykłych</b> i&nbsp;<b>ułamków dziesiętnych</b>. 
          W&nbsp;<i>Matlandii&nbsp;7</i> znajdują się również praktyczne ćwiczenia z&nbsp;<b>zamiany jednostek czasu</b>, <b>długości</b> i&nbsp;<b>masy</b>. Świetnie przygotowane 
          zadania z&nbsp;<b>wyrażeń arytmetycznych</b>, <b>procentów</b>, <b>potęg</b> i&nbsp;<b>pierwiastków</b> pomogą uzyskać lepsze wyniki w&nbsp;nauce. Nasz program matematyczny 
          online zawiera również zagadnienia z&nbsp;zakresu geometrii: <b>proste</b> i&nbsp;<b>odcinki</b>, <b>kąty</b> i&nbsp;<b>mierzenie kątów</b>, <b>pola figur</b> (pole trójkąta, 
          pole prostokąta, pole trapezu, pole równoległoboku, pole rombu, pole wielokąta), <b>graniastosłupy</b> (siatki graniastosłupów, 
          pole powierzchni graniastosłupa, objętość graniastosłupa), a&nbsp;także zadania związane z&nbsp;<b>układem współrzędnych</b>. Dzięki zmiennym 
          danym liczbowym w&nbsp;zadaniach dzieci mogą sprawdzić, w&nbsp;jakim stopniu opanowały umiejętność obliczania <b>wyrażeń algebraicznych</b>, 
          <b>jednomianów</b>, <b>równań</b> czy <b>przekształcania wzorów</b>.
          `,
        },
        {
          id: 6,
          subheading: `Bezpieczna nauka i&nbsp;zabawa`,
          text: `W <i>Matlandii 7</i> nie ma żadnych reklam, więc bez obaw można pozwolić dziecku na samodzielną naukę z&nbsp;programem. 
          Treści zawarte w&nbsp;ćwiczeniach zostały stworzone przez doświadczonych specjalistów i&nbsp;są one dopasowane do wieku gracza.
          `,
        },
      ],
    },
    'matlandia-8': {
      h1: '<i>Matlandia 8</i>',
      h2: 'gra edukacyjna online',
      h3: '48 zł na rok | bezpłatna przesyłka',
      description: `<b>Gra matematyczna na komputer</b> dla 8-klasistów (wiek <b>13 i&nbsp;14&nbsp;lat</b>). To dalszy ciąg przygód bohaterów 
      znanych z&nbsp;<i>Matlandii 7</i> – Siemka i&nbsp;Eli. Tym razem gracze rozwiązują zadania, poruszając się po nowoczesnym ośrodku badawczym.
      `,
      advantagesDescriptionTitle: `<b>Zalety <i>Matlandii 8</i></b>`,
      advantagesDescription: `<i>Matlandię 8</i> polecamy zarówno uzdolnionym dzieciom, jak i&nbsp;tym, którym nauka sprawia
        trudności. Nasza eduGra w&nbsp;prosty i&nbsp;przyjazny sposób <b>pomoże</b> każdemu dziecku <b>uzupełnić wiedzę</b>, <b>zdobyć nowe
        umiejętności</b>, <b>uwierzyć we własne siły i&nbsp;podnieść samoocenę!</b>`,
      pdfButton: matlandia8pdf,
      gallery: [
        {
          img: matlandia8Screen1,
          alt: 'Zadania matematyczne klasa 8 Matlandia, widok gry - temat',
        },
        {
          img: matlandia8Screen2,
          alt: 'Zadania matematyczne klasa 8 Matlandia, widok gry - obliczanie procentów',
        },
        {
          img: matlandia8Screen3,
          alt: 'Zadania matematyczne klasa 8 Matlandia, widok gry - obliczanie objętości bryły',
        },
        {
          img: matlandia8Screen4,
          alt: 'Zadania matematyczne klasa 8 Matlandia, widok gry - działy',
        },
      ],
      reviewLink: null,
      advantages: [
        {
          id: 1,
          img: Advantages1,
          text: `ponad 240 zadań ze zmieniającymi się przykładami`,
        },
        {
          id: 2,
          img: Advantages2,
          text: 'zadania przygotowane przez nauczycieli i&nbsp;autorów podręczników',
        },
        {
          id: 3,
          img: Advantages11,
          text: 'wirtualne medale i&nbsp;nagrody za osiągnięcia',
        },

        {
          id: 4,
          img: Advantages10,
          text: 'rankingi z&nbsp;wynikami graczy z&nbsp;całej Polski',
        },
        {
          id: 5,
          img: Advantages7,
          text: 'brak reklam',
        },
        {
          id: 6,
          img: Advantages9,
          text: 'jednorazowa opłata za dostęp na rok, pół roku, miesiąc lub tydzień',
        },
      ],
      demoLink: 'https://matlandia8.apps.gwo.pl/0',
      buyLink: 'https://ksiegarnia.gwo.pl/product/matlandia-klasa-8',
      descriptionTitle: ``,
      descriptionParagraphs: [
        {
          id: 1,
          subheading: `Interaktywne ćwiczenia – atrakcyjny sposób na naukę`,
          text: `<b><i>Matlandia 8</i></b> to wciągająca <b>gra matematyczna</b>, która została stworzona z&nbsp;myślą, że najskuteczniejsza nauka 
          to <b>nauka poprzez zabawę</b>. <b>Zadania matematyczne</b> w&nbsp;naszej eduGrze mają zróżnicowany poziom trudności, dlatego są 
          idealne zarówno dla uzdolnionego <b>13-latka</b> i&nbsp;<b>14-latka</b>, jak i&nbsp;takiego, któremu nauka matematyki sprawia trudności. 
          `,
        },
        {
          id: 2,
          subheading: null,
          text: `<b>Gra edukacyjna dla dzieci online <i>Matlandia&nbsp;8</i></b> w&nbsp;atrakcyjny sposób zachęca gracza do sprawdzania umiejętności matematycznych 
          oraz do podejmowania prób, by osiągnąć jeszcze lepszy wynik: wykonać zadanie w&nbsp;jeszcze krótszym czasie, bez żadnych błędów. 
          Nasze matlandiowe zadania matematyczne klasa&nbsp;8 to sprytna metoda na zamianę zazwyczaj nudnych powtórek we wciągającą rozrywkę. 
          Przy kolejnych uruchomieniach tego samego zadania w&nbsp;<i>Matlandii&nbsp;8</i> pojawiają się inne dane. Dzięki temu nie można „wykuć” 
          odpowiedzi – program sprawdza faktyczne opanowanie danego zagadnienia. 
          `,
        },
        {
          id: 3,
          subheading: null,
          text: `Nawet starsze dzieci uwielbiają otrzymywać nagrody za podjęty wysiłek. EduGra <i>Matlandia&nbsp;8</i> zapewnia możliwość 
          zdobywania całej gamy <b>motywujących odznak</b> za rozwiązywanie zadań. Doceniana jest systematyczna praca, podejmowanie 
          prób w&nbsp;osiągnięciu coraz lepszego rezultatu, można też otrzymać nagrodę za bezbłędną odpowiedź czy szybkie rozwiązanie zadania. 
          `,
        },
        {
          id: 4,
          subheading: null,
          text: `Sposobem na dodatkowe zaangażowanie gracza w&nbsp;rozwijanie jego umiejętności matematycznych z&nbsp;naszą grą edukacyjną online mogą 
          stać się również punkty zdobywane przy każdym rozwiązaniu zadania. Punkty te umieszczane są w&nbsp;ogólnopolskim rankingu gry i&nbsp;można 
          tam porównać swoje wyniki z&nbsp;osiągnięciami innych graczy. 
          `,
        },
        {
          id: 5,
          subheading: `Zagadnienia w <i>Matlandii 8</i>`,
          text: `<i>Matlandia&nbsp;8</i> pomaga dzieciom bezstresowo przećwiczyć zagadnienia, których znajomości wymaga się w&nbsp;<b>klasie&nbsp;8</b> 
          (m.in. <b>dodawanie i&nbsp;odejmowanie</b>, <b>tabliczka mnożenia</b> i&nbsp;<b>dzielenie</b> – również dzielenie z&nbsp;resztą). Komiksowi bohaterowie 
          uprzyjemnią powtórki z&nbsp;<b>rozkładania liczby na czynniki pierwsze</b> czy <b>wspólnego dzielnika liczb</b>. Rozwiązując interaktywne 
          zadania, 8-klasiści łatwo utrwalą wiedzę dotyczącą <b>systemu rzymskiego</b> lub <b>rozwinięć dziesiętnych</b>. Dzięki zmiennym danym 
          liczbowym w&nbsp;zadaniach dzieci sprawdzą, w&nbsp;jakim stopniu opanowały <b>pierwiastki</b>, <b>potęgi</b>, <b>wyrażenia algebraiczne</b> i&nbsp;<b>równania</b>. 
          Świetnie przygotowane zadania z&nbsp;<b>procentów</b> i&nbsp;<b>rachunku prawdopodobieństwa</b> pomogą uzyskać lepsze wyniki w&nbsp;nauce. Nasz program 
          matematyczny online zawiera również działy z&nbsp;zakresu geometrii: <b>proporcje</b>, <b>kąty</b> i&nbsp;<b>obliczanie miar kątów</b>, <b>twierdzenie 
          Pitagorasa</b>. W&nbsp;zadaniach można przećwiczyć obliczanie <b>pola figur</b>, <b>przekątnych figur</b>, odczytywanie <b>układu współrzędnych</b>, 
          a&nbsp;także udoskonalić znajomość <b>graniastosłupów</b> (siatki graniastosłupów, pole powierzchni graniastosłupa, objętość graniastosłupa), 
          <b>ostrosłupów</b> (siatki ostrosłupów, pole powierzchni ostrosłupa, objętość ostrosłupa), <b>symetrii</b>, <b>koła</b> i&nbsp;<b>okręgów</b> (długość okręgu, pole koła). 
          `,
        },
        {
          id: 6,
          subheading: `Bezpieczna nauka i&nbsp;zabawa`,
          text: `W <i>Matlandii 8</i> nie ma żadnych reklam, więc bez obaw można pozwolić dziecku na samodzielną naukę z&nbsp;programem. Treści zawarte w&nbsp;ćwiczeniach 
          zostały stworzone przez doświadczonych specjalistów i&nbsp;są one dopasowane do wieku gracza. 
          `,
        },
      ],
    },
    'powtorkomat-6': {
      h1: '<i>PowtórkoMat 6</i>',
      h2: 'gra edukacyjna online',
      h3: '38 zł na rok | bezpłatna przesyłka',
      description: `<b>Gra matematyczna na komputer</b> dla <b>6-klasistów</b> (wiek 11 i&nbsp;12&nbsp;lat). Uczy ona, jak rozwiązywać różne typy zadań z&nbsp;matematyki 
      z&nbsp;zakresu klas&nbsp;4,&nbsp;5&nbsp;i&nbsp;6 szkoły podstawowej, a&nbsp;także pozwala na szybkie sprawdzenie zdobytych umiejętności. <i>PowtórkoMat&nbsp;6</i> składa się 
      z&nbsp;dwóch części. Pierwsza z&nbsp;nich to ponad <b>90 ćwiczeń powtórzeniowych</b>, druga zaś zawiera <b>10 testów sprawdzających zdobytą wiedzę</b>.
      `,
      advantagesDescriptionTitle: `<b>Zalety <i>PowtórkoMatu 6</i></b>`,
      advantagesDescription: `<i>PowtórkoMat 6</i> polecamy zarówno uzdolnionym dzieciom, jak i&nbsp;tym, którym nauka
        sprawia trudności. Nasza eduGra w&nbsp;prosty i&nbsp;przyjazny sposób <b>pomoże</b> każdemu dziecku
        <b>uzupełnić wiedzę</b>, <b>zdobyć nowe umiejętności</b>, <b>uwierzyć we własne siły i&nbsp;podnieść samoocenę!</b>`,
      pdfButton: null,
      gallery: [
        {
          img: powtorkomat6Screen1,
          alt: 'Zadania powtórkowe klasa 6 PowtórkoMat, widok gry - działy i tematy',
        },
        {
          img: powtorkomat6Screen2,
          alt: 'Zadania powtórkowe klasa 6 PowtórkoMat, widok gry - zestawy testowe',
        },
        {
          img: powtorkomat6Screen3,
          alt: 'Zadania powtórkowe klasa 6 PowtórkoMat, widok gry - oś liczbowa',
        },
        {
          img: powtorkomat6Screen4,
          alt: 'Zadania powtórkowe klasa 6 PowtórkoMat, widok gry - liczby całkowite i działania',
        },
      ],
      reviewLink: null,
      advantages: [
        {
          id: 1,
          img: Advantages1,
          text: `ponad 240 zadań ze zmieniającymi się przykładami`,
        },
        {
          id: 2,
          img: Advantages2,
          text: 'zadania przygotowane przez nauczycieli i&nbsp;autorów podręczników',
        },
        {
          id: 3,
          img: Advantages12,
          text: 'wskazówki do zadań i&nbsp;filmy z&nbsp;rozwiązaniami',
        },

        {
          id: 4,
          img: Advantages13,
          text: 'zegar odliczający czas do końca rozwiązywania zestawu zadań',
        },
        {
          id: 5,
          img: Advantages7,
          text: 'brak reklam',
        },
        {
          id: 6,
          img: Advantages9,
          text: 'jednorazowa opłata za dostęp na rok, pół roku, miesiąc lub tydzień',
        },
      ],
      demoLink: 'https://powtorkomat6.apps.gwo.pl/0',
      buyLink: 'https://ksiegarnia.gwo.pl/product/powtorkomat-6',
      descriptionTitle: ``,
      descriptionParagraphs: [
        {
          id: 1,
          subheading: null,
          text: `<b><i>PowtórkoMat&nbsp;6</i></b> to <b>interaktywne powtórki z&nbsp;matematyki</b>. W&nbsp;pierwszej części
            <i>PowtórkoMatu&nbsp;6</i> szóstoklasiści mogą rozwiązywać <b>ćwiczenia powtórzeniowe z&nbsp;matematyki</b>,
            które zawierają wskazówki lub <b>filmy z&nbsp;rozwiązaniami krok po kroku</b>. Druga część to
            <b> testy sprawdzające</b> zdobytą wiedzę. Nasza eduGra zawiera następujące zagadnienia:
            <b>liczby całkowite i&nbsp;działania</b>, <b>ułamki zwykłe</b>, <b>ułamki dziesiętne</b>, <b>działania na ułamkach</b>, <b>geometria na
            płaszczyźnie</b>, <b>figury przestrzenne</b>, <b>wyrażenia algebraiczne i&nbsp;równania</b>, <b>obliczenia praktyczne</b>,
            <b>elementy statystyki</b>, <b>zadania tekstowe</b>.
          `,
        },
      ],
    },
    'powtorkomat-8': {
      h1: '<i>PowtórkoMat 8</i>',
      h2: 'zadania powtórkowe do egzaminu ósmoklasisty<br/>matematyka',
      h3: '48 zł na rok | bezpłatna przesyłka',
      description: `<b>Gra matematyczna na komputer</b> dla <b>8-klasistów</b> (wiek <b>13 i&nbsp;14&nbsp;lat</b>). Umożliwia
        ona kompletne przygotowanie do egzaminu ósmoklasisty z&nbsp;matematyki. Wpierwszej części programu można rozwiązać ponad
        <b>200 ćwiczeń powtórzeniowych</b> (ułożonych według działów podstawy programowej), a&nbsp;w&nbsp;drugiej –
        <b>14 próbnych zestawów zadań przygotowanych na wzór prawdziwego egzaminu.</b>
      `,
      advantagesDescriptionTitle: `<b>Zalety <i>PowtórkoMatu 8</i></b>`,
      advantagesDescription: `<i>PowtórkoMat 8</i> polecamy zarówno uzdolnionym dzieciom, jak i&nbsp;tym, którym nauka
        sprawia trudności. Nasza eduGra w&nbsp;prosty i&nbsp;przyjazny sposób <b>pomoże</b> każdemu dziecku <b>uzupełnić wiedzę</b>,
        <b>zdobyć nowe umiejętności</b>, <b>uwierzyć we własne siły i&nbsp;podnieść samoocenę!</b>`,
      pdfButton: null,
      gallery: [
        {
          img: powtorkomat8Screen1,
          alt: 'Zadania powtórkowe do egzaminu ósmoklasisty matematyka, PowtórkoMat 8 - działy',
        },
        {
          img: powtorkomat8Screen2,
          alt: 'Zadania powtórkowe do egzaminu ósmoklasisty matematyka, PowtórkoMat 8 - widok zadania',
        },
        {
          img: powtorkomat8Screen3,
          alt: 'Zadania powtórkowe do egzaminu ósmoklasisty matematyka, PowtórkoMat 8 - twierdzenie Pitagorasa',
        },
        {
          img: powtorkomat8Screen4,
          alt: 'Zadania powtórkowe do egzaminu ósmoklasisty matematyka, PowtórkoMat 8 - wielokąty',
        },
      ],
      reviewLink: 'https://www.juniorowo.pl/nauka-matematyki-dla-cyfrowych-tubylcow-testujemy-powtorkomat',
      advantages: [
        {
          id: 1,
          img: Advantages1,
          text: `ponad 530 zadań ze zmieniającymi się przykładami`,
        },
        {
          id: 2,
          img: Advantages2,
          text: 'zadania przygotowane przez nauczycieli i&nbsp;autorów podręczników',
        },
        {
          id: 3,
          img: Advantages12,
          text: 'wskazówki do zadań i&nbsp;filmy z&nbsp;rozwiązaniami',
        },

        {
          id: 4,
          img: Advantages13,
          text: 'zegar odliczający czas do końca rozwiązywania zestawu zadań',
        },
        {
          id: 5,
          img: Advantages7,
          text: 'brak reklam',
        },
        {
          id: 6,
          img: Advantages9,
          text: 'jednorazowa opłata za dostęp na rok, pół roku, miesiąc lub tydzień',
        },
      ],
      demoLink: 'https://powtorkomat8.apps.gwo.pl/0',
      buyLink: 'https://ksiegarnia.gwo.pl/product/powtorkomat-8',
      descriptionTitle: ``,
      descriptionParagraphs: [
        {
          id: 1,
          subheading: `Powtórka z matematyki przed egzaminem ósmoklasisty`,
          text: `<b><i>PowtórkoMat&nbsp;8</i></b> to <b>interaktywne zadania powtórkowe do egzaminu ósmoklasisty z&nbsp;matematyki</b>. Nasza eduGra 
          umożliwia kompletne i&nbsp;bezstresowe <b>przygotowanie do egzaminu ósmoklasisty</b>. W&nbsp;programie znajdują się wszystkie 
          typy zadań pojawiających się na egzaminie. Można je przećwiczyć rozwiązując zadania po kilka razy – przy kolejnym 
          uruchomieniu dane w przykładzie się zmieniają.
          `,
        },
        {
          id: 2,
          subheading: null,
          text: `W&nbsp;pierwszej części <i>PowtórkoMatu&nbsp;8</i> ósmoklasiści mogą rozwiązywać <b>ćwiczenia powtórzeniowe z&nbsp;matematyki</b>. W&nbsp;wypadku 
          trudności z&nbsp;którymś z&nbsp;zadań można skorzystać z&nbsp;wyjaśnienia, jak rozwiązać dane zadanie krok po kroku. Wskazówki dostępne 
          są w&nbsp;formie filmów z&nbsp;lektorem lub opisów. Pomaga to również ósmoklasiście w&nbsp;usystematyzowaniu wiedzy z&nbsp;danego tematu.
          `,
        },
        {
          id: 3,
          subheading: `Zadania egzaminacyjne (matematyka klasa&nbsp;8)`,
          text: `Druga część programu to próbne zestawy testowe zawierające zadania egzaminacyjne (matematyka klasa&nbsp;8). 
          Po rozwiązaniu testu pojawia się raport. Widać w nim podsumowanie oraz szczegóły podanych odpowiedzi – pozwala 
          to ocenić przygotowanie do egzaminu. W&nbsp;naszej eduGrze <i>PowtórkoMat&nbsp;8</i> do danego testu sprawdzającego można podejść 
          ponowie, poprawiając tylko poprzednio błędnie udzielone odpowiedzi, lub też zaczynając test od nowa.
          `,
        },
        {
          id: 4,
          subheading: `Zagadanienia w&nbsp;<i>PowtórkoMacie</i>`,
          text: `<i>PowtórkoMat&nbsp;8</i> zawiera następujące zagadnienia: <b>liczby całkowite</b>, <b>ułamki</b>, <b>oś liczbowa</b>, <b>działania na liczbach</b>, 
          <b>potęgi</b>, <b>pierwiastki</b>, <b>wyrażenia algebraiczne</b>, <b>przekształcanie wyrażeń algebraicznych</b>, <b>równania</b>, <b>proste</b>, <b>odcinki</b>, <b>kąty</b>, 
          <b>trójkąty</b>, <b>twierdzenie Pitagorasa</b>, <b>wielokąty</b>, <b>koła</b>, <b>okręgi</b>, <b>układ współrzędnych na płaszczyźnie</b>, <b>graniastosłupy</b>, 
          <b>ostrosłupy</b>, <b>obliczenia praktyczne</b>, <b>procenty</b>, <b>statystyka</b>, <b>prawdopodobieństwo</b>, <b>zastosowania w&nbsp;geometrii</b>.
          `,
        },
        {
          id: 5,
          subheading: `Bezpieczna nauka i&nbsp;zabawa`,
          text: `W&nbsp;eduGrze <i>PowtórkoMat&nbsp;8</i> nie ma żadnych reklam, więc bez obaw można pozwolić dziecku na samodzielną naukę 
          z&nbsp;programem. Treści zawarte w&nbsp;ćwiczeniach zostały stworzone przez doświadczonych specjalistów i&nbsp;są one dopasowane do wieku gracza.
          `,
        },
      ],
    },
    'wladcy-slow': {
      h1: '<i>Władcy Słów</i>',
      h2: 'interaktywne ćwiczenia z&nbsp;języka polskiego dla dzieci',
      h3: '48 zł na rok | bezpłatna przesyłka',
      description: `Wciągające <b>ćwiczenia ortograficzne online, interaktywne zadania z&nbsp;języka polskiego</b> dla dzieci w&nbsp;wieku <b>9–12&nbsp;lat</b> 
      (<b>klasy&nbsp;4–6</b>). Gracze wybierają bohaterów, którzy będą im towarzyszyli podczas rozwiązywania zadań. Każdy chce wyposażyć 
      swoje postacie lepiej niż inni – waleczny krasnolud potrzebuje przecież zbroi, a&nbsp;zwiewna driada – twarzowego kapelusika. 
      Aby zdobyć najwspanialsze stroje i&nbsp;ekwipunek, trzeba się wykazać doskonałą znajomością języka polskiego (<b>ortografii, 
      interpunkcji, gramatyki</b> itd.).
      `,
      advantagesDescriptionTitle: `<b>Zalety <i>Władców Słów</i></b>`,
      advantagesDescription: `<i>Władców Słów</i> polecamy zarówno uzdolnionym dzieciom, jak i&nbsp;tym, którym
        nauka sprawia trudności. Nasza eduGra w&nbsp;prosty i&nbsp;przyjazny sposób <b>pomoże</b> każdemu dziecku
        <b>uzupełnić wiedzę</b>, <b>zdobyć nowe umiejętności</b>, <b>uwierzyć we własne siły i&nbsp;podnieść samoocenę!</b>
      `,
      pdfButton: null,
      gallery: [
        {
          img: wladcySlowScreen1,
          alt: 'Ćwiczenia z języka polskiego dla dzieci, widok gry Władcy Słów - rozsypanka sylab',
        },
        {
          img: wladcySlowScreen2,
          alt: 'Ćwiczenia ortograficzne online, widok gry Władcy Słów - krzyżówka',
        },
        {
          img: wladcySlowScreen3,
          alt: 'Ćwiczenia ortograficzne online, widok gry Władcy Słów - labirynt ortograficzny',
        },
        {
          img: wladcySlowScreen4,
          alt: 'Ćwiczenia ortograficzne online, widok gry Władcy Słów - rozsypanka',
        },
      ],
      reviewLink: 'https://mojedziecikreatywnie.pl/2020/09/wladcy-slow-program-do-nauki-ortografii',
      advantages: [
        {
          id: 1,
          img: Advantages1,
          text: `ponad 350 zadań ze zmieniającymi się przykładami`,
        },
        {
          id: 2,
          img: Advantages2,
          text: 'zadania przygotowane przez nauczycieli i&nbsp;autorów podręczników',
        },
        {
          id: 3,
          img: Advantages11,
          text: 'wirtualne nagrody za osiągnięcia',
        },

        {
          id: 4,
          img: Advantages10,
          text: 'rankingi z wynikami graczy z&nbsp;całej Polski',
        },
        {
          id: 5,
          img: Advantages7,
          text: 'brak reklam',
        },
        {
          id: 6,
          img: Advantages9,
          text: 'jednorazowa opłata za dostęp na rok, pół roku, miesiąc lub tydzień',
        },
      ],
      demoLink: 'https://wladcyslow.apps.gwo.pl/0',
      buyLink: 'https://ksiegarnia.gwo.pl/product/wladcy-slow',
      descriptionTitle: ``,
      descriptionParagraphs: [
        {
          id: 1,
          subheading: null,
          text: `EduGra <b><i>Władcy Słów</i></b> to animowane <b>ćwiczenia z&nbsp;języka polskiego</b> dla dzieci w&nbsp;wieku <b>9&nbsp;lat, 10&nbsp;lat, 11&nbsp;lat</b> oraz <b>12&nbsp;lat</b>. 
          Została ona stworzona z&nbsp;myślą, że najskuteczniejsza nauka to <b>nauka poprzez zabawę</b>. We <i>Władcach Słów</i> dzieci znajdą 
          wciągające <b>gry ortograficzne online</b>. Jest też mnóstwo zadań pomagających przećwiczyć zagadnienia, których 
          znajomości wymaga się w&nbsp;<b>klasie&nbsp;4, klasie&nbsp;5 i&nbsp;klasie&nbsp;6 szkoły podstawowej</b>. 
          `,
        },
        {
          id: 2,
          subheading: null,
          text: `Gra edukacyjna dla dzieci online <i>Władcy Słów</i> w&nbsp;atrakcyjny sposób zachęca do sprawdzania umiejętności gracza 
          z&nbsp;języka polskiego oraz do podejmowania prób ich rozwijania. Program został stworzony w&nbsp;taki sposób, by skłonić dziecko 
          do wielokrotnego rozwiązywania ćwiczeń, dzięki czemu utrwalają one wiedzę. <i>Władcy Słów</i> oferują dzieciom wybór bohatera, 
          z&nbsp;którym mogą one rozwiązywać zadania. Za każde poprawnie ukończone ćwiczenie na koncie gracza pojawia się określona ilość 
          wirtualnego złota. Waluta ta pozwala na zakupienie bohaterowi elementów stroju i&nbsp;ekwipunku. Kolejne rozwiązane zadania 
          odblokowują możliwość zakupu innych bohaterów. <b>Gry ortograficzne online</b> oraz inne zadania rozwijające kompetencje z&nbsp;języka 
          polskiego stają się więc środkiem do celu – zdobycia wymarzonego bohatera i&nbsp;dopasowania jego wyglądu do gustu gracza. 
          `,
        },
        {
          id: 3,
          subheading: null,
          text: `Nasze <b>interaktywne ćwiczenia z&nbsp;języka polskiego dla dzieci</b> to sprytna metoda na zamianę zazwyczaj nudnych powtórek 
          we wciągającą rozrywkę. Przy kolejnych uruchomieniach tych samych zadań w&nbsp;naszej eduGrze pojawiają się inne dane. Dzięki 
          temu nie można „wykuć” odpowiedzi – program sprawdza faktyczne opanowanie danego zagadnienia. 
          `,
        },
        {
          id: 4,
          subheading: null,
          text: `Sposobem na dodatkowe zaangażowanie gracza w&nbsp;rozwijanie jego umiejętności z&nbsp;języka polskiego z&nbsp;naszą grą edukacyjną 
          online <i>Władcy Słów</i> mogą stać się również punkty zdobywane przy każdym rozwiązaniu zadania. Punkty te umieszczane są 
          w&nbsp;ogólnopolskim rankingu gry i&nbsp;można tam porównać swoje wyniki z&nbsp;osiągnięciami innych graczy.
          `,
        },
        {
          id: 5,
          subheading: `Zagadnienia we <i>Władcach Słów</i>`,
          text: `Nasze ćwiczenia online obejmują takie tematy, jak: <b>odmiana rzeczownika</b> (również: <b>odmiana przez przypadki</b>), 
          <b>odmiana przymiotnika</b> oraz <b>odmiana czasownika</b>. Rozwiązując animowane zadania, dziecko sprawdzi swoją znajomość oraz 
          umiejętność poprawnego zastosowania <b>liczebników</b>, <b>zaimków rzeczownych</b>, <b>przymiotnych</b>, <b>przysłownych</b> oraz <b>liczebnych</b>, 
          <b>przyimków</b> i&nbsp;<b>wyrażeń przyimkowych</b>, <b>przysłówków</b>. Dzięki interaktywnym zadaniom oraz ciekawym bohaterom gry 
          <b><i>Władcy Słów</i></b> łatwo utrwali materiał z&nbsp;zakresu użycia spójników, wykrzyknika i&nbsp;partykuły. Program w&nbsp;ciekawy sposób 
          prezentuje również ćwiczenia z&nbsp;<b>typów wypowiedzeń</b>, <b>zdań pojedynczych</b> i&nbsp;<b>zdań złożonych</b> oraz rozbioru zdania. Z&nbsp;grą 
          <i>Władcy Słów</i> dziecko powtórzy sobie materiał z&nbsp;zakresu rodziny wyrazów, <b>wyrazów pokrewnych</b>, <b>synonimów</b>, <b>antonimów</b> 
          i&nbsp;<b>homonimów</b> oraz <b>frazeologizmów</b>. Władcy Słów pomagają też poćwiczyć <b>czytanie ze zrozumieniem</b> oraz pisanie różnych tekstów.
          `,
        },
        {
          id: 6,
          subheading: `Bezpieczna nauka i&nbsp;zabawa`,
          text: `W&nbsp;eduGrze <i>Władcy Słów</i> nie ma żadnych reklam, więc bez obaw można pozwolić dziecku na samodzielną naukę z&nbsp;programem. 
          Treści zawarte w&nbsp;ćwiczeniach zostały stworzone przez doświadczonych specjalistów i&nbsp;są one dopasowane do wieku gracza.
          `,
        },
      ],
    },
    'informatyka-1': {
      h1: '<i>Informatyka 1</i>',
      h2: 'nauka programowania dla dzieci',
      h3: '38 zł na rok | bezpłatna przesyłka',
      description: `Gra edukacyjna dla dzieci z&nbsp;<b>klasy&nbsp;1</b> (wiek <b>6&nbsp;i&nbsp;7&nbsp;lat</b>) w&nbsp;formie animowanych ćwiczeń <b>online</b> na komputer. 
      Nauka <b>programowania wizualnego</b> wprowadzana jest w&nbsp;postaci prostych gier (<i>UFO</i> i&nbsp;<i>Pan Ołówek</i>) o&nbsp;rosnącym stopniu trudności. 
      W&nbsp;naszej eduGrze <i>Informatyka&nbsp;1</i> znajdują się także zadania doskonalące <b>posługiwanie się myszką komputerową</b> oraz <b>szybkie 
      bezwzrokowe pisanie na klawiaturze wszystkimi palcami</b>.
      `,
      advantagesDescriptionTitle: `<b>Zalety <i>Informatyki 1</i></b>`,
      advantagesDescription: `<i>Informatykę 1</i> polecamy zarówno uzdolnionym dzieciom, jak i&nbsp;tym, którym nauka sprawia trudności. 
      Nasza eduGra w&nbsp;prosty i&nbsp;przyjazny sposób <b>pomoże</b> każdemu dziecku <b>uzupełnić wiedzę</b>, <b>zdobyć nowe umiejętności</b>, <b>uwierzyć 
      we własne siły i&nbsp;podnieść samoocenę</b>!`,
      pdfButton: informatyka1pdf,
      gallery: [
        {
          img: informatyka1Screen1,
          alt: 'Nauka programowania dla dzieci widok gry Informatyka 1 - programowanie wizualne Pan Ołówek',
        },
        {
          img: informatyka1Screen2,
          alt: 'Nauka pisania na klawiaturze dla dzieci widok gry Informatyka 1',
        },
        {
          img: informatyka1Screen3,
          alt: 'Nauka programowania dla dzieci widok gry Informatyka 1 - programowanie wizualne UFO',
        },
        {
          img: informatyka1Screen4,
          alt: 'Ćwiczenie pisania na klawiaturze widok gry Informatyka 1',
        },
      ],
      reviewLink: `https://www.pozeramstrony.pl/2019/01/programowanie-dla-dzieci-zostan.html`,
      advantages: [
        {
          id: 1,
          img: Advantages1,
          text: `ponad 120 zadań ze zmieniającymi się przykładami`,
        },
        {
          id: 2,
          img: Advantages14,
          text: 'zróżnicowany poziom trudności',
        },
        {
          id: 3,
          img: Advantages8,
          text: 'czytane na głos polecenia',
        },

        {
          id: 4,
          img: Advantages4,
          text: 'zadania przygotowane przez specjalistów',
        },
        {
          id: 5,
          img: Advantages7,
          text: 'brak reklam',
        },
        {
          id: 6,
          img: Advantages9,
          text: 'jednorazowa opłata za dostęp na rok, pół roku, miesiąc lub tydzień',
        },
      ],
      demoLink: 'https://informatyka1.apps.gwo.pl/0',
      buyLink: 'https://ksiegarnia.gwo.pl/product/informatyka-klasa-1',
      descriptionTitle: ``,
      descriptionParagraphs: [
        {
          id: 1,
          subheading: `Kodowanie dla dzieci online – nauka poprzez zabawę`,
          text: `O tym, że programista to zawód przyszłości, nie trzeba nikogo przekonywać. Kodowanie to umiejętność, 
          której warto się uczyć już od najmłodszych lat. Pomocna tu może być eduGra <i>Informatyka&nbsp;1</i>, <b>czyli nauka 
          programowania dla dzieci poprzez zabawę</b> przeznaczona głównie dla <b>6-latka i&nbsp;7-latka</b>.
          `,
        },
        {
          id: 2,
          subheading: null,
          text: `EduGra <i>Informatyka&nbsp;1</i> to nie tylko <b>kodowanie dla dzieci online</b> w&nbsp;formie atrakcyjnych, animowanych ćwiczeń. 
          Korzystając z&nbsp;naszego programu, dziecko zdobędzie także inne, towarzyszące programowaniu, <b>umiejętności 
          cyfrowe</b>. Gra podzielona jest na 6&nbsp;części: <b>wstęp do programowania</b>, <b>programowanie wizualne</b> (2&nbsp;części), 
          <b>obsługa myszki komputerowej</b>, <b>nauka liter i&nbsp;pisania na klawiaturze</b> (2&nbsp;części).
          `,
        },
        {
          id: 3,
          subheading: `Nauka programowania dla dzieci`,
          text: `Ćwiczenia z&nbsp;zakresu programowania w&nbsp;eduGrze <i>Informatyka&nbsp;1</i> rozwijają u&nbsp;dziecka wielorakie umiejętności powiązane 
          z&nbsp;kodowaniem. <b>Kodowanie dla dzieci online</b> w&nbsp;formie naszej gry edukacyjnej wzmacnia dodatkowo takie kompetencje, 
          jak <b>analiza</b>, <b>wyciąganie wniosków</b> czy <b>odkrywanie algorytmów</b>. <b>Nauka programowania dla dzieci</b> w&nbsp;eduGrze <i>Informatyka&nbsp;1</i> 
          prowadzona jest w atrakcyjny i&nbsp;przystępny dla dziecka sposób. Gracz przechodzi stopniowo do coraz trudniejszych 
          zadań – od wstępu do programowania aż do budowania złożonych sekwencji bloków („kodu”). Dziecko układa odpowiednie 
          sekwencje bloków, a&nbsp;następnie uruchamia je i&nbsp;sprawdza poprawność swojego „kodu”.
          `,
        },
        {
          id: 4,
          subheading: `Posługiwanie się myszką komputerową`,
          text: `<b>Nauka obsługi myszki dla dzieci</b> w&nbsp;<i>Informatyce&nbsp;1</i> odbywa się poprzez rozwiązywanie dostosowanych do wieku dziecka 
          zagadek i&nbsp;animowanych zadań interaktywnych. W&nbsp;grach z&nbsp;tej części trenowane są <b>pojedyncze i&nbsp;podwójne kliknięcia</b> oraz 
          przytrzymanie przycisków myszki komputerowej. Dziecko przeciąga i&nbsp;upuszcza elementy za pomocą kursora, a&nbsp;w&nbsp;niektórych 
          ćwiczeniach ma za zadanie <b>wodzić kursorem po określonej drodze</b>. W&nbsp;ten sposób dodatkowo doskonalona jest <b>spostrzegawczość</b> 
          oraz <b>koordynacja wzrokowo-ruchowa</b>, <b>a&nbsp;nauka obsługi myszki dla dzieci</b> przebiega w&nbsp;atrakcyjny sposób w&nbsp;formie zabawy.
          `,
        },
        {
          id: 5,
          subheading: `Nauka pisania na klawiaturze dla dzieci`,
          text: `Szybkie pisanie na klawiaturze to obecnie bardzo przydatna umiejętność, która świetnie sprawdza się w&nbsp;profesjonalnym programowaniu. 
          Celem ćwiczeń w&nbsp;części nauka liter i&nbsp;<b>nauka pisania na klawiaturze dla dzieci</b> w&nbsp;<i>Informatyce&nbsp;1</i> jest zapamiętanie, gdzie na 
          klawiaturze znajdują się poszczególne litery, oraz wykształcenie nawyków, dzięki którym w&nbsp;przyszłości łatwiej będzie <b>pisać 
          bezwzrokowo</b>. Zadania pozwalają na bezstresowe <b>ćwiczenie pisania na klawiaturze</b>, a&nbsp;także na sprawdzenie umiejętności gracza w&nbsp;trybie „na czas”.
          `,
        },
        {
          id: 6,
          subheading: `Bezpieczna nauka i&nbsp;zabawa`,
          text: `W <i>Informatyce&nbsp;1</i> nie ma żadnych reklam, więc bez obaw można pozwolić dziecku na samodzielną naukę z&nbsp;programem. 
          Treści zawarte w&nbsp;ćwiczeniach zostały stworzone przez doświadczonych specjalistów i&nbsp;są one dopasowane do wieku gracza.
          `,
        },
      ],
    },
    'informatyka-2': {
      h1: '<i>Informatyka 2</i>',
      h2: 'nauka programowania dla dzieci',
      h3: '38 zł na rok | bezpłatna przesyłka',
      description: `<b>Gra edukacyjna dla dzieci</b> z&nbsp;<b>klasy&nbsp;2</b> (wiek <b>7&nbsp;i&nbsp;8&nbsp;lat</b>) w&nbsp;postaci animowanych ćwiczeń <b>online</b> na komputer. 
      W&nbsp;<i>Informatyce&nbsp;2</i> <b>programowanie wizualne</b> wprowadzane jest w&nbsp;formie prostych gier (<i>UFO</i> i&nbsp;<i>Pan Ołówek</i>) o&nbsp;rosnącym stopniu 
      trudności. Dostępny jest również <b>kurs programowania dla dzieci w&nbsp;języku Scratch</b>. W&nbsp;naszej eduGrze znajdują się także 
      zadania doskonalące <b>szybkie bezwzrokowe pisanie na klawiaturze wszystkimi palcami</b>. Oprócz tego dzieci dowiadują się, 
      do czego może się przydać na co dzień <b>edytor tekstu</b> (np. <i>MS Word</i>).
      `,
      advantagesDescriptionTitle: `<b>Zalety <i>Informatyki 2</i></b>`,
      advantagesDescription: `<i>Informatykę&nbsp;2</i> polecamy zarówno uzdolnionym dzieciom, jak i&nbsp;tym, którym nauka sprawia trudności. 
      Nasza eduGra w&nbsp;prosty i&nbsp;przyjazny sposób <b>pomoże</b> każdemu dziecku <b>uzupełnić wiedzę</b>, <b>zdobyć nowe umiejętności</b>, <b>uwierzyć 
      we własne siły i&nbsp;podnieść samoocenę</b>!`,
      pdfButton: informatyka2pdf,
      gallery: [
        {
          img: informatyka2Screen1,
          alt: 'Ćwiczenie pisania na klawiaturze widok gry Informatyka 2',
        },
        {
          img: informatyka2Screen2,
          alt: 'Nauka pisania na klawiaturze dla dzieci widok gry Informatyka 2',
        },
        {
          img: informatyka2Screen3,
          alt: 'Nauka programowania dla dzieci widok gry Informatyka 2 - programowanie wizualne Pan Ołówek',
        },
        {
          img: informatyka2Screen4,
          alt: 'Nauka programowania dla dzieci widok gry Informatyka 2 - programowanie wizualne UFO',
        },
      ],
      reviewLink: null,
      advantages: [
        {
          id: 1,
          img: Advantages1,
          text: `ponad 120 zadań ze zmieniającymi się przykładami`,
        },
        {
          id: 2,
          img: Advantages14,
          text: 'zróżnicowany poziom trudności',
        },
        {
          id: 3,
          img: Advantages8,
          text: 'czytane na głos polecenia',
        },

        {
          id: 4,
          img: Advantages4,
          text: 'zadania przygotowane przez specjalistów',
        },
        {
          id: 5,
          img: Advantages7,
          text: 'brak reklam',
        },
        {
          id: 6,
          img: Advantages9,
          text: 'jednorazowa opłata za dostęp na rok, pół roku, miesiąc lub tydzień',
        },
      ],
      demoLink: 'https://informatyka2.apps.gwo.pl/0',
      buyLink: 'https://ksiegarnia.gwo.pl/product/informatyka-klasa-2',
      descriptionTitle: ``,
      descriptionParagraphs: [
        {
          id: 1,
          subheading: `Kodowanie dla dzieci online – nauka poprzez zabawę`,
          text: `O tym, że programista to zawód przyszłości, nie trzeba nikogo przekonywać. Kodowanie to umiejętność, 
          której warto się uczyć już od najmłodszych lat. Pomocna tu może być <b>gra edukacyjna online na komputer</b> <i>Informatyka&nbsp;2</i>, 
          czyli <b>nauka programowania dla dzieci poprzez zabawę</b> przeznaczona głównie dla <b>7-latka i&nbsp;8-latka</b>.
          `,
        },
        {
          id: 2,
          subheading: null,
          text: `EduGra <i>Informatyka&nbsp;2</i> to nie tylko <b>kodowanie dla dzieci online</b> w&nbsp;formie atrakcyjnych animowanych ćwiczeń. 
          Korzystając z&nbsp;naszego programu, dziecko zdobędzie także inne, towarzyszące programowaniu, umiejętności cyfrowe. 
          Gra podzielona jest na 6&nbsp;części: <b>programowanie wizualne</b> (2&nbsp;części), <b>lekcje Scratch</b> (filmy), <b>nauka pisania na 
          klawiaturze</b> (2&nbsp;części), <b>praca z&nbsp;edytorem tekstu</b> (szablony do pracy).
          `,
        },
        {
          id: 3,
          subheading: `Nauka programowania dla dzieci`,
          text: `Ćwiczenia z&nbsp;zakresu programowania w&nbsp;eduGrze <i>Informatyka&nbsp;2</i> rozwijają u&nbsp;dziecka wielorakie umiejętności 
          powiązane z&nbsp;kodowaniem. <b>Kodowanie dla dzieci online</b> w&nbsp;formie naszej gry edukacyjnej wzmacnia dodatkowo takie 
          kompetencje, jak <b>analiza</b>, <b>wyciąganie wniosków</b> czy <b>odkrywanie algorytmów</b>. <b>Nauka programowania dla dzieci</b> 
          w&nbsp;eduGrze <i>Informatyka&nbsp;2</i> prowadzona jest w&nbsp;atrakcyjny i&nbsp;przystępny dla dziecka sposób. Gracz przechodzi 
          stopniowo do coraz trudniejszych zadań. Dziecko układa odpowiednie sekwencje bloków, a&nbsp;następnie uruchamia 
          je i&nbsp;sprawdza poprawność swojego „kodu”.
          `,
        },
        {
          id: 4,
          subheading: null,
          text: `Scratch to darmowy program stworzony przez MIT (<i>Massachusetts Institute of Technology</i>). 
          Kurs Scratch dostępny w&nbsp;naszej eduGrze w&nbsp;formie filmów w&nbsp;przystępny sposób daje dziecku możliwość 
          zapoznania się z&nbsp;tym narzędziem. Zaprezentowane podstawy działania programu oraz zadania mają na 
          celu zachęcić dziecko do podjęcia własnych eksperymentów, realizacji własnych pomysłów oraz do dalszego rozwoju w&nbsp;tym kierunku.
          `,
        },
        {
          id: 5,
          subheading: `Nauka pisania na klawiaturze dla dzieci`,
          text: `Szybkie pisanie na klawiaturze to obecnie bardzo przydatna umiejętność, która świetnie sprawdza się 
          w&nbsp;profesjonalnym programowaniu. Celem ćwiczeń w&nbsp;części <b>nauka pisania na klawiaturze dla dzieci</b> w&nbsp;<i>Informatyce&nbsp;2</i> 
          jest zapamiętanie, gdzie na klawiaturze znajdują się poszczególne litery, oraz wykształcenie nawyków, dzięki 
          którym w&nbsp;przyszłości łatwiej będzie <b>pisać bezwzrokowo</b>. Zadania pozwalają na bezstresowe <b>ćwiczenie pisania na 
          klawiaturze</b>, a&nbsp;także na sprawdzenie umiejętności gracza w&nbsp;trybie „na czas”.
          `,
        },
        {
          id: 6,
          subheading: `Korzystanie z edytora tekstu`,
          text: `Z&nbsp;kartami pracy dostępnymi do pobrania w&nbsp;naszej eduGrze <i>Informatyka&nbsp;2</i> dziecko przećwiczy korzystanie 
          ze skrótów klawiszowych. Zadania zachęcą także do zapoznania się z&nbsp;funkcjami, które ułatwiają pracę 
          z&nbsp;programem oraz pozwalają tworzyć ciekawe i&nbsp;kolorowe dokumenty.
          `,
        },
        {
          id: 7,
          subheading: `Bezpieczna nauka i&nbsp;zabawa`,
          text: `W <i>Informatyce&nbsp;2</i> nie ma żadnych reklam, więc bez obaw można pozwolić dziecku na samodzielną naukę 
          z&nbsp;programem. Treści zawarte w&nbsp;ćwiczeniach zostały stworzone przez doświadczonych specjalistów i&nbsp;są one dopasowane do wieku gracza.
          `,
        },
      ],
    },
    'informatyka-3': {
      h1: '<i>Informatyka 3</i>',
      h2: 'nauka programowania dla dzieci',
      h3: '38 zł na rok | bezpłatna przesyłka',
      description: `<b>Gra edukacyjna dla dzieci</b> z&nbsp;</b>klasy&nbsp;3</b> (wiek <b>8&nbsp;i&nbsp;9&nbsp;lat</b>) w&nbsp;postaci animowanych ćwiczeń <b>online</b> na komputer. 
      W&nbsp;<i>Informatyce&nbsp;3</i> <b>programowanie wizualne</b> wprowadzane jest w&nbsp;formie prostych gier (<i>UFO</i> i&nbsp;<i>Pan Ołówek</i>) o&nbsp;rosnącym 
      stopniu trudności. W&nbsp;naszej eduGrze znajdują się także zadania doskonalące szybkie <b>bezwzrokowe pisanie na klawiaturze 
      wszystkimi palcami</b>. Oprócz tego dzieci dowiadują się, do czego mogą się przydać na co dzień <b>edytor grafiki</b> (np. <i>MS Paint</i>) 
      i&nbsp;edytor tekstu (np. <i>MS Word</i>) oraz <b>jak bezpiecznie korzystać z&nbsp;komputera i&nbsp;internetu</b>.
      `,
      advantagesDescriptionTitle: `<b>Zalety <i>Informatyki&nbsp;3</i></b>`,
      advantagesDescription: `<i>Informatykę&nbsp;3</i> polecamy zarówno uzdolnionym dzieciom, jak i&nbsp;tym, którym nauka sprawia trudności. 
      Nasza eduGra w&nbsp;prosty i&nbsp;przyjazny sposób <b>pomoże</b> każdemu dziecku <b>uzupełnić wiedzę</b>, <b>zdobyć nowe umiejętności</b>, <b>uwierzyć 
      we własne siły i&nbsp;podnieść samoocenę</b>!`,
      pdfButton: informatyka3pdf,
      gallery: [
        {
          img: informatyka3Screen1,
          alt: 'Ćwiczenie pisania na klawiaturze widok gry Informatyka 3',
        },
        {
          img: informatyka3Screen2,
          alt: 'Nauka pisania na klawiaturze dla dzieci widok gry Informatyka 3',
        },
        {
          img: informatyka3Screen3,
          alt: 'Nauka programowania dla dzieci widok gry Informatyka 3 - programowanie wizualne UFO',
        },
        {
          img: informatyka3Screen4,
          alt: 'Nauka programowania dla dzieci widok gry Informatyka 3 - programowanie wizualne Pan Ołówek',
        },
      ],
      reviewLink: null,
      advantages: [
        {
          id: 1,
          img: Advantages1,
          text: `ponad 140 zadań ze zmieniającymi się przykładami`,
        },
        {
          id: 2,
          img: Advantages14,
          text: 'zróżnicowany poziom trudności',
        },
        {
          id: 3,
          img: Advantages8,
          text: 'czytane na głos polecenia',
        },

        {
          id: 4,
          img: Advantages4,
          text: 'zadania przygotowane przez specjalistów',
        },
        {
          id: 5,
          img: Advantages7,
          text: 'brak reklam',
        },
        {
          id: 6,
          img: Advantages9,
          text: 'jednorazowa opłata za dostęp na rok, pół roku, miesiąc lub tydzień',
        },
      ],
      demoLink: 'https://informatyka3.apps.gwo.pl/0',
      buyLink: 'https://ksiegarnia.gwo.pl/product/informatyka-klasa-3',
      descriptionTitle: ``,
      descriptionParagraphs: [
        {
          id: 1,
          subheading: `Kodowanie dla dzieci online – nauka poprzez zabawę`,
          text: `O tym, że programista to zawód przyszłości, nie trzeba nikogo przekonywać. Kodowanie to umiejętność, 
          której warto się uczyć już od najmłodszych lat. Pomocna tu może być <b>gra edukacyjna online na komputer</b> <i>Informatyka&nbsp;3</i>, 
          czyli <b>nauka programowania dla dzieci</b> poprzez zabawę. Zadania w&nbsp;<i>Informatyce&nbsp;3</i> są przeznaczone głównie dla <b>8-latka</b> i&nbsp;<b>9-latka</b>. 
          `,
        },
        {
          id: 2,
          subheading: null,
          text: `EduGra <i>Informatyka&nbsp;3</i> to nie tylko <b>kodowanie dla dzieci online</b> w&nbsp;formie atrakcyjnych animowanych ćwiczeń. 
          Korzystając z&nbsp;naszego programu, dziecko zdobędzie także inne, towarzyszące programowaniu, umiejętności cyfrowe. 
          Gra podzielona jest na 6&nbsp;działów: <b>programowanie wizualne</b> (2&nbsp;części), <b>nauka pisania na klawiaturze</b> (2&nbsp;części), 
          <b>praca z&nbsp;edytorami tekstu i&nbsp;grafiki</b> (szablony do pracy), <b>internet i&nbsp;bezpieczeństwo</b> (filmy).  
          `,
        },
        {
          id: 3,
          subheading: `Nauka programowania dla dzieci`,
          text: `Ćwiczenia z&nbsp;zakresu programowania w&nbsp;eduGrze <i>Informatyka&nbsp;3</i> rozwijają u&nbsp;dziecka wielorakie umiejętności powiązane z&nbsp;kodowaniem. 
          <b>Kodowanie dla dzieci online</b> w&nbsp;formie naszej gry edukacyjnej wzmacnia dodatkowo takie kompetencje, jak <b>analiza</b>, <b>wyciąganie wniosków</b> 
          czy <b>odkrywanie algorytmów</b>. <b>Nauka programowania dla dzieci</b> w&nbsp;eduGrze <i>Informatyka&nbsp;3</i> prowadzona jest w&nbsp;atrakcyjny i&nbsp;przystępny dla 
          dziecka sposób. Gracz przechodzi stopniowo do coraz trudniejszych zadań. Dziecko układa odpowiednie sekwencje bloków, a&nbsp;następnie 
          uruchamia je i&nbsp;sprawdza poprawność swojego „kodu”.   
          `,
        },
        {
          id: 4,
          subheading: `Nauka pisania na klawiaturze dla dzieci`,
          text: `Szybkie pisanie na klawiaturze to obecnie bardzo przydatna umiejętność, która świetnie sprawdza się w&nbsp;profesjonalnym programowaniu. 
          Celem ćwiczeń w części <b>nauka pisania na klawiaturze dla dzieci</b> w&nbsp;<i>Informatyce 3</i> jest zapamiętanie, gdzie na klawiaturze znajdują się poszczególne 
          litery, oraz wykształcenie nawyków, dzięki którym w przyszłości łatwiej będzie <b>pisać bezwzrokowo</b>. Zadania pozwalają na bezstresowe <b>ćwiczenie 
          pisania na klawiaturze</b>, a&nbsp;także na sprawdzenie umiejętności gracza w&nbsp;trybie „na czas”.   
          `,
        },
        {
          id: 5,
          subheading: `Korzystanie z&nbsp;edytora tekstu i&nbsp;grafiki`,
          text: `Z&nbsp;kartami pracy dostępnymi do pobrania w&nbsp;naszej eduGrze <i>Informatyka&nbsp;3</i> dziecko przećwiczy <b>korzystanie ze skrótów klawiszowych</b>. 
          Zadania zachęcą także do <b>zapoznania się z&nbsp;funkcjami</b>, które ułatwiają pracę z&nbsp;programami oraz pozwalają tworzyć ciekawe i&nbsp;kolorowe dokumenty.   
          `,
        },
        {
          id: 6,
          subheading: `Bezpieczna nauka i&nbsp;zabawa`,
          text: `W&nbsp;<i>Informatyce&nbsp;3</i> nie ma żadnych reklam, więc bez obaw można pozwolić dziecku na samodzielną naukę z&nbsp;programem. 
          Treści zawarte w&nbsp;ćwiczeniach zostały stworzone przez doświadczonych specjalistów i&nbsp;są one dopasowane do wieku gracza.   
          `,
        },
      ],
    },
  },
};
