import { makeStyles } from '@material-ui/styles';
import { darkSlateBlue, transition, white } from 'styles/common/variables';

export const makeMenuMobileStyles = makeStyles({
  expandMoreIcon: {
    height: '28px',
    width: '28px',
  },
  expansionPanel: {
    boxShadow: 'none',

    '&.Mui-expanded': {
      margin: 0,
    },

    '&::before': {
      content: 'none',
    },
  },
  expansionPanelDetails: {
    padding: 0,
  },
  expansionPanelDetailsList: {
    padding: 0,
    width: '100%',
  },
  expansionPanelSummary: {
    padding: '0 25px 0 20px',

    '&.Mui-expanded': {
      minHeight: 'auto',
    },

    '& .MuiExpansionPanelSummary-content.Mui-expanded': {
      margin: '12px 0',
    },
  },
  expansionPanelTitle: {
    color: darkSlateBlue,
    fontSize: '0.875rem',
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  hamburgerButton: {
    position: 'absolute',
    transition: `all ${transition}`,
    left: '20px',
    height: '32px',
    width: '32px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '4px',

    '& .line': {
      height: '2px',
      width: '20px',
      background: darkSlateBlue,
      transition: 'all 0.2s ease',
    },

    '& .lineTop': {
      transformOrigin: 'top left',
      marginBottom: '5px',
    },

    '& .lineMiddle': {
      opacity: 1,
    },

    '& .lineBottom': {
      transformOrigin: 'top left',
      marginTop: '5px',
    },

    '&.transform': {
      left: 'calc(70% - 52px)',
      zIndex: 1310,

      '& .lineTop': {
        transform: 'rotate(45deg)',
      },

      '& .lineMiddle': {
        opacity: 0,
        transform: 'translateX(-16px)',
      },

      '& .lineBottom': {
        transform: 'translateX(-1px) rotate(-45deg)',
      },
    },
  },
  listWrapper: {
    marginTop: '72px',
  },
  paper: {
    backgroundColor: white,
    width: '70%',
  },
});
