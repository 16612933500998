import React, { useRef } from 'react';
import { NavLink } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Hamburger from './Hamburger';
import Typography from '@material-ui/core/Typography';
import { makeMenuMobileStyles } from 'styles/MobileMenu';
import { makeNavLinkStyles } from 'styles/NavLink';
import menu from 'common/menu';

const ForwardNavLink = React.forwardRef((props, ref) => <NavLink {...props} innerRef={ref} />);

const CustomListItem = ({ id, className, title, toggleDrawer, url }) => (
  <ListItem
    key={id}
    className={className}
    component={ForwardNavLink}
    onClick={toggleDrawer(false)}
    to={url}
  >
    {title}
  </ListItem>
);

const MobileMenu = ({ mobileMenuOpen, setMobileMenuOpen }) => {
  const { navLink } = makeNavLinkStyles();
  const {
    expandMoreIcon,
    expansionPanel,
    expansionPanelDetails,
    expansionPanelDetailsList,
    expansionPanelSummary,
    expansionPanelTitle,
    hamburgerButton,
    listWrapper,
    paper,
  } = makeMenuMobileStyles();
  const container = useRef(null);

  const toggleDrawer = open => e => {
    if (e.type === 'keydown' && (e.key === 'Tab' || e.key === 'Shift')) return;

    setMobileMenuOpen(open);
  };

  const side = () => (
    <div className={listWrapper} role="presentation" onKeyDown={toggleDrawer(false)}>
      <List>
        {menu.map(({ id, subMenu, title, url }) =>
          subMenu ? (
            <ExpansionPanel
              key={id}
              className={expansionPanel}
              TransitionProps={{ unmountOnExit: true }}
            >
              <ExpansionPanelSummary
                id={`panel-${id}`}
                aria-controls={`panel-${id}`}
                className={expansionPanelSummary}
                expandIcon={<ExpandMoreIcon className={expandMoreIcon} />}
              >
                <Typography className={expansionPanelTitle} component="h4">
                  {title}
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={expansionPanelDetails}>
                <ul className={expansionPanelDetailsList}>
                  {subMenu.map(props => (
                    <CustomListItem
                      key={props.id}
                      className={`${navLink} mobile__sub-nav-link`}
                      toggleDrawer={toggleDrawer}
                      {...props}
                    />
                  ))}
                </ul>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ) : (
            <CustomListItem
              key={id}
              id={id}
              className={`${navLink} mobile__nav-link`}
              toggleDrawer={toggleDrawer}
              title={title}
              url={url}
            />
          )
        )}
      </List>
    </div>
  );

  return (
    <Box
      display={{ xs: 'flex', md: 'none' }}
      justifyContent="flex-start"
      alignItems="center"
      height="100%"
    >
      <Hamburger
        hamburgerButton={hamburgerButton}
        mobileMenuOpen={mobileMenuOpen}
        toggleDrawer={toggleDrawer}
      />
      <Drawer
        ModalProps={container.current ? { container: container.current } : {}}
        classes={{ paper }}
        open={mobileMenuOpen}
        onClose={toggleDrawer(false)}
      >
        {side()}
      </Drawer>
      <div ref={container} />
    </Box>
  );
};

export default MobileMenu;
