import React from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeNotFoundStyles } from 'styles/pages/NotFound';
import { texts } from './texts';

const NotFound = () => {
  const {
    img: { alt, src },
    text,
    title,
  } = texts;

  const { container, h1, img, paragraph } = makeNotFoundStyles();

  return (
    <Container>
      <Grid container justify="center">
        <Grid item xs={11}>
          <Box className={container}>
            <Typography className={h1} dangerouslySetInnerHTML={{ __html: title }} variant="h1" />
            <Typography
              className={paragraph}
              dangerouslySetInnerHTML={{ __html: text }}
              component="p"
            />
            <img className={img} src={src} alt={alt} />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default NotFound;
