import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import { makeNavLinkStyles } from 'styles/NavLink';
import menu from 'common/menu';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const AdapterLink = React.forwardRef((props, ref) => <NavLink innerRef={ref} {...props} />);

const Nav = () => {
  const { navLink, submenuContainer, submenuWrapper, submenuTile, submenuLink, arrowSubmenu, } = makeNavLinkStyles();
  const [expandedId, setExpandedId] = React.useState();

  const handleUserClickOutsideMenu = (event) => {
    if (event.target.classList.contains('navigation-link')) {
      return;
    }
    setExpandedId();
  };

  const handleSubmenuClick = (id, subMenu, e) => {
    if(!subMenu) {
      return;
    }

    e.preventDefault();

    if(id === expandedId) {
      setExpandedId(0);
      return;
    }

    setExpandedId(id);
  }

  useEffect(() => {
    document.body.addEventListener('click', handleUserClickOutsideMenu);
    document.addEventListener('scroll', () => setExpandedId());
    return () => {
      document.body.removeEventListener('click', handleUserClickOutsideMenu);
    };
  }, []);

  useEffect(() => {
    const submenuContainer = document.querySelector('[class^="makeStyles-submenuContainer"]');
    if (submenuContainer) {
      submenuContainer.addEventListener('click', (e) => e.stopPropagation());
    }
  });

  return (
    <Box
      display={{ xs: 'none', md: 'flex' }}
      justifyContent="space-between"
      alignItems="center"
      height="100%"
    >
      {menu.map(({ id, title, url, subMenu, subMenuCustomTileStyle }) => (
        <div key={id}>
          <Link className={`navigation-link ${navLink}`} component={AdapterLink} to={url} onClick={(e) => handleSubmenuClick(id, subMenu, e)}>
            {title}
            {subMenu &&
                (expandedId !== id
                    ? <KeyboardArrowDownIcon className={arrowSubmenu}/>
                    : <KeyboardArrowUpIcon className={arrowSubmenu}/>
                )}
          </Link>
          {expandedId === id &&
          (
            <div className={submenuContainer}>
              <div className={submenuWrapper}>
                {subMenu.map(({ id, title, url, icon, }) => (
                  <Link key={id} className={submenuLink} component={AdapterLink} to={url}>
                    <div className={submenuTile} style={subMenuCustomTileStyle}>
                      <img src={icon} alt=""/>
                      <span>{title}</span>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          )}
        </div>
      ))}
    </Box>
  );
};

export default Nav;
