import React from 'react';
import { Introduction } from 'pages/LargeFamily';
import Container from '@material-ui/core/Container';
import { Grid } from '@material-ui/core';

const LargeFamily = () => (
  <Container>
    <Grid container justify="center">
      <Grid item xs={11}>
        <Introduction />
      </Grid>
    </Grid>
  </Container>
);

export default LargeFamily;
