import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Grid } from '@material-ui/core';
import Loader from 'components/Loader';
import { makeTilesStyles } from 'styles/pages/Materials';
import { texts } from './texts';

function RenderTile({ id, download, filename, path, src }) {
  const [loading, setLoading] = useState(true);
  const { card, cardContent, tile } = makeTilesStyles();

  const alt = texts(path).alt;

  return (
    <Grid key={id} item xs={12} sm={6} md={4}>
      <Box className={tile}>
        <a href={download} download={filename}>
          <Card className={card}>
            <CardContent className={cardContent}>
              <Loader className={`basic ${loading ? '' : 'loaded'}`}></Loader>
              <img src={src} onLoad={() => setLoading(false)} alt={alt} />
            </CardContent>
          </Card>
        </a>
      </Box>
    </Grid>
  );
}

const Tiles = ({ path }) => {
  const { gridContainer } = makeTilesStyles();

  return (
    <Grid className={gridContainer} container spacing={6}>
      {texts(path).tiles.map(props => (
        <RenderTile key={props.id} path={path} {...props} />
      ))}
    </Grid>
  );
};

Tiles.propTypes = {
  path: PropTypes.string.isRequired,
};

export default Tiles;
