import { makeStyles } from '@material-ui/styles';
import { darkSlateBlue } from 'styles/common/variables';

const makeIntroductionStyles = makeStyles({
  h1: {
    marginTop: '65px',
  },
  img: {
    margin: '100px 0',
    maxWidth: '500px',
  },
  paragraph: {
    color: darkSlateBlue,
    fontSize: '1.125rem',
    fontWeight: 300,
    lineHeight: '1.8',
    marginTop: '25px',
  },
  statuteLink: {
    fontSize: '1.125rem',
  },
});

export default makeIntroductionStyles;
