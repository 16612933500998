import { makeStyles } from '@material-ui/styles';
import {
  breakpointMd,
  breakpointLg,
  darkSlateBlue,
  topBarCardBlue,
  topBarCardGreen,
  topBarCardPurple,
  topBarCardViolet,
  white,
  whiteSmoke,
  buttonSeeMoreBackground,
} from 'styles/common/variables';

export const makePresentationTileStyles = makeStyles({
  button: {
    margin: '30px auto 0',
  },
  card: {
    color: darkSlateBlue,
    height: '540px',
    margin: 'auto',
    maxWidth: '365px',
    position: 'relative',
    borderRadius: '12px',
    boxShadow: '0px 0px 12px 6px rgb(0 0 0 / 10%)',
  },
  cardContent: {
    padding: '15px 25px',

    '&:last-child': {
      paddingBottom: '25px',
    },

    [`@media (max-width: ${breakpointMd}px)`]: {
      padding: '15px',
    },
  },
  cardHeader: {
    height: '50px',

    '& span': {
      alignItems: 'center',
      color: white,
      display: 'flex',
      justifyContent: 'center',
    },

    '&.history': {
      background: topBarCardViolet,
    },
    '&.informatics': {
      background: topBarCardPurple,
    },
    '&.math': {
      background: topBarCardBlue,
    },
    '&.polish': {
      background: topBarCardGreen,
    },
  },
  cardWrapper: {
    height: '100%',
  },
  descriptionParagraph: {
    fontSize: '0.9rem',
    fontWeight: 400,
    lineHeight: 1.6,
    marginTop: '15px',
  },
  gridContainer: {
    marginBottom: '30px',
    marginTop: '10px',
  },
  h3: {
    fontSize: '1.375rem',
    fontWeight: '600',
  },
  h4: {
    fontSize: '1rem',
    lineHeight: 1,
  },
  img: {
    width: '100%',
  },
  link: {
    margin: '20px auto',
    borderRadius: '12px',
    width: '100%',
    maxWidth: '365px',
  },
  linksWrapper: {
    display: 'flex',
    justifyContent: 'space-between',

    '& a': {
      margin: '20px',
      background: buttonSeeMoreBackground,
      fontSize: '1rem',
      boxShadow: '0px 0px 12px 6px rgb(0 0 0 / 10%)',

      [`@media (max-width: ${breakpointMd}px)`]: {
        margin: '20px 0 0 0',
        width: '100%',
      },

      '&:last-child': {
        [`@media (max-width: ${breakpointMd}px)`]: {
          margin: '20px 0 20px 0',
        },
      },
    },

    [`@media (max-width: ${breakpointMd}px)`]: {
      alignItems: 'center',
      flexDirection: 'column',
    },
  },
  priceBar: {
    alignItems: 'center',
    background: whiteSmoke,
    bottom: 0,
    display: 'flex',
    fontSize: '1.125rem',
    height: '50px',
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    width: '100%',

    [`@media (max-width: ${breakpointLg}px)`]: {
      fontSize: '.875rem',
    },
  },
  subtitleExtensionParagraph: {
    fontSize: '1rem',
    fontWeight: 600,
    lineHeight: 1.3,
    marginTop: '8px',
  },
  productTileLink: {
    textDecoration: 'none',
  },
});
