import { makeStyles } from '@material-ui/styles';
import {
  articleFontFamily,
  breakpointMd,
  darkSlateBlue,
  navLinkHover,
  transition,
} from 'styles/common/variables';

const makeArticleContentStyles = makeStyles({
  arrowBack: {
    width: '20px',
  },
  article: {
    color: darkSlateBlue,
    display: 'flex',
    flexDirection: 'column',
    margin: '50px 0',

    '& *': {
      fontFamily: articleFontFamily,
    },
  },
  paragraph: {
    lineHeight: 1.6,
    marginBottom: '15px',
  },
  prevLink: {
    display: 'inline-flex',
    color: darkSlateBlue,
    fontFamily: articleFontFamily,
    marginBottom: '50px',
    position: 'relative',
    textDecoration: 'none',
    transition: `color ${transition}`,

    '&::after': {
      content: "''",
      borderTop: `1px solid ${navLinkHover}`,
      bottom: 0,
      left: 0,
      height: '1px',
      position: 'absolute',
      transition: `all ${transition}`,
      width: 0,
    },

    '&:hover': {
      color: navLinkHover,

      '&::after': {
        width: '100%',
      },
    },
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
  },
  textWrapper: {
    width: '65%',

    [`@media (max-width: ${breakpointMd}px)`]: {
      width: '100%',
    },
  },
  title: {
    fontSize: '1.25rem',
    fontWeight: 600,
    margin: '20px 0 10px 0',
  },
});

export default makeArticleContentStyles;
