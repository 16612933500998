import { makeStyles } from '@material-ui/styles';
import { footerHeight, headerHeight } from 'styles/common/variables';

export const makeMainStyles = makeStyles({
  main: {
    display: 'block',
    minHeight: `calc(100vh - ${footerHeight})`,
    height: '100%',
    padding: `${headerHeight} 0 0`,
    position: 'relative',
  },
});
