// import historyForChildren from 'assets/img/historia-dla-dzieci.png';
import mathGamesForChildren from 'assets/img/gry-matematyczne-dla-dzieci.png';
import programmingForChildren from 'assets/img/programowanie-dla-dzieci.png';
import spellingGamesForChildren from 'assets/img/gry-ortograficzne-dla-dzieci.png';

export const texts = {
  introduction: {
    h1: 'Ponad 2300 animowanych zadań z&nbsp;różnych przedmiotów',
    h2: `Nasze gry edukacyjne na komputer zawierają interaktywne 
    zadania z&nbsp;matematyki, języka polskiego oraz informatyki 
    (w tym również z&nbsp;podstaw programowania). Zostały one zaprojektowane tak, 
    aby pomóc dzieciom przećwiczyć zagadnienia, których znajomości 
    wymaga się w&nbsp;szkole podstawowej. `,
  },
  tiles: [
    {
      id: 1,
      className: 'math',
      header: 'Matematyka',
      img: {
        alt:
          'Gry i zadania matematyczne z animowanymi bohaterami w grach edukacyjnych dla dzieci eduGry',
        src: mathGamesForChildren,
      },
      url: 'matematyka',
    },
    {
      id: 2,
      className: 'polish',
      header: 'Język polski',
      img: {
        alt:
          'Gry ortograficzne i dyktanda online z animowanymi postaciami z gry edukacyjnej dla dzieci Władcy Słów',
        src: spellingGamesForChildren,
      },
      url: 'jezyk-polski',
    },
    // {
    //   id: 3,
    //   className: 'history',
    //   header: 'Historia',
    //   img: {
    //     alt:
    //       'Ćwiczenia z historii z animowanymi postaciami z gry edukacyjnej online dla dzieci Histlandia',
    //     src: historyForChildren,
    //   },
    //   url: 'historia',
    // },
    {
      id: 4,
      className: 'informatics',
      header: 'Informatyka',
      img: {
        alt:
          'Nauka programowania dla dzieci z grą edukacyjną Informatyka i jej animowanymi bohaterami',
        src: programmingForChildren,
      },
      url: 'informatyka',
    },
  ],
};
