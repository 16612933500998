import { makeStyles } from '@material-ui/styles';

const makeTilesStyles = makeStyles({
  cardContent: {
    display: 'flex',
    padding: 0,
    position: 'relative',

    '&:last-child': {
      paddingBottom: 0,
    },

    '& img': {
      width: '100%',
      height: '100%',
    },
  },
  gridContainer: {
    marginBottom: '100px',
  },
  tile: {
    margin: '0 auto 20px auto',
    maxWidth: '350px',
  },
});

export default makeTilesStyles;
