import { urls } from 'common/urls';

import class1 from 'assets/img/klasa_1.svg';
import class2 from 'assets/img/klasa_2.svg';
import class3 from 'assets/img/klasa_3.svg';
import class4 from 'assets/img/klasa_4.svg';
import class5 from 'assets/img/klasa_5.svg';
import class6 from 'assets/img/klasa_6.svg';
import class7 from 'assets/img/klasa_7.svg';
import class8 from 'assets/img/klasa_8.svg';

import math from 'assets/img/matematyka.svg';
import polish from 'assets/img/jezyk_polski.svg';
import informatics from 'assets/img/informatyka.svg';

const { articles, download, subjects } = urls;

const menu = [
  {
    id: 1,
    subMenu: [
      {
        id: 1,
        icon: class1,
        title: '1 klasa',
        url: '/klasa-1',
      },
      {
        id: 2,
        icon: class2,
        title: '2 klasa',
        url: '/klasa-2',
      },
      {
        id: 3,
        icon: class3,
        title: '3 klasa',
        url: '/klasa-3',
      },
      {
        id: 4,
        icon: class4,
        title: '4 klasa',
        url: '/klasa-4',
      },
      {
        id: 5,
        icon: class5,
        title: '5 klasa',
        url: '/klasa-5',
      },
      {
        id: 6,
        icon: class6,
        title: '6 klasa',
        url: '/klasa-6',
      },
      {
        id: 7,
        icon: class7,
        title: '7 klasa',
        url: '/klasa-7',
      },
      {
        id: 8,
        icon: class8,
        title: '8 klasa',
        url: '/klasa-8',
      },
    ],
    subMenuCustomTileStyle: null,
    title: 'klasy',
    url: '',
  },
  {
    id: 2,
    subMenu: [
      {
        id: 1,
        icon: math,
        title: 'matematyka',
        url: `/${subjects.root}/${subjects.math}`,
      },
      {
        id: 2,
        icon: polish,
        title: 'język polski',
        url: `/${subjects.root}/${subjects.polish}`,
      },
      {
        id: 3,
        icon: informatics,
        title: 'informatyka',
        url: `/${subjects.root}/${subjects.informatics}`,
      },
    ],
    subMenuCustomTileStyle: { width: '210px' },
    title: 'przedmioty',
    url: '',
  },
  {
    id: 3,
    subMenu: null,
    subMenuCustomTileStyle: null,
    title: 'do pobrania',
    url: `/${download.root}`,
  },
  {
    id: 4,
    subMenu: null,
    subMenuCustomTileStyle: null,
    title: 'do poczytania',
    url: `/${articles.root}`,
  },
];

export default menu;
