import React from 'react';
import PropTypes from 'prop-types';
import { makeButtonStyles } from 'styles/Button';

const Button = ({ className, text }) => {
  const { button } = makeButtonStyles();

  return (
    <button className={`${button} ${className}`} type="button">
      <span>{text}</span>
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default Button;
