import React from 'react';
import { NavLink } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Grid } from '@material-ui/core';
import { makeButtonStyles } from 'styles/Button';
import { makeTilesStyles } from 'styles/pages/Download';
import { texts } from './texts';

const renderTile = ({ id, img: { alt, src }, link: { text, to } }) => {
  const { button } = makeButtonStyles();
  const { card, cardContent, gridContainer, tile } = makeTilesStyles();

  return (
    <Grid key={id} className={gridContainer} item xs={12} sm={6} md={4}>
      <Box className={tile}>
        <NavLink to={to}>
          <Card className={card}>
            <CardContent className={cardContent}>
              <img src={src} alt={alt} />
            </CardContent>
          </Card>
        </NavLink>
        <NavLink className={`${button} full-width`} to={to}>
          <span>{text}</span>
        </NavLink>
      </Box>
    </Grid>
  );
};

const Tiles = () => {
  const { gridContainer } = makeTilesStyles();

  return (
    <Grid className={gridContainer} container spacing={6}>
      {texts.tiles.map(renderTile)}
    </Grid>
  );
};

export default Tiles;
