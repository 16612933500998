import InformatykaImage from 'assets/img/programs/programowanie-dla-dzieci.png';
import {urls} from "../../urls";

export const Informatyka = (classLevel = '') => ({
  programPresentation: {
    classLevels: {
      1: {
        description: `Pracując z&nbsp;<i>Informatyką</i>, dzieci poznają podstawy programowania, 
          a&nbsp;także uczą się sprawnego posługiwania się myszką i&nbsp;szybkiego pisania na klawiaturze.`,
      },
      2: {
        description: `Pracując z&nbsp;<i>Informatyką</i>, dzieci poznają podstawy programowania (m.in. w języku Scratch), 
          a&nbsp;także uczą się sprawnego posługiwania się myszką, szybkiego pisania na klawiaturze 
          oraz pracy z&nbsp;edytorami tekstu i&nbsp;grafiki.`,
      },
      3: {
        description: `Pracując z&nbsp;<i>Informatyką</i>, dzieci kontynuują naukę bezwzrokowego pisania na klawiaturze oraz ćwiczą 
          pracę z&nbsp;edytorami tekstu i&nbsp;grafiki. Poznają także podstawy programowania oraz zasady 
          bezpiecznego korzystania z&nbsp;komputera i&nbsp;internetu.`,
      },
    },
    common: {
      classNameHeader: 'informatics',
      seeMoreLink: `klasa-${classLevel}/${urls.apps.informatyka}-${classLevel}`,
      seeMoreLinkOnSubjectPage: `/${urls.subjects.root}/${urls.subjects.informatics}/${urls.apps.informatyka}`,
      img: {
        alt: `Nauka programowania dla dzieci z grą edukacyjną Informatyka ${classLevel} i jej animowanymi bohaterami`,
        src: InformatykaImage,
      },
      price: '21 zł na rok | bezpłatna przesyłka',
      subject: 'Informatyka',
      subtitle: 'gra edukacyjna online',
      title: `<i>Informatyka ${classLevel}</i>`,
    },
  },
  programDescription: {
    classLevels: {
      1: [
        {
          id: 1,
          text: `
              O tym, że programista to zawód przyszłości, nie trzeba nikogo przekonywać. Kodowanie to umiejętność, 
              której warto się uczyć już od najmłodszych lat. Pomocna tu może być <b>gra edukacyjna online na komputer 
              <i>Informatyka 1</i></b>, czyli <b>nauka programowania dla dzieci poprzez zabawę</b>. 
              Zadania w&nbsp;<i>Informatyce 1</i> są przeznaczone głównie dla <b>6-latka</b> i&nbsp;<b>7-latka</b>. 
              <b>Programowanie wizualne</b> zostało wprowadzone w&nbsp;formie prostych gier <i>UFO</i> oraz <i>Pan Ołówek</i>. 
              W&nbsp;<i>Informatyce 1</i> znajdują się także zadania doskonalące <b>posługiwanie się myszką komputerową</b> 
              oraz <b>szybkie bezwzrokowe pisanie na klawiaturze wszystkimi palcami</b>. 
            `,
        },
      ],
      2: [
        {
          id: 1,
          text: `
            O tym, że programista to zawód przyszłości, nie trzeba nikogo przekonywać. Kodowanie to umiejętność, 
            której warto się uczyć już od najmłodszych lat. Pomocna tu może być <b>gra edukacyjna online na komputer 
            <i>Informatyka 2</i></b>, czyli <b>nauka programowania dla dzieci poprzez zabawę</b>. Zadania w&nbsp;<i>Informatyce 2</i> 
            są przeznaczone głównie dla <b>7-latka</b> i&nbsp;<b>8-latka</b>. <b>Programowanie wizualne</b> zostało 
            wprowadzone w&nbsp;formie prostych gier <i>UFO</i> oraz <i>Pan Ołówek</i>. W&nbsp;<i>Informatyce 2</i> dostępny 
            jest również <b>kurs programowania dla dzieci w&nbsp;języku Scratch</b> (filmy instruktażowe krok po kroku). 
            W&nbsp;naszej eduGrze znajdują się także zadania doskonalące <b>posługiwanie się myszką komputerową</b> oraz 
            <b>szybkie bezwzrokowe pisanie na klawiaturze wszystkimi palcami</b>. Oprócz tego dzieci dowiadują się, 
            do czego mogą się przydać na co dzień <b>edytor grafiki</b> (np. <i>MS Paint</i>) i&nbsp;<b>edytor tekstu</b> (np. <i>MS Word</i>). 
          `,
        },
      ],
      3: [
        {
          id: 1,
          text: `
            O tym, że programista to zawód przyszłości, nie trzeba nikogo przekonywać. Kodowanie to umiejętność, 
            której warto się uczyć już od najmłodszych lat. Pomocna tu może być <b>gra edukacyjna online na komputer 
            <i>Informatyka 3</i></b>, czyli <b>nauka programowania dla dzieci</b> poprzez zabawę. Zadania w&nbsp;<i>Informatyce 3</i> 
            są przeznaczone głównie dla <b>8-latka</b> i <b>9-latka</b>. <b>Programowanie wizualne</b> zostało wprowadzone 
            w&nbsp;formie prostych gier <i>UFO</i> oraz <i>Pan Ołówek</i>. W&nbsp;naszej eduGrze znajdują się także zadania doskonalące 
            <b>posługiwanie się myszką komputerową</b> oraz <b>szybkie bezwzrokowe pisanie na klawiaturze wszystkimi palcami</b>. 
            Oprócz tego dzieci dowiadują się, do czego mogą się przydać na co dzień <b>edytor grafiki</b> (np. <i>MS Paint</i>) 
            i <b>edytor tekstu</b> (np. <i>MS Word</i>) oraz <b>jak bezpiecznie korzystać z&nbsp;komputera i&nbsp;internetu</b>.
          `,
        },
      ],
    },
    title: `Informatyka dla dzieci – ${classLevel}&nbsp;klasa`,
  },
});
