import { makeStyles } from '@material-ui/styles';
import {
  breakpointSm,
  buttonBuyBackground,
  buttonDemoBackground,
  darkSlateBlue,
  indigo,
  transition,
  white,
} from 'styles/common/variables';
import { darken } from '@material-ui/core/styles';

const beforeAfter = {
  content: "''",
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 1,
  transition: `all ${transition}`,
};

export const makeButtonStyles = makeStyles({
  button: {
    background: 'none',
    border: 0,
    color: darkSlateBlue,
    display: 'flex',
    fontSize: '1.25rem',
    justifyContent: 'center',
    margin: '20px auto',
    padding: '15px',
    position: 'relative',
    textDecoration: 'none',

    '&.full-width': {
      width: '100%',
    },

    '& span': {
      zIndex: 2,
    },

    '&::after': {
      ...beforeAfter,
      backgroundColor: 'rgba(55, 62, 128, 0.75)',
      borderRadius: '3px',
      opacity: 0,
      transform: 'scale(0.7, 0.7)',
    },

    '&::before': {
      ...beforeAfter,
      border: `1px solid ${darkSlateBlue}`,
    },

    '&:focus': {
      outline: 'none',
    },

    '&:hover': {
      color: white,
      cursor: 'pointer',

      '&::after': {
        opacity: 1,
        transform: 'scale(0.9)',
      },

      '&::before': {
        opacity: 0,
        transform: 'scale(0.9, 0.9)',
      },
    },
  },
  buttonPrimary: {
    background: buttonBuyBackground,
    border: 0,
    color: white,
    display: 'flex',
    fontSize: '1.25rem',
    justifyContent: 'center',
    padding: '10px 15px',
    position: 'relative',
    textDecoration: 'none',
    transition: `background ${transition}`,

    '&.contact': {
      fontSize: '1.5rem',
      marginTop: '30px',
      maxWidth: '270px',
      padding: '12px 15px',

      [`@media (max-width: ${breakpointSm}px)`]: {
        maxWidth: '100%',
      },
    },

    '&.demo': {
      background: buttonDemoBackground,

      '&:hover': {
        background: darken(buttonDemoBackground, 0.1),
      },
    },

    '&.full-width': {
      width: '100%',
    },

    '&.indigoBackground': {
      background: indigo,

      '&:hover': {
        background: darken(indigo, 0.3),
      },
    },

    '&:hover': {
      background: darken(buttonBuyBackground, 0.3),
      color: white,
      cursor: 'pointer',
    },
  },
});
