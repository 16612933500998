import { makeStyles } from "@material-ui/styles";
import { darkSlateBlue } from "../../common/variables";

const makeShortDescriptionStyles = makeStyles({
  description: {
    marginTop: '30px',
    color: darkSlateBlue,
    fontSize: '1.3rem',
    lineHeight: '1.8',
  },

  advantagesDescriptionTitle: {
    fontWeight: 400,
  },

  imageSection: {
    display: 'flex',
    alignItems: 'bottom',

    '& img': {
      objectFit: 'contain',
      objectPosition: 'bottom',
      minWidth: '480px',
    }
  }
});

export default makeShortDescriptionStyles;
