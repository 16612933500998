import React from 'react';
import { Statute } from 'pages/LargeFamilyStatute';
import Container from '@material-ui/core/Container';
import { Grid } from '@material-ui/core';

const LargeFamilyStatute = () => (
  <Container>
    <Grid container justify="center">
      <Grid item xs={11} md={10}>
        <Statute />
      </Grid>
    </Grid>
  </Container>
);

export default LargeFamilyStatute;
