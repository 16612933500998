import { makeStyles } from '@material-ui/styles';
import {
  breakpointMd,
  darkSlateBlue,
  ghostWhite,
  whiteSmoke,
  navLinkHover,
  transition,
} from 'styles/common/variables';

export const makeNavLinkStyles = makeStyles({
  navLink: {
    display: 'flex',
    color: darkSlateBlue,
    fontWeight: 600,
    fontSize: '0.875rem',
    padding: '5px 0',
    position: 'relative',
    textTransform: 'uppercase',
    transition: `all ${transition}`,

    '&::after': {
      content: "''",
      borderTop: `1px solid ${navLinkHover}`,
      bottom: 0,
      left: 0,
      height: '1px',
      position: 'absolute',
      transition: `all ${transition}`,
      width: 0,
    },

    '&:hover': {
      textDecoration: 'none',
      color: navLinkHover,

      '&::after': {
        width: '100%',
      },
    },

    '&.mobile__nav-link': {
      padding: '12px 20px',

      '&::after': {
        content: 'none',
      },

      '&::before': {
        content: 'none',
      },

      '&:hover': {
        backgroundColor: ghostWhite,
      },
    },

    '&.mobile__sub-nav-link': {
      fontWeight: 400,
      padding: '5px 35px',

      '&::after': {
        content: 'none',
      },

      '&::before': {
        content: 'none',
      },

      '&:hover': {
        backgroundColor: ghostWhite,
      },
    },
  },

  arrowSubmenu: {
    height: '20px',
  },

  logo: {
    display: 'flex',

    [`@media (max-width: ${breakpointMd}px)`]: {
      transition: `all ${transition}`,
      position: 'absolute',
      left: '90px',
    },

    '&.transform': {
      left: '20px',
    },

    '& img': {
      width: '193px',
      height: '55px',

      [`@media (max-width: ${breakpointMd}px)`]: {
        width: '128px',
        height: '37px',
        zIndex: 1310,
      },
    },
  },
  submenuContainer: {
     backgroundColor: whiteSmoke,
     position: 'absolute',
     left: 0,
     top: 80,
     width: '100%',
     height: 160,
  },
  submenuLink:{
    '&:hover': {
      textDecoration: 'none',
    }
  },
  submenuTile: {
    position: 'relative',
    bottom:0,
    height: 45,
    width: 160,
    backgroundColor: 'white',
    borderRadius: 7,
    marginBottom: 10,
    fontWeight: 600,
    fontSize: '0.875rem',
    textDecoration: 'none',
    display: 'flex',
    boxShadow: '0px 2px 2px 0 #ccc',

    '&:hover' : {
        bottom: '3px',
        transition: 'all .2s',
        boxShadow: '0px 5px 5px 0 #ccc',
    },

    '& img': {
      padding: '5px',
      marginLeft: '10px',
      minWidth: '48px',
    },

    '& span': {
      color: '#373e80',
      textTransform: 'uppercase',
      alignSelf: 'center',
      width: '100%',
      textAlign: 'center',
      marginRight: '12px',
    }
  },
  submenuWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: 700,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
});
