import React, { useState, } from 'react';
import { NavLink } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Cookies from 'components/Cookies';
import { makeFooterStyles } from 'styles/Footer';
import { texts } from './texts';
import CookiesBar from './CookiesBar';

const renderLink = ({ id, img: { alt, src } = {}, text, url }) => {
  const { link, linkExternal, listItem } = makeFooterStyles();

  return (
    <li key={id} className={listItem}>
      {src ? (
        <a className={linkExternal} href={url} target="_blank" rel="noopener noreferrer">
          <img src={src} alt={alt} />
        </a>
      ) : url ? (
        <NavLink className={link} to={url}>
          {text}
        </NavLink>
      ) : (
        <div>{text}</div>
      )}
    </li>
  );
};

const renderColumn = ({ id, columns, links, title }) => {
  const {
    expansionPanel,
    expansionPanelDetails,
    expansionPanelSummary,
    linksColumnTitle,
    list,
    plusIcon,
  } = makeFooterStyles();

  return (
    <Grid key={id} item xs={12} md={columns}>
      <Box display={{ xs: 'none', md: 'block' }}>
        <Typography component="h4" className={linksColumnTitle}>
          {title}
        </Typography>
        <ul className={list}>{links.map(renderLink)}</ul>
      </Box>
      <Box display={{ xs: 'block', md: 'none' }}>
        <ExpansionPanel className={expansionPanel} TransitionProps={{ unmountOnExit: true }}>
          <ExpansionPanelSummary
            id={`panel-${id}`}
            className={expansionPanelSummary}
            aria-controls={`panel-${id}`}
          >
            <Typography component="h4" className={linksColumnTitle}>
              {title}
            </Typography>
            <div className={plusIcon}>
              <div className="line-horizontal"></div>
              <div className="line-vertical"></div>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={expansionPanelDetails}>
            <ul className={list}>{links.map(renderLink)}</ul>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Box>
    </Grid>
  );
};

const Footer = () => {
  const [open, setOpen] = useState(false);
  const { companyInformation, cookiesLink, footer, footerBottom, footerTop } = makeFooterStyles();

  const handleClose = () => setOpen(false);

  return (
    <footer className={footer}>
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <Grid container className={footerTop}>
              {texts.footerTop.map(renderColumn)}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box className={footerBottom}>
              <div className={companyInformation}>
                <div>{texts.footerBottom.company.name}</div>
                <div>{texts.footerBottom.company.address}</div>
              </div>
              <div>
                <div dangerouslySetInnerHTML={{ __html: texts.footerBottom.copy }} />
                <div>
                  Serwis wykorzystuje pliki cookies (ciasteczka).{' '}
                  <span className={cookiesLink} onClick={() => setOpen(true)}>
                    Więcej informacji
                  </span>
                </div>
              </div>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Cookies handleClose={handleClose} open={open} />
      <CookiesBar/>
    </footer>
  );
};

export default Footer;
