import React from 'react';
import PropTypes from 'prop-types';

const Hamburger = ({ hamburgerButton, mobileMenuOpen, toggleDrawer }) => {
  const className = `${hamburgerButton} ${mobileMenuOpen ? 'transform' : ''}`;

  return (
    <div className={className} onClick={toggleDrawer(!mobileMenuOpen)}>
      <div className="line lineTop" />
      <div className="line lineMiddle" />
      <div className="line lineBottom" />
    </div>
  );
};

Hamburger.propTypes = {
  hamburgerButton: PropTypes.string.isRequired,
  mobileMenuOpen: PropTypes.bool.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
};

export default Hamburger;
