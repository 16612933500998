import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeProgramDescriptionSectionStyles } from 'styles/ProgramDescriptionSection';
import { texts } from "./texts";

const ProgramDescriptionSection = ({
  application,
}) => {
  const {
    container,
    description,
    h3,
    maxWidth,
  } = makeProgramDescriptionSectionStyles();

  return (
    <section className={container}>
      <Box className={maxWidth}>
        <Typography
          component="h3"
          className={h3}
          variant="h1"
          dangerouslySetInnerHTML={{ __html: texts.apps[application].descriptionTitle }}
        ></Typography>
        {texts.apps[application].descriptionParagraphs.map(({ id, text }) => (
          <Typography
            key={id}
            component="p"
            className={description}
            dangerouslySetInnerHTML={{ __html: text }}
          ></Typography>
        ))}
      </Box>
    </section>
  );
};

export default ProgramDescriptionSection;
