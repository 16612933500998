import React from 'react';
import { makeLoaderStyles } from 'styles/Loader';

const Loader = ({ className }) => {
  const { loader, dot } = makeLoaderStyles();

  return (
    <div className={`${loader} ${className}`}>
      <div className={`${dot} dot-left`}></div>
      <div className={`${dot} dot-middle`}></div>
      <div className={`${dot} dot-right`}></div>
    </div>
  );
};

export default Loader;
