import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeIntroductionStyles } from 'styles/pages/Classes';
import { texts } from './texts';

const Introduction = ({ application }) => {
  const { h1, h2, h3 } = makeIntroductionStyles();

  return (
    <Fragment>
      <Grid item>
        <h1 style={{ margin: 0 }}>
          <Typography
            className={h1}
            dangerouslySetInnerHTML={{ __html: texts.apps[application].h1 }}
            component="span"
            variant="h1"
            align="center"
          />
          <Typography
            className={h2}
            dangerouslySetInnerHTML={{ __html: texts.apps[application].h2 }}
            component="span"
            variant="h2"
            align="center"
          />
        </h1>
        <Typography
          className={h3}
          dangerouslySetInnerHTML={{ __html: texts.apps[application].h3 }}
          component="span"
          variant="h2"
          align="center"
        />
      </Grid>
    </Fragment>
  );
};

Introduction.propTypes = {
  application: PropTypes.string.isRequired,
};

export default Introduction;
