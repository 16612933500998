export const Informatics = [
  {
    id: 1,
    paragraphs: [
      {
        id: 1,
        text: `O tym, że programista to zawód przyszłości, nie trzeba nikogo przekonywać. 
          <b>Kodowanie</b> to umiejętność, której warto się uczyć już od najmłodszych lat. 
          Pomocna tu może być <b>gra edukacyjna online na komputer <i>Informatyka</i></b>, czyli 
          <b>nauka programowania dla dzieci</b> poprzez zabawę. 
        `,
      },
      {
        id: 2,
        text: `Zadania w&nbsp;<i>Informatyce</i> mają zróżnicowany poziom trudności, 
          dlatego są idealne dla dzieci w&nbsp;różnym wieku: <b>6 lat, 7 lat, 8 lat</b> oraz 
          <b>9 lat</b>. <b>Czytane na głos polecenia</b> ułatwiają zrozumienie treści ćwiczeń. 
          <b>Programowanie wizualne</b> zostało wprowadzone w&nbsp;formie 
          prostych gier UFO oraz Pan Ołówek. W&nbsp;naszej eduGrze dostępny jest również 
          <b>kurs programowania dla dzieci w&nbsp;języku Scratch</b> (filmy instruktażowe krok po kroku). 
          W&nbsp;<i>Informatyce</i> znajdują się także zadania doskonalące <b>posługiwanie się 
          myszką komputerową</b> oraz <b>szybkie bezwzrokowe pisanie na klawiaturze 
          wszystkimi palcami</b>. Oprócz tego dzieci dowiadują się, do czego mogą 
          się przydać na co dzień <b>edytor grafiki</b> (np. <i>MS Paint</i>) i&nbsp;<b>edytor tekstu</b> 
          (np. <i>MS Word</i>) oraz <b>jak bezpiecznie korzystać z komputera i&nbsp;internetu</b>. 
        `,
      },
    ],
    title: `Informatyka dla dzieci`,
  },
];
