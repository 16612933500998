import { makeStyles } from '@material-ui/styles';
import {
  breakpointSm,
  breakpointMd,
  breakpointLg,
  containerWidthLg,
  white,
  darkSlateBlue,
} from 'styles/common/variables';

export const makeMainImageStyles = makeStyles({
  img: {
    objectFit: 'cover',
    width: '100%',

    [`@media (max-width: ${breakpointMd}px)`]: {
      objectPosition: 'right',
    },
  },
  mainImage: {
    display: 'inline-flex',
    height: '448px',
    position: 'relative',
    width: '100%',
  },
  panel: {
    position: 'absolute',
    width: '47%',
    height: '100%',
    right: 0,
    top: 0,
    background: 'rgba(0, 0, 0, 0)',

    [`@media (max-width: ${breakpointMd}px)`]: {
      width: '100%',
      minHeight: '40%',
      padding: '10px 0',
      bottom: 0,
      top: 'auto',
      background: 'rgba(0, 0, 0, 0)',
    },
  },
  title: {
    alignItems: 'center',
    color: white,
    display: 'flex',
    fontSize: '2.5rem',
    fontWeight: 600,
    height: '100%',
    lineHeight: '1.3',
    margin: 0,
    padding: '15px 40px 0 55px',

    '&.font-bold': {
      fontSize: '4rem',
      fontWeight: 800,
      padding: '35px 25px 0 100px',

      [`@media (max-width: ${breakpointLg}px)`]: {
        fontSize: '3rem',
      },

      [`@media (max-width: ${breakpointMd}px)`]: {
        fontSize: '2rem',
        padding: '45px 35px',
        alignItems: 'center',
      },
    },

    [`@media (max-width: ${breakpointMd}px)`]: {
      fontSize: '2rem',
      padding: '0 45px',
      alignItems: 'center',
    },

    [`@media (max-width: ${breakpointSm}px)`]: {
      fontSize: '2rem',
      padding: '0 16px',
      alignItems: 'center',
    },
  },
  mainImageTitle: {
    '& span': {
        color: darkSlateBlue,

        paddingBottom: '45px',

        [`@media (max-width: ${breakpointMd}px)`]: {
          paddingBottom: 0,
        },
    },
    '& h1': {
        [`@media (max-width: ${breakpointMd}px)`]: {
            width: '100%',
        },
        fontSize:'1.75rem',
        lineHeight:'1.4',
        fontWeight: 800,
    },
    '& h2': {
        fontSize:'1.125rem',
        lineHeight:'1.8',
        fontWeight: 300,
    },
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    maxWidth: containerWidthLg,
    margin: 'auto',
    overflow: 'hidden',
  },
});
