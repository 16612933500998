import React from 'react';
import { NavLink } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { Grid } from '@material-ui/core';
import { makeChooseClassStyles } from 'styles/pages/Home';

const renderClassTile = key => {
  const { classTile, classTileWrapper } = makeChooseClassStyles();

  return (
    <Box key={key} className={`${classTileWrapper} class-${key + 1}`}>
      <NavLink key={key} className={`${classTile} `} to={`klasa-${key + 1}`}>
        <div className="number">{key + 1}</div>klasa
      </NavLink>
    </Box>
  );
};

const ChooseClass = () => {
  const { background, gridContainer, h3, wrapper } = makeChooseClassStyles();
  const classesQuantity = 8;
  const classesNumbers = [...Array(classesQuantity / 2).keys()];

  return (
    <div className={background}>
      <Container>
        <Grid container justify="center">
          <Grid item xs={11}>
            <h3 className={h3}>Wybierz klasę</h3>
            <Grid container className={gridContainer} justify="center" spacing={4}>
              <Grid className="item" item xs={7} sm={9} md={6} lg={6}>
                <Box
                  className={wrapper}
                  display={{ xs: 'flex' }}
                  justifyContent="space-between"
                  flexWrap="wrap"
                >
                  {classesNumbers.map(renderClassTile)}
                </Box>
              </Grid>
              <Grid className="item" item xs={7} sm={9} md={6} lg={6}>
                <Box
                  className={wrapper}
                  display={{ xs: 'flex' }}
                  justifyContent="space-between"
                  flexWrap="wrap"
                >
                  {classesNumbers.map(key => key + classesQuantity / 2).map(renderClassTile)}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default ChooseClass;
