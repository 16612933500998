import HistlandiaImage from 'assets/img/programs/historia-dla-dzieci.png';

export const Histlandia = classLevel => ({
  programDescription: {
    paragraphs: [
      {
        id: 1,
        text: `
          EduGra <b><i>Histlandia</i></b> to animowane <b>ćwiczenia z&nbsp;historii dla dzieci</b> w&nbsp;wieku 
          <b>9 lat</b>, <b>10 lat</b>, <b>11 lat</b>, <b>12 lat</b>, <b>13 lat</b> oraz <b>14 lat</b>. 
          <b><i>Histlandia</i></b> pomaga przećwiczyć zagadnienia, których znajomości wymaga się w&nbsp;<b>szkole podstawowej</b>. 
          Oto niektóre z&nbsp;nich: <b>źródła historyczne</b>, <b>czas w&nbsp;historii</b>, <b>mapy historyczne</b>. 
          Program zawiera też interaktywne zadania z&nbsp;takich działów, jak <b>starożytna Grecja</b> 
          (obejmujące następujące tematy: <b>starożytne Ateny</b>, <b>religia starożytnej Grecji</b>, 
          <b>teatr grecki</b>, <b>igrzyska</b>) oraz <b>starożytny Rzym</b> (a w&nbsp;nim: <b>religia starożytnego Rzymu</b>, 
          <b>igrzyska</b> czy <b>osiągnięcia Rzymian</b>).
        `,
      },
      {
        id: 2,
        text: `
          <i>Histlandia</i> pozwala dzieciom utrwalić wiedzę również z&nbsp;zakresu historii Polski. 
          Dzięki interaktywnym zadaniom dziecko może w&nbsp;atrakcyjny sposób sprawdzić, 
          w&nbsp;jakim stopniu opanowało następujące zagadnienia: <b>Polska Piastów</b>, 
          <b>legendy o&nbsp;początkach Polski</b> czy <b>chrzest Polski</b> (<b>chrześcijaństwo</b>). 
          Animowane ćwiczenia wraz z&nbsp;zabawnymi postaciami ułatwiają zapamiętywanie 
          ważnych dat takich, jak <b>zjazd gnieźnieński</b> czy informacji na temat 
          <b>rozbicia dzielnicowego</b>. Dzieci w&nbsp;przyjemny sposób powtórzą również materiał 
          dotyczący polskich władców (<b>Mieszko I</b>, <b>Bolesław Chrobry</b>, 
          <b>Kazimierz Wielki</b> oraz <b>pierwsi Jagiellonowie</b>). <i>Histlandia</i> zawiera także ćwiczenia 
          z&nbsp;tematów, takich jak: <b>Krzyżacy</b>, <b>unia polsko-litewska</b>, 
          <b>wielka wojna z&nbsp;zakonem krzyżackim</b> czy <b>wojna trzynastoletnia</b>. 
        `,
      },
      {
        id: 3,
        text: `
          W&nbsp;naszym programie do nauki historii dla dzieci nie zabrakło 
          również zadań odnoszących się do <b>renesansu</b>, <b>złotego wieku</b>, 
          <b>reformacji</b> oraz <b>wielkich odkryć geograficznych</b>. 
          Dodatkowo z&nbsp;grą <i>Histlandia</i> dziecko przyswoi najważniejsze informacje 
          dotyczące zagadnień: <b>unia lubelska</b>, <b>pierwsza wolna elekcja</b>, 
          <b>potop szwedzki</b> czy <b>odsiecz wiedeńska</b>. Dzięki ciekawym pytaniom 
          łatwo może też utrwalić wiedzę na temat <b>oświecenia</b>, 
          <b>Konstytucji 3 maja</b>, <b>powstania kościuszkowskiego</b> oraz <b>rozbiorów</b>. 
          Program online <i>Histlandia</i> zawiera także zagadnienia dotyczące 
          <b>Polski pod zaborami</b>, <b>Legionów Polskich we Włoszech</b>, 
          <b>Księstwa Warszawskiego</b> i&nbsp;<b>Królestwa Polskiego</b>. 
          Interaktywne zadania pozwalają przećwiczyć znajomość faktów i&nbsp;dat związanych 
          z&nbsp;<b>powstaniem listopadowym i&nbsp;powstaniem styczniowym</b>. 
          Program w&nbsp;atrakcyjny sposób pozwala na powtórkę materiału 
          z&nbsp;zakresu <b>Wielkiej Emigracji</b>, <b>rusyfikacji</b> oraz <b>germanizacji</b>. 
          Animowane ćwiczenia obejmują również zagadnienia z&nbsp;historii XX wieku 
          (<b>I  i&nbsp;II wojna światowa</b>, <b>II Rzeczpospolita</b>, <b>powstanie warszawskie</b>, 
          <b>PRL</b>, <b>III Rzeczpospolita</b>, <b>wiedza o&nbsp;społeczeństwie</b> czy <b>Unia Europejska</b>).
        `,
      },
    ],
    title: `Historia dla dzieci – ${classLevel}&nbsp;klasa`,
  },
  programPresentation: {
    buyLink:
      'https://gwo.pl/ksiegarnia/search?categories%5b7%5d=1&categories%5b552%5d=1&s=&limit=20',
    classNameHeader: 'history',
    demoLink: 'http://histlandia.gwo.pl/apps/runFreetourA/4039',
    description: `Program zawiera zadania ze wszystkich okresów historycznych – od starożytności 
      po czasy współczesne. Pracując z&nbsp;<i>Histlandią</i>, dzieci zapamiętują ważne daty i&nbsp;pojęcia, 
      a&nbsp;także sprawdzają swoją znajomość zabytków i&nbsp;map.`,
    img: {
      alt:
        'Ćwiczenia z&nbsp;historii z&nbsp;animowanymi postaciami z&nbsp;gry edukacyjnej online dla dzieci Histlandia',
      src: HistlandiaImage,
    },
    price: '6 zł na miesiąc | bezpłatna przesyłka',
    subject: 'Historia',
    subtitle: 'gra edukacyjna online',
    title: '<i>Histlandia</i>',
  },
});
