import React, { useEffect, useState, } from "react";
import { makeCookiesBarStyles } from 'styles/CookiesBar';
import cookies from "js-cookie";

const CookiesBar = () => {
  const { cookiesBar, container, closeButton, findOutMoreButton } = makeCookiesBarStyles();
  const [cookiesBarOpen, setCookiesBarOpen] = useState(true);

  useEffect(()=> {
    const hideCookiesBar = cookies.get("hide_cookies_bar");
    if (hideCookiesBar === "true") {
      setCookiesBarOpen(false);
    }
  }, [])

  const closeCookiesBar = () => {
    cookies.set("hide_cookies_bar", "true");
    setCookiesBarOpen(false);
  };

  return (
    <>
      {cookiesBarOpen && (
        <div className={cookiesBar}>
          <div className={container}>
                    <span>
                      Ta strona wykorzystuje pliki cookies.&nbsp;&nbsp;
                      <a href="https://gwo.pl/polityka-plikow-cookies/" rel="noopener noreferrer" target="_blank" className={findOutMoreButton}>Dowiedz się więcej</a>
                    </span>
            <div className={closeButton} onClick={closeCookiesBar} />
          </div>
        </div>
      )}
    </>
  )

}
export default CookiesBar;
