import { makeStyles } from '@material-ui/styles';
import { cookiesBarBackgroundColor, cookiesBarBoxShadow, cookiesBarColor } from 'styles/common/variables';
import closeIconButton from '../../assets/img/close-icon.svg'

export const makeCookiesBarStyles = makeStyles({
  cookiesBar: {
    backgroundColor: cookiesBarBackgroundColor,
    display: 'block',
    bottom: '0',
    boxShadow: cookiesBarBoxShadow,
    color: cookiesBarColor,
    fontSize: '16px',
    position: 'fixed',
    width: '100%',
    letterSpacing: '-.7px',
    zIndex: '1000',
    padding: '0 20px',
  },

  container: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    maxWidth: '460px',
    margin: '8px auto',
    textAlign: 'center',
  },

  findOutMoreButton: {
    textDecoration: 'none',
    color: 'inherit',
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
    cursor: 'pointer',

    '&:hover': {
      color: 'inherit',
      textDecoration: 'none',
    }
  },

  closeButton: {
    background: `url(${closeIconButton})`,
    minWidth: '24px',
    minHeight: '24px',
    marginLeft: '8px',
    cursor: 'pointer',
  },
});
