import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Hidden from '@material-ui/core/Hidden';
import Link from '@material-ui/core/Link';
import MobileMenu from './MobileMenu';
import Nav from './Nav';
import { makeHeaderStyles } from 'styles/Header';
import { makeNavLinkStyles } from 'styles/NavLink';
import eduGryLogo from 'assets/img/logo_edugry.svg';

const ForwardNavLink = React.forwardRef((props, ref) => <NavLink {...props} innerRef={ref} />);

const Header = () => {
  const { header } = makeHeaderStyles();
  const { logo } = makeNavLinkStyles();

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <AppBar className={header} position="fixed">
      <Container>
        <Grid container>
          <Hidden mdUp>
            <Grid item xs={2} sm={1}>
              <MobileMenu mobileMenuOpen={mobileMenuOpen} setMobileMenuOpen={setMobileMenuOpen} />
            </Grid>
          </Hidden>
          <Grid item xs={10} sm={11} md={6} lg={7}>
            <Box
              display={{ xs: 'flex' }}
              justifyContent="space-between"
              alignItems="center"
              height="100%"
            >
              <Link
                className={`${logo} ${mobileMenuOpen ? 'transform' : ''}`}
                component={ForwardNavLink}
                to="/"
              >
                <img src={eduGryLogo} alt="eduGry" />
              </Link>
            </Box>
          </Grid>
          <Grid item md={6} lg={5}>
            <Nav />
          </Grid>
        </Grid>
      </Container>
    </AppBar>
  );
};

export default Header;
