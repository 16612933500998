import { makeStyles } from '@material-ui/styles';
import { perano, transition } from 'styles/common/variables';
import { darken } from '@material-ui/core/styles';

export const makeLoaderStyles = makeStyles({
  '@keyframes sk-bouncedelay': {
    '0%, 80%, 100%': {
      transform: 'scale(0)',
    },
    '40%': {
      transform: 'scale(1.0)',
    },
  },
  dot: {
    animation: '$sk-bouncedelay 1.4s infinite ease-in-out both',
    backgroundColor: '#333',
    borderRadius: '100%',
    display: 'inline-block',
    height: '18px',
    width: '18px',

    '&.dot-left': {
      animationDelay: '-0.32s',
      backgroundColor: perano,
    },
    '&.dot-middle': {
      animationDelay: '-0.16s',
      backgroundColor: darken(perano, 0.15),
    },
    '&.dot-right': {
      backgroundColor: darken(perano, 0.3),
    },
  },
  loader: {
    alignItems: 'center',
    background: 'rgba(255, 255, 255, .95)',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    textAlign: 'center',
    top: 0,
    transition: `all ${transition}`,
    width: '100%',
    zIndex: 10,

    '&.main-image': {
      background: 0,
      filter: 'none',

      '& + img': {
        opacity: 0.01,
        transition: `all ${transition}`,
      },

      '&.loaded': {
        display: 'none',

        '& + img': {
          opacity: 1,
        },
      },
    },

    '&.basic': {
      '& + img': {
        filter: 'blur(3px)',
      },

      '&.loaded': {
        display: 'none',

        '& + img': {
          filter: 'none',
        },
      },
    },
  },
});
