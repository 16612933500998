import React from 'react';
import { Introduction, Tiles } from 'pages/Subjects';
import Container from '@material-ui/core/Container';
import { Grid } from '@material-ui/core';

const Subjects = () => (
  <Container>
    <Grid container justify="center">
      <Grid item xs={11}>
        <Introduction /> <Tiles />
      </Grid>
    </Grid>
  </Container>
);

export default Subjects;
