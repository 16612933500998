import React from 'react';
import { NavLink } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { Grid } from '@material-ui/core';
import Button from 'components/Button';
import { makeTilesStyles } from 'styles/pages/Subjects';
import { texts } from './texts';
import { urls } from 'common/urls';

const renderTile = ({ id, className, header, img: { alt, src }, url }) => {
  const { card, cardContent, cardHeader, gridContainer, link, tile } = makeTilesStyles();
  const { subjects } = urls;

  return (
    <Grid key={id} className={gridContainer} item xs={12} sm={6} md={3}>
      <Box className={tile}>
        <Card className={card}>
          <CardHeader className={`${cardHeader} ${className}`} title={header} />
          <CardContent className={`${cardContent} ${className}`}>
            <NavLink to={`/${subjects.root}//${url}`}>
              <img src={src} alt={alt} />
            </NavLink>
          </CardContent>
        </Card>
        <NavLink className={link} to={`/${subjects.root}/${url}`}>
          <Button className="full-width" text="Zobacz więcej" />
        </NavLink>
      </Box>
    </Grid>
  );
};

const Tiles = () => {
  const { gridContainer } = makeTilesStyles();

  return (
    <Grid className={gridContainer} container spacing={6}>
      {texts.tiles.map(renderTile)}
    </Grid>
  );
};

export default Tiles;
