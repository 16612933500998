import React from 'react';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeIntroductionStyles } from 'styles/pages/Contact';
import { texts } from './texts';

const Introduction = () => {
  const { h1 } = makeIntroductionStyles();

  return (
    <Grid item lg={5}>
      <Typography
        className={h1}
        dangerouslySetInnerHTML={{ __html: texts.introduction.h1 }}
        variant="h1"
      />
    </Grid>
  );
};

export default Introduction;
