import { makeStyles } from '@material-ui/styles';
import { breakpointMd, darkSlateBlue, ghostWhite } from 'styles/common/variables';

export const makeProgramDescriptionSectionStyles = makeStyles({
  container: {
    alignItems: 'center',
    color: darkSlateBlue,
    display: 'flex',
    padding: '40px',
    position: 'relative',
    width: '100%',

    '&:nth-of-type(even)': {
      justifyContent: 'flex-end',
    },

    '&:nth-of-type(odd)': {
      background: ghostWhite,
    },

    '&:last-of-type': {
      marginBottom: '100px',
    },

    [`@media (max-width: ${breakpointMd}px)`]: {
      padding: '20px',
    },
  },
  description: {
    fontSize: '.875rem',
    fontWeight: 300,
    lineHeight: 1.6,
    marginBottom: '30px',

    '&:last-child': {
      marginBottom: 0,
    },

    '& b': {
      fontWeight: 600,
    },
  },
  h4: {
    fontSize: '1.5rem',
    marginTop: '30px',
    marginBottom: '30px',
  },
  h3: {
    fontSize: '2rem',
    marginBottom: '30px',
  },
  image: {
    maxWidth: '100%',
  },
  imageWrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    width: '40%',

    [`@media (max-width: ${breakpointMd}px)`]: {
      display: 'none',
    },
  },
  maxWidth: {
    width: '60%',

    [`@media (max-width: ${breakpointMd}px)`]: {
      width: '100%',
    },
  },
});
