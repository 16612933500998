import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { makeCookiesStyles } from 'styles/Cookies';
import { texts } from './texts';

const Cookies = ({ handleClose, open }) => {
  const { paragraphs, title } = texts;
  const { closeButton, dialog, dialogTitle, paragraph } = makeCookiesStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Dialog onClose={handleClose} fullScreen={fullScreen} open={open}>
      <DialogTitle className={dialogTitle}>
        {title}
        {handleClose ? (
          <IconButton aria-label="close" className={closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent className={dialog}>
        {paragraphs.map(({ id, text }) => (
          <Typography
            key={id}
            className={paragraph}
            component="p"
            dangerouslySetInnerHTML={{ __html: text }}
          />
        ))}
      </DialogContent>
    </Dialog>
  );
};

export default Cookies;
