import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ArrowBack from '@material-ui/icons/ArrowBackIos';
import { makeArticleContentStyles } from 'styles/pages/Article';
import SocialPanel from './SocialPanel';
import { texts } from './texts';
import { urls } from 'common/urls';

const { articles } = urls;

const ArticleSection = ({ id, paragraphs, sectionTitle }) => {
  const { paragraph, section, textWrapper, title } = makeArticleContentStyles();

  return (
    <section key={id} className={section}>
      <div className={textWrapper}>
        <Typography
          component="h3"
          className={title}
          dangerouslySetInnerHTML={{ __html: sectionTitle }}
        />
        {paragraphs.map(({ id, text }) => (
          <Typography
            key={id}
            component="p"
            className={paragraph}
            dangerouslySetInnerHTML={{ __html: text }}
          />
        ))}
      </div>
    </section>
  );
};

const ArticleContent = ({ articleTitle }) => {
  const {
    arrowBack,
    article,
    gridContainer,
    paragraph,
    prevLink,
    textWrapper,
  } = makeArticleContentStyles();

  return (
    <Grid className={gridContainer} container>
      <Grid item xs={12}>
        <article className={article}>
          {texts[articleTitle].introduction.map(({ id, text }) => (
            <div key={id} className={textWrapper}>
              <Typography
                component="p"
                className={paragraph}
                dangerouslySetInnerHTML={{ __html: text }}
              />
            </div>
          ))}
          {texts[articleTitle].sections.map(props => (
            <ArticleSection key={props.id} {...props} />
          ))}
        </article>
        <SocialPanel direction="right" />
        <NavLink className={prevLink} to={`/${articles.root}`}>
          <ArrowBack className={arrowBack} /> powrót
        </NavLink>
      </Grid>
    </Grid>
  );
};

ArticleContent.propTypes = {
  articleTitle: PropTypes.string.isRequired,
};

export default ArticleContent;
