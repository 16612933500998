import PowtorkomatImage from 'assets/img/programs/zadania-z-matematyki.png';
import { urls } from 'common/urls';

export const PowtorkoMat = (classLevel = '') => ({
  programPresentation: {
    classLevels: {
      6: {
        description: `Program uczy, jak rozwiązywać różne typy zadań z&nbsp;matematyki z&nbsp;zakresu 
          klas 4, 5 i 6 szkoły podstawowej, oraz pozwala na szybkie sprawdzenie zdobytych umiejętności.`,
        buyLink: 'https://gwo.pl/ksiegarnia/program-komputerowy/211,powtorkomat-6',
        demoLink: 'https://powtorkomat.gwo.pl/apps/runFreetourA/7090',
        img: {
          alt: 'Zadania matematyczne i powtórki materiału klasa 6 z eduGrą Powtórkomat 6',
          src: PowtorkomatImage,
        },
        price: '30 zł na rok | bezpłatna przesyłka',
      },
      8: {
        description: `Program umożliwia kompletne przygotowanie do egzaminu ósmoklasisty 
          z matematyki. W&nbsp;pierwszej części <i>PowtórkoMatu</i> można rozwiązywać ćwiczenia 
          powtórzeniowe (z&nbsp;filmami i&nbsp;komentarzami), a w&nbsp;drugiej – próbne zestawy zadań egzaminacyjnych.`,
        buyLink: 'https://gwo.pl/ksiegarnia/program-komputerowy/259,powtorkomat-8',
        demoLink: 'https://powtorkomat.gwo.pl/apps/runFreetourA/12111',
        img: {
          alt:
            'Zadania matematyczne klasa 8 i powtórki materiału przed egzaminem ósmoklasisty z eduGrą Powtórkomat 8',
          src: PowtorkomatImage,
        },
        price: '30 zł na rok | bezpłatna przesyłka',
      },
    },
    common: {
      classNameHeader: 'math',
      seeMoreLink: `/klasa-${classLevel}/${urls.apps.powtorkomat}-${classLevel}`,
      seeMoreLinkOnSubjectPage: `/${urls.subjects.root}/${urls.subjects.math}/${urls.apps.powtorkomat}`,
      img: {
        alt: 'Zadania matematyczne i powtórki materiału z eduGrą Powtórkomat',
        src: PowtorkomatImage,
      },
      subject: 'Matematyka',
      subtitle: 'gra edukacyjna online',
      title: `<i>PowtórkoMat ${classLevel}</i>`,
    },
  },
});
