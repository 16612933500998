export const CreativeIdeas = {
  articleTitle: `4 kreatywne pomysły na ferie w&nbsp;domu`,
  introduction: [
    {
      id: 1,
      text: `Nie każdy rodzic ma możliwość zorganizowania dziecku wyjazdu na ferie 
  lub opłacenia półkolonii. Podrzucamy więc cztery kreatywne pomysły, dzięki którym dziecko 
  nie tylko nie będzie się nudziło w&nbsp;domu, ale również rozwinie nowe umiejętności.`,
    },
  ],
  sections: [
    {
      id: 1,
      paragraphs: [
        {
          id: 1,
          text: `Pewnie większość dzieci nie miała jeszcze okazji, żeby własnoręcznie napisać 
          list i&nbsp;wysłać go na poczcie, a&nbsp;potem niecierpliwie czekać na listonosza, 
          który przyniesie odpowiedź. Czas więc to nadrobić!`,
        },
        {
          id: 2,
          text: `<ul>
            <li>Zastanówcie się z&nbsp;dziećmi, kto mógłby być odbiorcą listu – dziadkowie, 
            ciocia, wujek czy kuzynostwo?</li>
            <li>Pójdźcie do sklepu, wybierzcie piękną papeterię i&nbsp;zestaw kolorowych długopisów.</li>
            <li>
              Pomyślcie, o&nbsp;czym można by napisać (ściągę z&nbsp;poszczególnych części listu znajdziecie 
              w&nbsp;naszej eduGrze <i>Władcy Słów</i>).
            </li>
          </ul>`,
        },
        {
          id: 3,
          text: `Pisanie listów rozwija kreatywność, zwiększa zasób słów oraz pozwala poćwiczyć ortografię. 
          Poza tym odręczne pisanie to umiejętność, która zanika w dzisiejszych czasach 
          (również u dorosłych) – warto więc ją pielęgnować! `,
        },
      ],
      sectionTitle: `1. Napisanie tradycyjnego listu`,
    },
    {
      id: 2,
      paragraphs: [
        {
          id: 1,
          text: `Czy pamiętacie jakąś książkę z dzieciństwa, która zrobiła na Was duże wrażenie? 
          Być może do dziś macie ją na swojej półce… Jeśli jeszcze tego nie zrobiliście, dajcie ją 
          do przeczytania swoim dzieciom! Możecie też wspólnie przeczytać wybrane fragmenty.`,
        },
        {
          id: 2,
          text: `Taka lektura będzie doskonałą okazją do powspominania czasów Waszego dzieciństwa. 
          Dzieci uwielbiają słuchać o&nbsp;tym, jak było kiedyś. No i&nbsp;oczywiście takie opowieści zbliżą 
          Was do siebie jeszcze bardziej! `,
        },
      ],
      sectionTitle: `2. Przeczytanie ulubionej książki z&nbsp;dzieciństwa mamy lub taty`,
    },
    {
      id: 3,
      paragraphs: [
        {
          id: 1,
          text: `Planszówki cieszą się dziś ogromną popularnością. 
          Grają w nie całe pokolenia: dzieci, rodzice, dziadkowie… W&nbsp;sklepach jest bardzo duży 
          wybór najróżniejszych gier – od tych najprostszych, takich na kilka minut, 
          aż do skomplikowanych, gdzie rozgrywka potrafi zająć kilka dobrych godzin.`,
        },
        {
          id: 2,
          text: `Największą frajdę może dać jednak samodzielnie zaprojektowana planszówka! 
          Zachęćcie swoje dzieci, żeby wymyśliły i&nbsp;stworzyły własną grę, 
          w&nbsp;którą zagra później cała rodzina.`,
        },
        {
          id: 3,
          text: `Co może się przydać: <ul>
            <li>kartka brystolu,</li>
            <li>kredki, mazaki i&nbsp;kolorowe długopisy,</li>
            <li>kostki do gry,</li>
            <li>małe figurki (np. z jajek niespodzianek).</li>
          </ul>`,
        },
        {
          id: 4,
          text: `A&nbsp;jeśli chodzi o&nbsp;zasady – ograniczeniem jest tylko wyobraźnia dziecka. 
          Oczywiście nic nie stoi na przeszkodzie, żeby się wzorować na już istniejących grach, 
          ale np. pozmieniać odrobinę zasady. Efekt może być naprawdę ciekawy!`,
        },
      ],
      sectionTitle: `3. Stworzenie własnej gry planszowej`,
    },
    {
      id: 4,
      paragraphs: [
        {
          id: 1,
          text: `O tym, że programista to zawód przyszłości, nie trzeba chyba nikogo przekonywać. 
          Wielu osobom wydaje się jednak, że programowanie jest bardzo trudne i&nbsp;wymaga 
          specjalistycznej wiedzy. Po części to prawda. Istnieją jednak pewne języki programowania, 
          które mogą zostać z&nbsp;łatwością opanowane nawet przez dzieci w&nbsp;najmłodszych klasach podstawówki! `,
        },
        {
          id: 2,
          text: `Jednym z&nbsp;nich jest Scratch – język programowania stworzony przez Mitchela Resnicka 
          we współpracy z&nbsp;MIT (<i>Massachusetts Institute of Technology</i>). Programowanie w&nbsp;Scratchu 
          odbywa się wizualnie. Oznacza to, że elementy tego języka mają kształt puzzli. 
          Wystarczy je przeciągać w&nbsp;odpowiednie miejsca na ekranie, żeby w prosty sposób 
          tworzyć interaktywne historyjki, animacje lub gry.`,
        },
        {
          id: 3,
          text: `Ze Scratchem można bawić się na dwa sposoby: <ul>
            <li>w edytorze online (dostępnym w przeglądarce internetowej) lub</li>
            <li>w edytorze offline (zainstalowanym na komputerze).</li>
          </ul>`,
        },
        {
          id: 4,
          text: `Z&nbsp;obu edytorów można skorzystać całkowicie bezpłatnie na stronie 
          <a href="https://scratch.mit.edu/" target="_blank" rel="noopener noreferrer">https://scratch.mit.edu/</a>`,
        },
        {
          id: 5,
          text: `Jeśli nie wiecie, jak zacząć z&nbsp;dziećmi przygodę ze Scratchem i&nbsp;szukacie inspiracji 
          na pierwsze proste programy, polecamy naszą eduGrę <i>Informatyka 2.</i>`,
        },
      ],
      sectionTitle: `4. Zabawa w programowanie`,
    },
  ],
};
