export const Polish = [
  {
    id: 1,
    paragraphs: [
      {
        id: 1,
        text: `EduGra <b><i>Władcy Słów</i></b> to animowane <b>ćwiczenia z&nbsp;języka polskiego</b> dla 
          dzieci w&nbsp;wieku <b>9 lat, 10 lat, 11 lat</b> oraz <b>12 lat</b>. Została ona stworzona 
          z&nbsp;myślą, że najskuteczniejsza nauka to <b>nauka poprzez zabawę</b>.  
        `,
      },
      {
        id: 2,
        text: `We <i>Władcach Słów</i> dzieci znajdą wciągające <b>gry ortograficzne</b> oraz 
          <b>zabawy ortograficzne</b> (również: dyktanda online). Jest też mnóstwo zadań 
          pomagających przećwiczyć zagadnienia, których znajomości wymaga się 
          w&nbsp;<b>4 klasie</b>, <b>5 klasie</b> i&nbsp;<b>6 klasie szkoły podstawowej</b>. Nasze ćwiczenia 
          online obejmują takie tematy, jak: <b>odmiana rzeczownika</b> 
          (również: <b>odmiana przez przypadki</b>), <b>odmiana przymiotnika</b> oraz 
          <b>odmiana czasownika</b>. Rozwiązując animowane zadania, dziecko sprawdzi 
          swoją znajomość oraz umiejętność poprawnego zastosowania <b>liczebników</b>, 
          <b>zaimków rzeczownych</b>, <b>przymiotnych</b>, <b>przysłownych</b> oraz <b>liczebnych</b>, 
          <b>przyimków</b> i&nbsp;<b>wyrażeń przyimkowych</b>, <b>przysłówków</b>. Dzięki interaktywnym 
          zadaniom oraz ciekawym bohaterom gry <b><i>Władcy Słów</i></b> łatwo utrwali materiał 
          z&nbsp;zakresu użycia <b>spójników</b>, <b>wykrzyknika i&nbsp;partykuły</b>. Program w&nbsp;ciekawy 
          sposób prezentuje również ćwiczenia z&nbsp;<b>typów wypowiedzeń</b>, 
          <b>zdań pojedynczych</b> i&nbsp;<b>zdań złożonych</b> oraz <b>rozbioru zdania</b>. 
          Z&nbsp;grą <i>Władcy Słów</i> dziecko powtórzy sobie materiał z&nbsp;zakresu rodziny wyrazów, 
          <b>wyrazów pokrewnych</b>, <b>synonimów</b>, <b>antonimów</b> i&nbsp;<b>homonimów</b> oraz <b>frazeologizmów</b>. 
          <i>Władcy Słów</i> pomagają też poćwiczyć <b>czytanie ze zrozumieniem</b>
          oraz pisanie różnych tekstów. 
        `,
      },
    ],
    title: `Język polski dla dzieci`,
  },
];
