export const SpendingTimeWithChild = {
  articleTitle: `Dlaczego warto spędzać czas z&nbsp;dzieckiem?`,
  introduction: [
    {
      id: 1,
      text: `Dzisiejsze tempo życia sprawia, że łatwo zapomnieć o&nbsp;tym, jak ważne jest spędzanie czasu 
    z&nbsp;naszymi pociechami. Wśród napiętych planów dnia zarówno rodziców, 
    jak i&nbsp;dzieci – w&nbsp;natłoku pracy, obowiązków domowych i&nbsp;zajęć 
    dodatkowych – ciężko jest znaleźć chwilę dla siebie nawzajem.`,
    },
  ],
  sections: [
    {
      id: 1,
      paragraphs: [
        {
          id: 1,
          text: `Wspólne przygotowywanie posiłków, rodzinne gry i&nbsp;wyjazdy oraz inne aktywności, 
            w&nbsp;których bierze udział cała rodzina, to sygnał dla dziecka, że jest ono dla rodziców ważne. 
            Dziecko, któremu rodzice poświęcają swój czas i&nbsp;uwagę, ma wyższe 
            poczucie własnej wartości. A&nbsp;lepsza samoocena pozwala dziecku na 
            lepsze zrozumienie własnych emocji i&nbsp;radzenie sobie z&nbsp;nimi.`,
        },
      ],
      sectionTitle: `Lepsza samoocena`,
    },
    {
      id: 2,
      paragraphs: [
        {
          id: 1,
          text: `Dziecko naśladuje dorosłych i&nbsp;bierze z&nbsp;nich przykład. Dobre wzorce przenosi 
            na swoje relacje z&nbsp;rówieśnikami. Wspólne rozmowy pozwolą mu zdobyć 
            takie umiejętności interpersonalne, jak empatia, cierpliwość czy 
            uważne słuchanie. Wspólne jedzenie posiłków nauczy dziecko dzielenia 
            się z&nbsp;innymi, a&nbsp;np. rodzinne granie – usprawni umiejętności komunikacyjne.`,
        },
      ],
      sectionTitle: `Umiejętności interpersonalne`,
    },
    {
      id: 3,
      paragraphs: [
        {
          id: 1,
          text: `Wspólne spędzanie czasu z&nbsp;rodziną i&nbsp;słuchanie siebie nawzajem 
            tworzy atmosferę zaufania oraz sprzyja otwartości. 
            W&nbsp;takich warunkach dziecko będzie chętniej rozmawiało&nbsp;o swoich problemach 
            czy zmartwieniach. Dzięki emocjonalnemu wsparciu oraz poczuciu więzi 
            rodzinnych dziecko będzie dużo odporniejsze na niebezpieczne wpływy 
            rówieśników (np. popadnięcie w&nbsp;nałogi). Dodatkowo, dzięki poczuciu 
            akceptacji oraz wspólnoty, w&nbsp;nieprzewidywalnych&nbsp;i trudnych dla całej 
            rodziny sytuacjach łatwiej będzie dziecku stawić czoła problemom.`,
        },
      ],
      sectionTitle: `Umiejętność radzenia sobie z&nbsp;codziennymi trudnościami`,
    },
  ],
};
