import { makeStyles } from '@material-ui/styles';
import { headerHeight, shadow, white } from 'styles/common/variables';

export const makeHeaderStyles = makeStyles({
  header: {
    boxShadow: shadow,
    height: headerHeight,
    justifyContent: 'center',
    backgroundColor: white,
  },
});
