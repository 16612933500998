import { makeStyles } from '@material-ui/styles';
import {
  bluebell,
  breakpointMd,
  darkSlateBlue,
  footerHeight,
  footerBackground,
  lavender,
  navLinkHover,
  transition,
  whiteSmoke,
} from 'styles/common/variables';

export const makeFooterStyles = makeStyles({
  companyInformation: {
    [`@media (max-width: ${breakpointMd}px)`]: {
      marginBottom: '15px',
    },
  },
  cookiesLink: {
    textDecoration: 'underline',

    '&:hover': {
      cursor: 'pointer',
    },
  },
  expansionPanel: {
    boxShadow: 'none',
  },
  expansionPanelDetails: {
    backgroundColor: whiteSmoke,
    padding: '0 0 24px',
  },
  expansionPanelSummary: {
    backgroundColor: whiteSmoke,
    color: darkSlateBlue,
    padding: '0 25px 0 0',
    position: 'relative',

    '&.Mui-expanded': {
      '& .line-horizontal': {
        transform: 'rotate(180deg)',
        opacity: 0,
      },
      '& .line-vertical': {
        transform: 'rotate(270deg)',
      },
    },
  },
  footer: {
    backgroundColor: footerBackground,
    color: darkSlateBlue,
    minHeight: footerHeight,
    justifyContent: 'center',
    padding: '25px 0',
  },
  footerBottom: {
    color: bluebell,
    display: 'flex',
    fontSize: '.75rem',
    justifyContent: 'space-between',

    [`@media (max-width: ${breakpointMd}px)`]: {
      flexDirection: 'column',
    },
  },
  footerTop: {
    borderBottom: `1px solid ${lavender}`,
    marginBottom: '20px',
    paddingBottom: '20px',
  },
  linksColumnTitle: {
    fontSize: '1.125rem',
    fontWeight: 600,
    marginBottom: '5px',
  },
  link: {
    color: darkSlateBlue,
    display: 'block',
    fontWeight: 300,
    marginBottom: '3px',
    position: 'relative',
    textDecoration: 'none',
    transition: `color ${transition}`,

    '&::after': {
      content: "''",
      borderTop: `1px solid ${navLinkHover}`,
      bottom: 0,
      left: 0,
      height: '1px',
      position: 'absolute',
      transition: `all ${transition}`,
      width: 0,
    },

    '&:hover': {
      color: navLinkHover,

      '&::after': {
        width: '100%',
      },
    },
  },
  linkExternal: {
    height: '25px',
    marginTop: '25px',
    width: '25px',

    '& img': {
      height: '100%',
    },
  },
  list: {
    listStyleType: 'none',
    margin: 0,
    padding: 0,
  },
  listItem: {
    display: 'flex',
    fontWeight: 300,
  },
  plusIcon: {
    alignItems: 'center',
    bottom: 0,
    display: 'flex',
    height: '16px',
    justifyContent: 'center',
    margin: 'auto',
    position: 'absolute',
    right: 0,
    top: 0,
    width: '16px',

    '& .line-horizontal': {
      border: `1px solid ${darkSlateBlue}`,
      position: 'absolute',
      transition: `all ${transition}`,
      width: '100%',
    },

    '& .line-vertical': {
      border: `1px solid ${darkSlateBlue}`,
      height: '100%',
      position: 'absolute',
      transition: `all ${transition}`,
    },
  },
});
