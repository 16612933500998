import React, { Fragment } from 'react';
import Container from '@material-ui/core/Container';
import { Grid } from '@material-ui/core';
import { Description, Introduction, Tiles } from 'pages/Classes';
import Wrapper from 'components/Wrapper';
import { getUrlFragment } from 'common/getUrlFragment';

const Classes = ({ location }) => {
  const path = getUrlFragment(location, 1);
  const classLevel = path.split('-')[1];

  return (
    <Fragment>
      <Container>
        <Grid container justify="center">
          <Grid item xs={11}>
            <Introduction classLevel={classLevel} />
            <Tiles classLevel={classLevel} />
          </Grid>
        </Grid>
      </Container>
      <Wrapper>
        <Description classLevel={classLevel} />
      </Wrapper>
    </Fragment>
  );
};

export default Classes;
