import { makeStyles } from '@material-ui/styles';
import {
  inputPlaceholderColor,
  darkSlateBlue,
  errorColor,
  lavender,
  cadetGrey,
} from 'styles/common/variables';

const makeFormStyles = makeStyles({
  callUs: {
    color: darkSlateBlue,
    display: 'flex',
    flexDirection: 'column',
    fontSize: '1.25rem',
    margin: '80px 0 50px 0',
  },
  checkbox: {
    color: darkSlateBlue,

    '&.Mui-checked': {
      color: darkSlateBlue,
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',

    '& .error': {
      color: errorColor,
    },
  },
  field: {
    '& label.Mui-focused': {
      color: darkSlateBlue,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: lavender,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: cadetGrey,
      },
      '&:hover fieldset': {
        borderColor: cadetGrey,
      },
      '&.Mui-focused fieldset': {
        borderColor: cadetGrey,
      },
    },
  },
  fieldRequired: {
    color: darkSlateBlue,
    fontSize: '.75rem',
    marginTop: '15px',
  },
  input: {
    color: darkSlateBlue,
  },
  inputLabel: {
    color: inputPlaceholderColor,
  },
  label: {
    alignItems: 'flex-start',
    color: darkSlateBlue,
    marginTop: '12px',

    '&.error': {
      color: errorColor,
    },

    '& span': {
      fontSize: '.75rem',
    },
  },
  likeUsOnFacebook: {
    alignItems: 'center',
    color: darkSlateBlue,
    display: 'flex',
    fontSize: '1.25rem',
    marginBottom: '100px',
    textDecoration: 'none',

    '& img': {
      height: '36px',
      marginRight: '10px',
      width: '36px',
    },
  },
  writeUs: {
    color: darkSlateBlue,
    fontSize: '1.125rem',
    fontWeight: 600,
    marginTop: '50px',
  },
});

export default makeFormStyles;
