import React from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { Grid } from '@material-ui/core';
import { Form, Introduction } from 'pages/Contact';
import contactImage from 'assets/img/contact.svg';

const Contact = () => (
  <Container>
    <Grid container justify="center">
      <Grid item xs={11} md={6}>
        <Introduction />
        <Form />
      </Grid>
      <Grid item xs={11} md={5}>
        <Box
          alignItems="center"
          display={{ xs: 'none', md: 'flex' }}
          height="65%"
          justifyContent="center"
        >
          <img src={contactImage} alt="" />
        </Box>
      </Grid>
    </Grid>
  </Container>
);

export default Contact;
