import React from 'react';
import { makeWrapperStyles } from 'styles/Wrapper';

const Wrapper = ({ children }) => {
  const { wrapper } = makeWrapperStyles();

  return <div className={wrapper}>{children}</div>;
};

export default Wrapper;
