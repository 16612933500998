import { Grid, Box } from "@material-ui/core";
import React, {useState} from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const customStyles = {
  overlay: {
    zIndex: '10000',
  },
};

const Gallery = ({ gallery }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(1);

  return (
    <>
      <Box mt={7} mb={8}>
        <Grid container spacing={6}>
          {gallery.map((image, key) =>
            <Grid item xs={12} md={6} key={key}>
              <img src={image.img} alt={image.alt} style={{ width: '100%', cursor: 'pointer',  boxShadow: '0px 0px 12px 6px rgb(0 0 0 / 10%)', }} onClick={() => { setIsOpen(true); setPhotoIndex(key); }}/>
            </Grid>
          )}
        </Grid>
      </Box>

      {isOpen && (
        <Lightbox
          reactModalStyle={ customStyles }
          enableZoom={false}
          mainSrc={gallery[photoIndex].img}
          nextSrc={gallery[(photoIndex + 1) % gallery.length].img}
          prevSrc={gallery[(photoIndex + gallery.length - 1) % gallery.length].img}
          onAfterOpen={() => document.body.style.overflow = 'hidden' }
          onCloseRequest={() => {
            setIsOpen(false);
            document.body.style.overflow = 'unset';
          }}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + gallery.length - 1) % gallery.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % gallery.length)
          }
        />
      )}
    </>
  );
}

export default Gallery;
