import { makeStyles } from '@material-ui/styles';
import {
  breakpointMd,
  darkSlateBlue,
} from 'styles/common/variables';

const makeAdvantagesStyles = makeStyles({
  advantage: {
    alignItems: 'flex-start',
    color: darkSlateBlue,
    display: 'flex',
    fontSize: '1.25rem',
    flexDirection: 'column',

    [`@media (max-width: ${breakpointMd}px)`]: {
      fontSize: '1rem',
      padding: 0,
    },

    '& p': {
      marginBlock: '0',
      textAlign: 'center',
      alignSelf: 'center',
    },

    '& img': {
      maxWidth: '77px',
      minWidth: '77px',
      maxHeight: '77px',
      minHeight: '77px',
      objectFit: 'scale-down',
      objectPosition: 'center',
      alignSelf: 'center',
      marginBottom: '20px',

      [`@media (max-width: ${breakpointMd}px)`]: {
        maxWidth: '40px',
        minWidth: 'initial',
        marginBottom: 0,
      },
    },
  },

  gridItem: {
    '&.MuiGrid-item': {
      [`@media (max-width: ${breakpointMd}px)`]: {
        padding: '12px 24px',
      },
    },
  },
});

export default makeAdvantagesStyles;
