// white colors

export const ghostWhite = '#faf9fd';
export const white = '#ffffff';
export const whiteSmoke = '#f4f5fa';

// blue colors

export const anakiwa = '#a4c0ff';
export const darkSlateBlue = '#373e80';
export const cornflowerBlue = '#5f86d1';
export const danube = '#7187d4';
export const indigo = '#555ac6';
export const lightSteelBlue = '#a4a2ed';
export const malibu = '#6192ff';
export const slateBlue = '#687bc1';
export const steelBlue = '#4a76b5';
export const violetDark = '#545ccb';
export const violetLight = '#91a5ef';

// other colors

export const bluebell = '#9897c8';
export const coral = '#ee7e2f';
export const hibiscus = '#cc3369';
export const lavender = '#e9ecf8';
export const cadetGrey = '#9094b1';
export const mediumPurple = '#8a82d2';
export const mediumVioletred = '#be4390';
export const perano = '#adadf5';
export const silver = '#bbb9d1';
export const yellowGreen = '#afb33d';

export const advantageTileLeft = bluebell;
export const advantageTileMiddle = yellowGreen;
export const advantageTileRight = slateBlue;

export const topBarCardBlue = cornflowerBlue;
export const topBarCardGreen = yellowGreen;
export const topBarCardPurple = mediumPurple;
export const topBarCardViolet = mediumVioletred;

export const buttonBuyBackground = malibu;
export const buttonSeeMoreBackground = '#5E9CF9';
export const buttonPackageDiscountBackground = '#777CCB';
export const buttonDemoBackground = anakiwa;
export const footerBackground = whiteSmoke;

export const chooseClassBar = danube;
export const navLinkHover = steelBlue;
export const tileBackgroundHover = perano;
export const packageDiscountSeeMoreButtonHover = '#5B61CB';
export const violetLightButtonHover = '#6A86EF';
export const violetDarkButtonHover = '#333DBB';
export const linkColor = '#5A8AF7';

// breakpoints

export const breakpointXs = 0;
export const breakpointSm = 600;
export const breakpointMd = 960;
export const breakpointLg = 1280;
export const breakpointXl = 1920;

// container widths

export const containerWidthXs = 0;
export const containerWidthSm = 600;
export const containerWidthMd = 960;
export const containerWidthLg = 1365;
export const containerWidthXl = 1920;

// header height

export const headerHeight = '80px';

// footer height

export const footerHeight = '355px';

// transition

export const transition = '0.3s ease';

// article

export const articleFontFamily = 'Roboto Slab';
export const articleWidth = '65%';

// form

export const errorColor = hibiscus;
export const inputBorderColor = silver;
export const inputPlaceholderColor = silver;

// shadow

export const shadow =
  '0px 2px 4px -1px rgba(0, 0, 0, 0), 0px 4px 5px 0px rgba(0, 0, 0, 0.03), 0px 1px 10px 0px rgba(0,0,0,0.12)';

// cookies bar

export const cookiesBarBackgroundColor = '#F9F9FB';
export const cookiesBarColor = '#666666';
export const cookiesBarBoxShadow = '0 0 3px 1px rgba(0, 0, 0, 0.2)';
