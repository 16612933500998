import React, { Fragment } from 'react';
import Container from '@material-ui/core/Container';
import { Grid } from '@material-ui/core';
import Wrapper from 'components/Wrapper';
import { ChooseClass, Introduction, ShortDescription, ProgramDescriptionSection } from "pages/ApplicationClassSelection";
import Box from "@material-ui/core/Box";
import { useParams } from "react-router-dom";

const ApplicationClassSelection = () => {
  const { application } = useParams();

  return (
    <Fragment>
      <Container>
        <Grid container justify="center">
          <Grid item xs={8}>
            <Box mb={3}>
              <Introduction application={application} />
            </Box>
            <ShortDescription application={application} />
          </Grid>
        </Grid>
      </Container>
      <ChooseClass application={application} />
      <Wrapper>
        <Box mt={6}>
          <ProgramDescriptionSection application={application} />
        </Box>
      </Wrapper>
    </Fragment>
  );
};

export default ApplicationClassSelection;
