import React from 'react';
import PropTypes from 'prop-types';
import ProgramDescriptionSection from 'components/ProgramDescriptionSection';
import { texts } from './texts';

const Description = ({ subject }) =>
  texts.subjects[subject].programsDescriptions.map(({ id, paragraphs, title }) => (
    <ProgramDescriptionSection key={id} id={id} paragraphs={paragraphs} title={title} />
  ));

Description.propTypes = {
  subject: PropTypes.string.isRequired,
};

export default Description;
