import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {Grid, Box, Hidden} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeShortDescriptionStyles } from 'styles/pages/Application';
import { texts } from './texts';

const ShortDescription = ({ application }) => {
  const { description, imageSection } = makeShortDescriptionStyles();

  return (
    <Fragment>
      <Grid container>
        <Grid item xs={12} md={7}>
          <Box mb={6}>
            <Typography
              className={description}
              dangerouslySetInnerHTML={{ __html: texts.apps[application].description }}
              component="p"
              align="left"
            />
          </Box>
        </Grid>
        <Grid item md={5} className={imageSection}>
          <Hidden smDown>
            <img src={texts.apps[application].descriptionImage} alt={''} />
          </Hidden>
        </Grid>
      </Grid>
    </Fragment>
  );
};

ShortDescription.propTypes = {
  application: PropTypes.string.isRequired,
};

export default ShortDescription;
