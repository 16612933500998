export const texts = {
  statute: {
    sections: [
      {
        id: 1,
        title: `&sect; 1 Postanowienia ogólne`,
        paragraphs: [
          {
            id: 1,
            text: `
              1. Organizatorem Promocji dla osób posiadających Kartę Dużej Rodziny 
              (zwanej dalej „Promocją”) jest Gdańskie Wydawnictwo Oświatowe 
              spółka z&nbsp;ograniczoną odpowiedzialnością spółka komandytowa 
              z&nbsp;siedzibą w&nbsp;Gdańsku (kod pocztowy: 80-309), al. Grunwaldzka 413 
              (zwana dalej „GWO”), zarejestrowana w&nbsp;Sądzie Rejonowym Gdańsk – Północ w&nbsp;Gdańsku, 
              VII Wydział Gospodarczy KRS pod nr KRS 0000537551, 
              posiadająca NIP: 584-10-00-656 oraz REGON: 190409115, 
              będąca właścicielem księgarni internetowej prowadzonej przez GWO w&nbsp;Serwisie.
            `,
          },
          {
            id: 2,
            text: `
              2. Promocja dotyczy produktów dostępnych w&nbsp;księgarni internetowej 
              GWO prowadzonej na stronie <a href="https://gwo.pl/ksiegarnia" target="_blank" rel="noopener noreferrer">www.gwo.pl/ksiegarnia</a>
              (dalej zwanej „Księgarnią”).
            `,
          },
          {
            id: 3,
            text: `
              3. W&nbsp;ramach Promocji osoby posiadające ważną Kartę Dużej Rodziny mogą skorzystać 
              z&nbsp;rabatu 12% na wszystkie produkty dostępne w&nbsp;księgarni internetowej, 
              oprócz wykładów online, aplikacji z&nbsp;serii Kompozytor klasówek i&nbsp;kart pracy 
              oraz innych produktów przeznaczonych tylko i&nbsp;wyłącznie dla nauczycieli.
            `,
          },
          {
            id: 4,
            text: `
              4. Skorzystanie z&nbsp;Promocji jest dobrowolne.
            `,
          },
          {
            id: 5,
            text: `
              5. Postanowienia niniejszego regulaminu (zwanego dalej „Regulaminem”) 
              nie wyłączają stosowania postanowień Regulaminu świadczenia usług 
              drogą elektroniczną – Serwis GWO.PL oraz Regulaminu świadczenia usług 
              drogą elektroniczną – Księgarnia, które znajdują zastosowanie 
              w&nbsp;wypadku korzystania z&nbsp;Księgarni.
            `,
          },
        ],
      },
      {
        id: 2,
        title: `&sect; 2 Zasady promocji`,
        paragraphs: [
          {
            id: 1,
            text: `
              1. Z&nbsp;promocji mogą skorzystać wyłącznie osoby posiadające ważną Kartę Dużej Rodziny, 
              wydaną w&nbsp;ramach ogólnopolskiego programu wsparcia rodzin wielodzietnych 
              zgodnie z&nbsp;ustawą z&nbsp;dnia 5 grudnia 2014 roku o&nbsp;Karcie Dużej Rodziny 
              (Dz.U. z&nbsp;2017 r., poz. 1832, t. j. z&nbsp;późn. zm.).
            `,
          },
          {
            id: 2,
            text: `
              2. Promocja jest przeznaczona wyłącznie dla konsumentów, 
              w&nbsp;rozumieniu art. 221 ustawy z&nbsp;dnia 23 kwietnia 1964 roku 
              Kodeks cywilny (Dz.U. z&nbsp;2017 r., poz. 459, t. j. z&nbsp;późn. zm.). 
              Uprawnienia wynikające z&nbsp;Promocji nie przysługują z&nbsp;tytułu zakupów 
              dokonywanych w&nbsp;ramach prowadzonej działalności gospodarczej.
            `,
          },
          {
            id: 3,
            text: `
              3. W&nbsp;ramach Promocji konkretnej osobie są przyznawane nieprzenoszalne 
              uprawnienia rabatowe. Zabronione jest korzystanie z&nbsp;cudzej 
              Karty Dużej Rodziny i&nbsp;skorzystanie w&nbsp;ten sposób z&nbsp;Promocji.
            `,
          },
          {
            id: 4,
            text: `
              4. Warunkiem skorzystania z&nbsp;Promocji jest:
              <ul>
                <li>
                  akceptacja jego postanowień,
                </li>
                <li>
                  ukończenie 18 lat i&nbsp;posiadanie pełnej zdolności do czynności prawnych,
                </li>
                <li>
                  posiadanie i&nbsp;legitymowanie się ważną Kartą Dużej Rodziny.
                </li>
              </ul>
            `,
          },
          {
            id: 5,
            text: `
              5. Rabat w&nbsp;odpowiedniej wysokości zostaje udzielony w&nbsp;taki sposób, 
              że regularna (podstawowa) cena brutto za produkt zakupiony przez uczestnika 
              w&nbsp;ramach Promocji zostanie pomniejszona o&nbsp;wysokość przysługującego rabatu.
            `,
          },
          {
            id: 7,
            text: `
              7. W&nbsp;wypadku jednoczesnego zakupu kilku produktów objętych Promocją, 
              rabat naliczany jest oddzielnie od ceny każdego produktu objętego Promocją.
            `,
          },
          {
            id: 8,
            text: `
              8. Przyznany w&nbsp;ramach Promocji rabat nie podlega wypłacie w&nbsp;pieniądzu 
              lub wymianie na jakikolwiek inny środek płatniczy.
            `,
          },
        ],
      },
      {
        id: 3,
        title: `&sect; 3 Sposób skorzystania z&nbsp;Promocji`,
        paragraphs: [
          {
            id: 1,
            text: `
              W&nbsp;celu skorzystania z&nbsp;Promocji na produkty oferowane w&nbsp;Księgarni należy wpisać 
              w&nbsp;koszyku zakupowym (w pierwszym kroku zamówienia) indywidualny 
              numer Karty Dużej Rodziny w&nbsp;polu <i>Kod rabatowy</i>.
            `,
          },
          {
            id: 2,
            text: `
              Brak ważnych uprawnień wynikających z&nbsp;Karty Dużej Rodziny uprawnia 
              GWO do odmowy udzielenia rabatu wynikającego z&nbsp;Promocji. 
              GWO zastrzega sobie prawo do zweryfikowania ważności Karty Dużej Rodziny.
            `,
          },
        ],
      },
      {
        id: 4,
        title: `&sect; 4 Reklamacje`,
        paragraphs: [
          {
            id: 1,
            text: `
              1. Reklamacje można składać pocztą elektroniczną pod adresem <a href="mailto:handel@gwo.pl">handel@gwo.pl</a> 
              (wpisując w&nbsp;temacie: „reklamacja”), za pomocą formularza kontaktowego 
              dostępnego na stronie Serwisu albo pisemnie pod adresem: GWO; 
              Dział Handlowy; al. Grunwaldzka 413; 80-309 Gdańsk, z&nbsp;dopiskiem „Reklamacja”.
            `,
          },
          {
            id: 2,
            text: `
              2. GWO udzieli odpowiedzi na reklamację w&nbsp;terminie 30 dni od daty jej otrzymania. 
              Jeżeli GWO nie udzieli odpowiedzi w&nbsp;powyższym terminie przyjmuje się, 
              że reklamacja została uznana.
            `,
          },
        ],
      },
      {
        id: 5,
        title: `&sect; 5 Pozostałe postanowienia`,
        paragraphs: [
          {
            id: 1,
            text: `
              1. W&nbsp;sprawach nieuregulowanych niniejszym Regulaminem zastosowanie 
              znajdą odpowiednie przepisy Kodeksu Cywilnego.
            `,
          },
          {
            id: 2,
            text: `
              2. Organizator promocji zastrzega sobie prawo do dokonania zmiany postanowień Regulaminu.
            `,
          },
        ],
      },
    ],
    title: `Regulamin promocji dla osób posiadających<br/>Kartę Dużej Rodziny`,
  },
};
