import { makeStyles } from '@material-ui/styles';
import { articleWidth, breakpointMd } from 'styles/common/variables';

const makeSocialPanelStyles = makeStyles({
  shareButton: {
    '&:focus': {
      outline: 'none',
    },

    '&:hover': {
      cursor: 'pointer',
    },
  },
  socialPanel: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '20px',
    maxWidth: '60px',
    width: '100%',

    '&.right': {
      alignSelf: 'flex-end',
    },

    '& img': {
      height: '20px',
      width: '20px',
    },
  },
  socialPanelWrapper: {
    display: 'flex',
    maxWidth: articleWidth,

    '&.right': {
      justifyContent: 'flex-end',
    },

    [`@media (max-width: ${breakpointMd}px)`]: {
      maxWidth: '100%',
    },
  },
});

export default makeSocialPanelStyles;
