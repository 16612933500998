import creativeIdeas from 'assets/img/4-kreatywne-pomysły-na-ferie-w-domu.png';
import spendingTimeWithChild from 'assets/img/dlaczego-warto-spedzac-czas-z-dzieckiem.png';
import waysOfMotivatingChild from 'assets/img/3-sposoby-na-zmotywowanie-dziecka-do-nauki.png';
import { urls } from 'common/urls';

const { articles } = urls;

export const texts = {
  introduction: {
    h1: 'Inspirujące porady<br/>dla rodziców',
    h2: `W tym miejscu znajdziesz inspirujące porady dla rodziców związane 
    z&nbsp;edukacją dziecka oraz ciekawe pomysły na spędzanie czasu z&nbsp;dzieckiem. 
    Zapraszamy do lektury!`,
  },
  tiles: [
    {
      id: 1,
      article: {
        description: `Jak mądrze zachęcić dziecko do nauki i&nbsp;pomóc 
        mu odnosić sukcesy?`,
        title: '3 skuteczne sposoby na zmotywowanie dziecka do nauki',
      },
      img: {
        alt: '3 sposoby na zmotywowanie dziecka do nauki',
        src: waysOfMotivatingChild,
      },
      link: `${articles.root}/${articles.waysOfMotivatingChild}`,
    },
    {
      id: 2,
      article: {
        description: `Jakie korzyści dają dziecku wspólne posiłki, 
        rozmowy oraz rodzinne gry?`,
        title: 'Dlaczego warto spędzać czas z&nbsp;dzieckiem?',
      },
      img: {
        alt: 'Dlaczego warto spędzać czas z dzieckiem?',
        src: spendingTimeWithChild,
      },
      link: `${articles.root}/${articles.spendingTimeWithChild}`,
    },
    {
      id: 3,
      article: {
        description: `Co zrobić, aby dziecko nie nudziło się w&nbsp;ferie w&nbsp;domu 
        i&nbsp;przy okazji rozwinęło nowe umiejętności?`,
        title: '4 kreatywne pomysły na ferie w&nbsp;domu',
      },
      img: {
        alt: '4 kreatywne pomysły na ferie w domu',
        src: creativeIdeas,
      },
      link: `${articles.root}/${articles.creativeIdeas}`,
    },
  ],
};
