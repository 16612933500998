import { makeStyles } from "@material-ui/styles";
import { breakpointMd, violetLight, violetDark, violetDarkButtonHover, violetLightButtonHover, linkColor } from "styles/common/variables";

const makeButtonsSectionStyles = makeStyles({
  linksWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '100px',

    '& a': {
      margin: '20px',
      fontSize: '1rem',
      boxShadow: '0px 0px 12px 6px rgb(0 0 0 / 10%)',

      [`@media (max-width: ${breakpointMd}px)`]: {
        margin: '20px 0 0 0',
        width: '100%',
      },

      '&:last-child': {
        [`@media (max-width: ${breakpointMd}px)`]: {
          margin: '20px 0 20px 0',
        },
      },
    },

    [`@media (max-width: ${breakpointMd}px)`]: {
      alignItems: 'center',
      flexDirection: 'column',
    },
  },
  link: {
    margin: '20px auto',
    borderRadius: '12px',
    width: '100%',
    maxWidth: '365px',
  },
  textLink: {
    color: linkColor,
    fontSize: '1.125rem',
    textDecoration: "none",

    '& img': {
      transform: 'rotate(0.5turn)',
      height: '22px',
      position: 'relative',
      top: '5px',
      left: '5px',
    },
  },
  violetLight: {
    background: violetLight,

    '&:hover': {
      background: violetLightButtonHover,
    }
  },
  violetDark: {
    background: violetDark,

    '&:hover': {
      background: violetDarkButtonHover,
}
  },
});

export default makeButtonsSectionStyles;
