import { makeStyles } from '@material-ui/styles';
import { articleFontFamily } from 'styles/common/variables';

const makeIntroductionStyles = makeStyles({
  h1: {
    fontFamily: articleFontFamily,
    fontSize: '2rem',
    marginTop: '65px',
  },
});

export default makeIntroductionStyles;
