export const texts = {
  form: {
    errors: {
      checked: 'Pole musi zostać zaznaczone.',
      emailInvalid: 'Niepoprawny adres e-mail.',
      required: 'Pole wymagane.',
    },
    fieldRequired: '* Pole obowiązkowe',
    fields: {
      labels: {
        contactName: '* Imię i nazwisko',
        dataProcessing: `* Wyrażam zgodę na przetwarzanie moich danych osobowych 
        w celu udzielenia mi odpowiedzi na pytania zadane poprzez formularz kontaktowy.`,
        email: '* Adres e-mail',
        emailMarketing: `* Wyrażam zgodę na przesyłanie mi informacji handlowych dotyczących administratora drogą elektroniczną.`,
        phoneNumber: 'Numer telefonu',
        text: '* Treść wiadomości',
      },
      names: {
        contactName: 'contact_name',
        dataProcessing: 'data_processing',
        email: 'email',
        emailMarketing: 'email_marketing',
        phoneNumber: 'phone_number',
        message: 'message',
      },
    },
    likeUsText: 'Polub nas na Facebooku',
    send: 'Wyślij',
    sentSuccessfully: 'Formularz wysłany pomyślnie.',
    writeUs: 'Napisz do nas',
  },
  introduction: {
    h1: 'Kontakt',
  },
};
