import React, { Fragment } from 'react';
import largeFamilyCard from 'assets/img/karta-duzej-rodziny.svg';
import { NavLink } from 'react-router-dom';
import { urls } from 'common/urls';

export const texts = {
  h1: 'Karta Dużej Rodziny',
  img: {
    alt: 'Karta Dużej Rodziny',
    src: largeFamilyCard,
  },
  paragraphs: [
    {
      id: 1,
      text: `<b>Tutaj honorujemy Kartę Dużej Rodziny (KDR)!</b> Posiadaczom KDR przysługuje rabat 
          12% na zakup eduGier dostępnych w&nbsp;naszej 
          <a 
            href="https://gwo.pl/ksiegarnia/search?categories%5b603%5d=1&category_group_id=1170"
            target="_blank"
            rel="noopener noreferrer"
          >księgarni internetowej</a>.
        `,
    },
    {
      id: 2,
      Text: () => (
        <Fragment>
          Zniżka ta nie łączy się z&nbsp;innymi promocjami. Pełny regulamin promocji znajdziesz{' '}
          <NavLink to={`/${urls.largeFamily.root}/${urls.largeFamily.statute}`}>tutaj</NavLink>.
        </Fragment>
      ),
    },
    {
      id: 3,
      text: `
          <b>
            Aby skorzystać z&nbsp;rabatu, w&nbsp;koszyku zakupowym (w&nbsp;pierwszym kroku zamówienia) 
            wpisz numer swojej KDR w&nbsp;polu <i>Karta Dużej Rodziny</i>.
          </b>
        `,
    },
  ],
};
