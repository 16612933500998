import { makeStyles } from '@material-ui/styles';
import { darkSlateBlue } from 'styles/common/variables';

export const makeNotFoundStyles = makeStyles({
  container: {
    alignItems: 'center',
    color: darkSlateBlue,
    display: 'flex',
    flexDirection: 'column',
    height: '300px',
    justifyContent: 'space-evenly',
    marginTop: '50px',
  },
  h1: {
    fontSize: '2rem',
    textTransform: 'uppercase',
  },
  img: {
    height: '100px',
    width: '100px',
  },
  paragraph: {
    fontWeight: 600,
  },
});
