import React, { Fragment } from 'react';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeIntroductionStyles } from 'styles/pages/Article';
import { texts } from './texts';

const Introduction = ({ articleTitle }) => {
  const { h1 } = makeIntroductionStyles();

  return (
    <Fragment>
      <Grid item lg={5}>
        <Typography
          className={h1}
          dangerouslySetInnerHTML={{ __html: texts[articleTitle].articleTitle }}
          variant="h1"
        />
      </Grid>
    </Fragment>
  );
};

export default Introduction;
