import { makeStyles } from '@material-ui/styles';
import { darkSlateBlue } from 'styles/common/variables';

const makeIntroductionStyles = makeStyles({
  h1: {
    marginTop: '65px',
  },
  h2: {
    color: darkSlateBlue,
    fontSize: '1.125rem',
    fontWeight: 300,
    lineHeight: '1.8',
    paddingRight: '40px',
    margin: '25px 0 60px 0',
  },
});

export default makeIntroductionStyles;
