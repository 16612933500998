import bohaterowieMatlandia from 'assets/img/Matematyka/Matlandia/bohaterowie_matlandia.svg';
import bohaterowieInformatyka from 'assets/img/Informatyka/Informatyka/bohaterowie_informatyka.svg';
import bohaterowiePowtorkomat from 'assets/img/Powtorkomat/Powtorkomat/bohaterowie_powtorkomat.svg';

export const texts = {
  apps: {
    'matlandia': {
      h1: '<i>Matlandia</i>',
      h2: 'gra edukacyjna online',
      classesNumbers: [4, 5, 6, 7, 8],
      description: `Wciągająca <b>gra matematyczna na komputer</b> dla dzieci uczących się w&nbsp;<b>klasach 4–8</b> (wiek <b>9–14 lat</b>). 
      Zapewnia <b>szybki rozwój umiejętności matematycznych</b> oraz <b>pomaga ćwiczyć pamięć i&nbsp;logiczne myślenie</b>. Kładzie 
      nacisk na <b>wykorzystanie matematyki w&nbsp;codziennych sytuacjach</b>.
      Od 2010 roku z&nbsp;<i>Matlandii</i> skorzystało ponad 200&nbsp;000 dzieci!
      `,
      descriptionImage: bohaterowieMatlandia,
      descriptionTitle: ``,
      descriptionParagraphs: [
        {
          id: 1,
          text: `<b><i>Matlandia</i></b> to wciągająca <b>gra matematyczna</b>, która została stworzona z&nbsp;myślą, że najskuteczniejsza nauka to <b>nauka poprzez zabawę</b>. 
          <b>Zadania matematyczne</b> w&nbsp;naszej eduGrze mają zróżnicowany poziom trudności, dlatego są idealne dla dzieci w&nbsp;różnym wieku: <b>9&nbsp;lat, 10&nbsp;lat, 
          11&nbsp;lat, 12&nbsp;lat, 13&nbsp;lat</b> oraz <b>14&nbsp;lat</b>. 
          `,
        },
        {
          id: 2,
          text: `Każda część <i>Matlandii</i> pomaga dzieciom bezstresowo przećwiczyć zagadnienia, których znajomości wymaga się w&nbsp;szkole podstawowej: <b>rozpoznawanie liczb, 
          dodawanie i&nbsp;odejmowanie, mnożenie i&nbsp;dzielenie</b> (również: tabliczka mnożenia i&nbsp;dzielenie z&nbsp;resztą), <b>kolejność wykonywania działań, liczby parzyste 
          i&nbsp;liczby nieparzyste, liczby dodatnie i&nbsp;liczby ujemne, oś liczbowa, cyfry rzymskie, system dziesiątkowy, ułamki zwykłe, ułamki dziesiętne, potęgi, 
          pierwiastki, figury geometryczne, pola figur</b> (pole trójkąta, pole prostokąta, pole trapezu, pole równoległoboku, pole rombu), <b>obwody figur, graniastosłupy</b> 
          (siatki graniastosłupów, pole powierzchni graniastosłupa, objętość graniastosłupa), <b>ostrosłupy</b> (siatki ostrosłupów, pole powierzchni ostrosłupa, objętość 
          ostrosłupa), <b>procenty, wyrażenia algebraiczne, równania, wyrażenia arytmetyczne, twierdzenie Pitagorasa, liczba&nbsp;Pi, symetrie, rachunek prawdopodobieństwa, 
          obliczenia pieniężne, litry, kilogramy i&nbsp;dekagramy, centymetry i&nbsp;milimetry, odczytywanie godzin na zegarze wskazówkowym i&nbsp;elektronicznym, jednostki czasu, 
          zapisywanie dat, skala i&nbsp;plan, prawa i&nbsp;lewa strona, kierunki świata</b>. 
          `,
        },
      ],
    },
    'powtorkomat': {
      h1: 'PowtórkoMat',
      h2: 'gra edukacyjna online',
      classesNumbers: [6, 8],
      description: `<b><i>PowtórkoMat&nbsp;6</i></b> umożliwia <b>powtórki z&nbsp;matematyki z&nbsp;zakresu klas 4–6</b>,
        a&nbsp;<b><i>PowtórkoMat&nbsp;8 </i></b> przygotowuje do <b>egzaminu ósmoklasisty</b>. Obie eduGry za pomocą
        filmów i&nbsp;komentarzy uczą, jak rozwiązywać różne typy zadań, a&nbsp;następnie pozwalają na szybkie sprawdzenie
        zdobytych umiejętności.
      `,
      descriptionImage: bohaterowiePowtorkomat,
      descriptionTitle: ``,
      descriptionParagraphs: [
        {
          id: 1,
          text: `<b><i>PowtórkoMat</i></b>&nbsp;to&nbsp;<b>interaktywne powtórki z&nbsp;matematyki na komputer</b>.
            W&nbsp;pierwszej części&nbsp;<i>PowtórkoMatu</i>&nbsp;dzieci mogą rozwiązywać&nbsp;<b>ćwiczenia powtórzeniowe
            z&nbsp;matematyki</b>, które zawierają wskazówki lub&nbsp;<b>filmy z&nbsp;rozwiązaniami krok po kroku</b>.
            Druga część to&nbsp;<b>testy&nbsp;sprawdzające</b> pozwalające na sprawdzenie wiedzy.
          `,
        },
      ],
    },
    'informatyka': {
      h1: 'Informatyka',
      h2: 'gra edukacyjna online',
      classesNumbers: [1, 2, 3],
      description: `Wciągające <b>animowane ćwiczenia na komputer</b> dla dzieci z&nbsp;klas&nbsp;<b>1–3</b> (wiek <b>6–9&nbsp;lat</b>).
        Pracując z&nbsp;<i>Informatyką</i>, dzieci poznają <b>podstawy programowania</b>, a&nbsp;także uczą się sprawnego
        <b>posługiwania się</b> myszką i&nbsp;szybkiego <b>pisania na klawiaturze</b>.
      `,
      descriptionImage: bohaterowieInformatyka,
      descriptionTitle: `Informatyka dla dzieci`,
      descriptionParagraphs: [
        {
          id: 1,
          text: `O tym, że programista to zawód przyszłości, nie trzeba nikogo przekonywać.&nbsp;<b>Kodowanie</b>&nbsp;to
            umiejętność, której warto się uczyć już od najmłodszych lat. Pomocna tu może być&nbsp;<b>gra edukacyjna
            online na komputer&nbsp;<i>Informatyka</i></b>, czyli&nbsp;<b>nauka programowania dla dzieci<b>&nbsp;poprzez zabawę.
          `,
        },
        {
          id: 2,
          text: `Zadania w&nbsp;<i>Informatyce</i>&nbsp;mają zróżnicowany poziom trudności, dlatego są idealne dla dzieci
            w&nbsp;różnym wieku: <b>6&nbsp;lat, 7&nbsp;lat, 8&nbsp;lat</b>&nbsp;oraz&nbsp;<b>9&nbsp;lat</b>.&nbsp;<b>Czytane na głos polecenia</b>
            &nbsp;ułatwiają zrozumienie treści ćwiczeń.&nbsp;<b>Programowanie wizualne</b>&nbsp;zostało wprowadzone w&nbsp;formie prostych gier
            UFO oraz Pan Ołówek. W&nbsp;naszej eduGrze dostępny jest również&nbsp;<b>kurs programowania dla dzieci w&nbsp;języku 
            Scratch</b>&nbsp;(filmy instruktażowe krok po kroku). W&nbsp;Informatyce&nbsp;znajdują się także zadania doskonalące
            &nbsp;<b>posługiwanie się myszką komputerową</b>&nbsp;oraz&nbsp;<b>szybkie bezwzrokowe pisanie na klawiaturze wszystkimi
            palcami</b>. Oprócz tego dzieci dowiadują się, do czego mogą się przydać na co dzień&nbsp;<b>edytor grafiki</b>&nbsp;
            (np.&nbsp;<i>MS Paint</i>) i&nbsp;edytor tekstu&nbsp;(np.&nbsp;<i>MS Word</i>) oraz&nbsp;<b>jak bezpiecznie korzystać 
            z&nbsp;komputera i&nbsp;internetu</b>.
          `,
        },
      ],
    },
  },
};
