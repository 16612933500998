import { makeStyles } from '@material-ui/styles';
import { darkSlateBlue, shadow } from 'styles/common/variables';

export const makeCookiesStyles = makeStyles({
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  dialog: {
    color: darkSlateBlue,

    '& ul': {
      listStyleType: 'none',
      paddingLeft: '20px',
    },
  },
  dialogTitle: {
    color: darkSlateBlue,
    boxShadow: shadow,
    textAlign: 'center',
  },
  paragraph: {
    fontSize: '.75rem',
    marginBottom: '10px',
  },
});
