import React from 'react';
import PropTypes from 'prop-types';
import ProgramDescriptionSection from 'components/ProgramDescriptionSection';
import { texts } from './texts';

const Description = ({ classLevel }) =>
  texts.classLevels[classLevel].programsDescriptions.map(
    ({ id, paragraphs, descriptionImage, title }, index) => (
      <ProgramDescriptionSection
        key={id}
        id={id}
        descriptionImage={descriptionImage}
        paragraphs={paragraphs}
        showDescriptionImage={index === 1}
        title={title}
      />
    )
  );

Description.propTypes = {
  classLevel: PropTypes.string.isRequired,
};

export default Description;
