import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import PresentationTile from 'components/PresentationTile';
import { makePresentationTileStyles } from 'styles/PresentationTile';
import { texts } from './texts';

const Tiles = ({ subject }) => {
  const { gridContainer } = makePresentationTileStyles();

  return (
    <Grid container className={gridContainer} justify={'center'} spacing={6}>
      {texts.subjects[subject].programs.map(props => (
        <PresentationTile key={props.id} {...props} />
      ))}
    </Grid>
  );
};

Tiles.propTypes = {
  subject: PropTypes.string.isRequired,
};

export default Tiles;
