import { makeStyles } from '@material-ui/styles';
import {
  topBarCardBlue,
  topBarCardPurple,
  topBarCardGreen,
  topBarCardViolet,
  breakpointMd,
  breakpointLg,
  white,
} from 'styles/common/variables';

const makeTilesStyles = makeStyles({
  card: {
    margin: '0 auto 30px auto',
  },
  cardContent: {
    display: 'flex',
    padding: 0,

    '&:last-child': {
      paddingBottom: 0,
    },

    '& img': {
      height: '100%',
      width: '100%',
    },
  },
  cardHeader: {
    color: white,
    fontSize: '1rem',
    padding: '10px 0',
    textAlign: 'center',

    '&.math': {
      backgroundColor: topBarCardBlue,
    },

    '&.polish': {
      backgroundColor: topBarCardGreen,
    },

    '&.history': {
      backgroundColor: topBarCardViolet,
    },

    '&.informatics': {
      backgroundColor: topBarCardPurple,
    },
  },
  gridContainer: {
    '& .MuiGrid-item': {
      [`@media  (min-width: ${breakpointMd}px) and (max-width: ${breakpointLg}px)`]: {
        padding: '8px',
      },
    },
  },
  link: {
    textDecoration: 'none',
  },
  tile: {
    margin: '0 auto 150px auto',
    maxWidth: '255px',

    [`@media (max-width: ${breakpointMd}px)`]: {
      margin: '0 auto 50px auto',
    },
  },
});

export default makeTilesStyles;
