import { makeStyles } from '@material-ui/styles';
import {
  advantageTileLeft,
  advantageTileMiddle,
  advantageTileRight,
  breakpointMd,
  breakpointLg,
  darkSlateBlue,
  white,
} from 'styles/common/variables';
import advantagesBarLeft from 'assets/img/advantages_bar_left.svg';
import advantagesBarMiddle from 'assets/img/advantages_bar_middle.svg';
import advantagesBarRight from 'assets/img/advantages_bar_right.svg';

import advantagesBarTop from 'assets/img/advantages_bar_top.svg';
import advantagesBarMiddleTop from 'assets/img/advantages_bar_middle_top.svg';
import advantagesBarMiddleBottom from 'assets/img/advantages_bar_middle_bottom.svg';
import advantagesBarBottom from 'assets/img/advantages_bar_bottom.svg';

import whiteDecoration from 'assets/img/white_decoration.svg';

const makeAdvantagesStyles = makeStyles({
  advantage: {
    alignItems: 'flex-start',
    color: darkSlateBlue,
    display: 'flex',
    fontSize: '1.25rem',
    padding: '40px 0',

    [`@media (max-width: ${breakpointMd}px)`]: {
      fontSize: '1rem',
      padding: 0,
    },

    '& p': {
      marginBlock: '0',
    },

    '& img': {
      marginRight: '15px',
      maxWidth: '77px',
      minWidth: '77px',
      objectFit: 'scale-down',
      objectPosition: 'top',

      [`@media (max-width: ${breakpointMd}px)`]: {
        maxWidth: '40px',
        minWidth: 'initial',
      },
    },
  },
  background: {
    background: `url(${whiteDecoration})`,
    marginTop: '80px',
    paddingBottom: '153px',
  },
  bigCardFamily: {
    alignItems: 'flex-end',
    display: 'flex',
    fontSize: '1.125rem',
    marginTop: '30px',

    '& img': {
      width: '100px',
    },

    '& p': {
      margin: '0 0 0 10px',
    },
  },
  bigCardFamilyLink: {
    color: white,
  },
  explanation: {
    fontSize: '1rem',
  },
  gridItem: {
    '&.MuiGrid-item': {
      [`@media (max-width: ${breakpointMd}px)`]: {
        padding: '12px 24px',
      },
    },
  },
  h3: {
    marginBottom: '60px',
  },
  tilesContainer: {
    width: '100%',
  },
  tile: {
    background: 'gray',
    color: white,
    fontSize: '1.25rem',
    height: '100%',
    lineHeight: '1.5',
    padding: '60px 60px 70px',
    position: 'relative',
    borderRadius: '5px',

    [`@media (max-width: ${breakpointLg}px)`]: {
      padding: '40px 40px 50px',
      fontSize: '1rem',
    },

    [`@media (max-width: ${breakpointMd}px)`]: {
      padding: '20px 20px 50px',
      borderRadius: '0px',
    },

    '& h3': {
      color: white,
      marginBottom: '20px',
    },

    '&::after': {
      [`@media (max-width: ${breakpointMd}px)`]: {
        bottom: 0,
        content: "''",
        display: 'block',
        left: 0,
        position: 'absolute',
        width: '100%',
        backgroundSize: 'contain',
        height: '1rem',
      },
    },

    '&.tile-left': {
      [`@media (max-width: ${breakpointMd}px)`]: {
        maxWidth: '100%',
      },

      backgroundColor: advantageTileLeft,
      maxWidth: '75%',

      '&::after': {
        backgroundImage: `url(${advantagesBarLeft}) `,
      },

      [`@media (min-width: ${breakpointMd}px)`]: {
        '&::before': {
          top: '30px',
          content: "''",
          height: '62px',
          left: '-31px',
          position: 'absolute',
          width: '62px',
          zIndex: 100,
          backgroundImage: `url(${advantagesBarTop}) `,
        },
      },
    },
    '&.tile-middle': {
      backgroundColor: advantageTileMiddle,
      maxWidth: '75%',
      marginLeft: 'auto',

      [`@media (max-width: ${breakpointMd}px)`]: {
        maxWidth: '100%',
        '&::after': {
        backgroundImage: `url(${advantagesBarMiddle}) `,
        },
      },

      [`@media (min-width: ${breakpointMd}px)`]: {
        '&::before': {
          top: '-32px',
          content: "''",
          height: '62px',
          left: 10,
          position: 'absolute',
          width: '224px',
          zIndex: 100,
          backgroundImage: `url(${advantagesBarMiddleTop}) `,
        },
        '&::after': {
          bottom: '-32px',
          content: "''",
          height: '62px',
          left: 10,
          position: 'absolute',
          width: '498px',
          zIndex: 100,
          backgroundImage: `url(${advantagesBarMiddleBottom}) `,
        },
      },
    },
    '&.tile-right': {
      backgroundColor: advantageTileRight,
       margin: '0 140px',

      [`@media (max-width: ${breakpointMd}px)`]: {
        maxWidth: '100%',
        margin: 0,
      },

      [`@media (min-width: ${breakpointMd}px)`]: {
        '&::before': {
          bottom: '-31px',
          content: "''",
          height: '62px',
          left: '35px',
          position: 'absolute',
          width: '62px',
          zIndex: 100,
          backgroundImage: `url(${advantagesBarBottom}) `,
        },
      },

      '&::after': {
        backgroundImage: `url(${advantagesBarRight}) `,
      },
    },
  },
});

export default makeAdvantagesStyles;
