import { makeStyles } from '@material-ui/styles';
import { breakpointMd } from 'styles/common/variables';

const makeTilesStyles = makeStyles({
  card: {
    margin: '0 auto 30px auto',
  },
  cardContent: {
    '&.MuiCardContent-root': {
      display: 'flex',
      padding: 0,

      '&:last-child': {
        paddingBottom: 0,
      },

      '& img': {
        height: '100%',
        width: '100%',
      },
    },
  },
  tile: {
    margin: '0 auto 150px auto',
    maxWidth: '350px',

    [`@media (max-width: ${breakpointMd}px)`]: {
      margin: '0 auto 50px auto',
    },
  },
});

export default makeTilesStyles;
