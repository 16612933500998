import { makeStyles } from '@material-ui/styles';
import { breakpointMd, lightSteelBlue, white, buttonPackageDiscountBackground, packageDiscountSeeMoreButtonHover } from 'styles/common/variables';
import violetDecoration from 'assets/img/white_decoration.svg';

export const makePackageDiscountStyles = makeStyles({
  background: {
    alignItems: 'center',
    background: `url(${violetDecoration}) ${lightSteelBlue}`,
    color: white,
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '100px',
    padding: '26px',
    width: '100%',

    [`@media (max-width: ${breakpointMd}px)`]: {
      padding: '45px 26px',
    },
  },
  description: {
    fontSize: '1.375rem',
    lineHeight: 1.6,
  },
  descriptionWrapper: {
    marginLeft: '60px',

    [`@media (max-width: ${breakpointMd}px)`]: {
      margin: 0,
    },
  },
  h3: {
    color: white,
    fontSize: '2.25rem',
    marginBottom: '30px',
  },
  img: {
    [`@media (max-width: ${breakpointMd}px)`]: {
      display: 'none',
    },
  },
  link: {
    maxWidth: '400px',
    marginTop: '30px',
    borderRadius: '12px',
    background: buttonPackageDiscountBackground,

    '&:hover': {
      background: packageDiscountSeeMoreButtonHover,
    }
  },
});
