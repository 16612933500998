import React, { Fragment } from 'react';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeIntroductionStyles } from 'styles/pages/LargeFamily';
import { texts } from './texts';

const Introduction = () => {
  const { h1, img, paragraph } = makeIntroductionStyles();
  const {
    img: { alt, src },
    paragraphs,
  } = texts;

  return (
    <Fragment>
      <Grid item md={5}>
        <Typography className={h1} dangerouslySetInnerHTML={{ __html: texts.h1 }} variant="h1" />
      </Grid>
      <Grid item md={7}>
        {paragraphs.map(({ id, link, text, Text }) =>
          Text ? (
            <Typography key={id} className={paragraph} component="p">
              <Text />
            </Typography>
          ) : (
            <Typography
              key={id}
              className={paragraph}
              component="p"
              dangerouslySetInnerHTML={{ __html: text }}
            />
          )
        )}
        <img className={img} src={src} alt={alt} />
      </Grid>
    </Fragment>
  );
};

export default Introduction;
