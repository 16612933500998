import MatlandiaImage from 'assets/img/programs/zadania-matematyczne.png';
import { urls } from 'common/urls';

export const Matlandia = (classLevel = '') => ({
  programDescription: {
    classLevels: {
      4: [
        {
          id: 1,
          text: `
            <b><i>Matlandia 4</i></b> to wciągająca <b>gra matematyczna</b>, która została stworzona z&nbsp;myślą, 
            że najskuteczniejsza nauka to <b>nauka poprzez zabawę</b>. <b>Zadania matematyczne</b> w&nbsp;naszej eduGrze mają 
            zróżnicowany poziom trudności, dlatego są idealne zarówno dla uzdolnionego 
            <b>9-latka</b> lub <b>10-latka</b>, jak i&nbsp;takiego, któremu nauka matematyki sprawia trudności. 
            <i>Matlandia 4</i> pomaga dzieciom bezstresowo przećwiczyć zagadnienia, których znajomości wymaga 
            się w&nbsp;<b>4 klasie</b> (m.in. <b>dodawanie i&nbsp;odejmowanie</b>, <b>tabliczka mnożenia</b>, <b>dzielenie</b> – również 
            dzielenie z&nbsp;resztą). Animowane ćwiczenia pozwalają w&nbsp;atrakcyjny sposób utrwalić wiedzę z&nbsp;zakresu 
            <b>kwadratów</b> i&nbsp;<b>sześcianów liczb</b>, <b>kolejności wykonywania działań</b> czy <b>osi liczbowej</b>. 
            Dzięki zmiennym danym liczbowym w&nbsp;zadaniach dzieci mogą sprawdzić, w&nbsp;jakim stopniu opanowały 
            <b>system dziesiątkowy</b> i&nbsp;<b>rzymski</b>, a&nbsp;także umiejętność <b>porównywania liczb</b>. 
            Program oferuje również ćwiczenia odnoszące się do matematyki na co dzień (<b>obliczenia pieniężne</b>, 
            <b>kilogramy</b> i&nbsp;<b>dekagramy</b>, <b>centymetry</b> i&nbsp;<b>milimetry</b>, 
            <b>odczytywanie godzin na zegarze wskazówkowym</b> i&nbsp;<b>elektronicznym</b>, <b>jednostki czasu</b>, 
            <b>zapisywanie dat</b> oraz <b>skala</b> i&nbsp;<b>plan</b>). <i>Matlandia 4</i> zawiera także działy 
            poświęcone geometrii – <b>kąty</b> i&nbsp;<b>mierzenie kątów</b>, <b>wielokąty</b> i&nbsp;<b>okręgi</b>, 
            <b>obwody figur</b>, <b>pola figur</b>, <b>prostopadłościany</b> i&nbsp;<b>sześciany</b> 
            (siatki prostopadłościanów, pole powierzchni prostopadłościanu). W&nbsp;programie znajdują się też 
            zadania matematyczne obejmujące <b>ułamki zwykłe</b> oraz <b>ułamki dziesiętne</b>.
          `,
        },
      ],
      5: [
        {
          id: 1,
          text: `
            <b><i>Matlandia 5</i></b> to wciągająca <b>gra matematyczna</b>, która została stworzona z&nbsp;myślą, 
            że najskuteczniejsza nauka to <b>nauka poprzez zabawę</b>. <b>Zadania matematyczne</b> w&nbsp;naszej eduGrze mają 
            zróżnicowany poziom trudności, dlatego są idealne zarówno dla uzdolnionego 
            <b>10-latka</b> i&nbsp;<b>11-latka</b>, jak i&nbsp;takiego, któremu nauka matematyki sprawia trudności. 
            <i>Matlandia 5</i> pomaga dzieciom bezstresowo przećwiczyć zagadnienia, których znajomości wymaga 
            się w&nbsp;<b>5 klasie</b> (m.in. <b>dodawanie i&nbsp;odejmowanie</b>, <b>tabliczka mnożenia</b>, <b>dzielenie</b> – również 
            dzielenie z&nbsp;resztą). Rozwiązywanie interaktywnych zadań pomaga dziecku łatwo utrwalić wiedzę dotyczącą 
            <b>osi liczbowej</b> czy <b>dzielników liczb</b> (również: największego wspólnego dzielnika). 
            Animowane ćwiczenia pozwalają w&nbsp;atrakcyjny sposób zrobić powtórkę z&nbsp;takich działów, jak: 
            <b>liczby pierwsze</b>, <b>liczby złożone</b> i&nbsp;<b>liczby ujemne</b>. Dzięki zmiennym danym liczbowym 
            w&nbsp;zadaniach dzieci mogą sprawdzić umiejętności z&nbsp;zakresu <b>rozkładu na czynniki pierwsze</b> oraz 
            <b>wielokrotności liczb</b>. Zabawni bohaterowie uprzyjemnią także powtórki z&nbsp;<b>ułamków zwykłych</b> 
            i&nbsp;<b>dziesiętnych</b>. <i>Matlandia 5</i> zawiera również zadania z&nbsp;geometrii, 
            w&nbsp;których można przećwiczyć <b>mierzenie kątów</b>, obliczanie <b>pola figur</b> 
            (pole trójkąta, pole prostokąta, pole trapezu, pole równoległoboku, pole rombu, pole wielokąta), 
            <b>obwodów figur</b>, i&nbsp;udoskonalić znajomość <b>graniastosłupów</b> (siatki graniastosłupów, 
            pole powierzchni graniastosłupa, objętość graniastosłupa).
          `,
        },
      ],
      6: [
        {
          id: 1,
          text: `
            <b><i>Matlandia 6</i></b> to wciągająca <b>gra matematyczna</b>, która została stworzona z&nbsp;myślą, 
            że najskuteczniejsza nauka to <b>nauka poprzez zabawę</b>. <b>Zadania matematyczne</b> w&nbsp;naszej eduGrze mają 
            zróżnicowany poziom trudności, dlatego są idealne zarówno dla uzdolnionego 
            <b>11-latka</b> i&nbsp;<b>12-latka</b>, jak i&nbsp;takiego, któremu nauka matematyki sprawia trudności. 
            <i>Matlandia 6</i> pomaga dzieciom bezstresowo przećwiczyć zagadnienia, których znajomości wymaga 
            się w&nbsp;<b>6 klasie</b> (m.in. <b>dodawanie i&nbsp;odejmowanie</b>, <b>tabliczka mnożenia</b>, czy <b>dzielenie</b>). 
            Świetnie przygotowane zadania uprzyjemnią powtórki z&nbsp;<b>ułamków zwykłych</b>, <b>ułamków dziesiętnych</b>, 
            <b>potęg</b> oraz <b>procentów</b>. Program oferuje także ćwiczenia odnoszące się do matematyki 
            na co dzień – <b>obliczeń pieniężnych</b>, <b>kalendarza</b> i&nbsp;<b>czasu</b> 
            (m.in. lata przestępne; określanie, który to wiek). Pozwala także udoskonalić umiejętności 
            związane z&nbsp;<b>przeliczaniem jednostek długości</b> i&nbsp;<b>masy</b>. W&nbsp;<i>Matlandii 6</i> znajdują się również 
            niezwykle praktyczne zadania ze <b>skali na planie</b> i&nbsp;<b>na mapie</b>. Dzięki zabawnym bohaterom 
            <b>odczytywanie diagramów</b>, <b>odczytywanie wykresów</b> czy zagadnienia związane z&nbsp;<b>prędkością</b> 
            i&nbsp;<b>drogą</b> stają się przyjemną zabawą. <i>Matlandia 6</i> zawiera również zagadnienia z&nbsp;zakresu 
            geometrii: w&nbsp;zadaniach można przećwiczyć <b>mierzenie kątów</b>, obliczanie <b>pól figur</b> 
            (pole trójkąta, pole prostokąta, pole trapezu, pole równoległoboku, pole rombu, pole wielokąta) 
            i&nbsp;udoskonalić znajomość <b>graniastosłupów</b> (siatki graniastosłupów, pole powierzchni graniastosłupa, 
            objętość graniastosłupa), <b>ostrosłupów</b>, <b>walców</b>, <b>stożków</b> i&nbsp;<b>kul</b>. 
            Dzięki zmiennym danym liczbowym w&nbsp;zadaniach dzieci mogą sprawdzić, w&nbsp;jakim stopniu opanowały 
            <b>wyrażenia algebraiczne</b> i&nbsp;<b>równania</b>.
          `,
        },
        {
          id: 2,
          text: `
            Z&nbsp;kolei eduGra <b><i>PowtórkoMat 6</i></b> to <b>interaktywne powtórki z&nbsp;matematyki z&nbsp;zakresu klas 4</b>, 
            <b>5</b> i&nbsp;<b>6 szkoły podstawowej</b>. W&nbsp;pierwszej części <i>PowtórkoMatu 6</i> można rozwiązywać 
            <b>ćwiczenia powtórzeniowe z&nbsp;matematyki</b>, które zawierają wskazówki lub 
            <b>filmy z&nbsp;rozwiązaniami krok po kroku</b>. Druga część to <b>próbne testy</b> sprawdzające poziom wiedzy dzieci.
          `,
        },
      ],
      7: [
        {
          id: 1,
          text: `
            <b><i>Matlandia 7</i></b> to wciągająca <b>gra matematyczna</b>, która została stworzona z&nbsp;myślą, 
            że najskuteczniejsza nauka to <b>nauka poprzez zabawę</b>. <b>Zadania matematyczne</b> w&nbsp;naszej eduGrze mają 
            zróżnicowany poziom trudności, dlatego są idealne zarówno dla uzdolnionego 
            <b>12-latka</b> i&nbsp;<b>13-latka</b>, jak i&nbsp;takiego, któremu nauka matematyki sprawia trudności. 
            <i>Matlandia 7</i> pomaga dzieciom bezstresowo przećwiczyć zagadnienia, których znajomości wymaga 
            się w&nbsp;<b>7 klasie</b> (m.in. <b>dodawanie i&nbsp;odejmowanie</b>, <b>tabliczka mnożenia</b> i&nbsp;<b>dzielenie</b>).
            Komiksowi bohaterowie uprzyjemnią powtórki z&nbsp;<b>liczb wymiernych</b> i&nbsp;<b>liczb ujemnych</b> 
            oraz z&nbsp;zakresu <b>rozwinięcia dziesiętnego liczb</b> i&nbsp;<b>zaokrąglania liczb</b>. 
            Rozwiązując interaktywne zadania, 7-klasiści łatwo utrwalą wiedzę dotyczącą <b>osi liczbowej</b>, 
            <b>ułamków zwykłych</b> i&nbsp;<b>ułamków dziesiętnych</b>. W&nbsp;<i>Matlandii 7</i> znajdują się również praktyczne 
            ćwiczenia z&nbsp;<b>zamiany jednostek czasu</b>, <b>długości</b> i&nbsp;<b>masy</b>. 
            Świetnie przygotowane zadania z&nbsp;<b>wyrażeń arytmetycznych</b>, <b>procentów</b>, <b>potęg</b> 
            i&nbsp;<b>pierwiastków</b> pomogą uzyskać lepsze wyniki w&nbsp;nauce. Nasz program matematyczny online zawiera 
            również zagadnienia z&nbsp;zakresu geometrii: <b>proste</b> i&nbsp;<b>odcinki</b>, <b>kąty</b> 
            i&nbsp;<b>mierzenie kątów</b>, <b>pola figur</b> (pole trójkąta, pole prostokąta, pole trapezu, 
            pole równoległoboku, pole rombu, pole wielokąta), <b>graniastosłupy</b> (siatki graniastosłupów, 
            pole powierzchni graniastosłupa, objętość graniastosłupa), a&nbsp;także zadania związane z&nbsp;<b>układem współrzędnych</b>. 
            Dzięki zmiennym danym liczbowym w&nbsp;zadaniach dzieci mogą sprawdzić, w&nbsp;jakim stopniu opanowały umiejętność 
            obliczania <b>wyrażeń algebraicznych</b>, <b>jednomianów</b>, <b>równań</b> czy <b>przekształcania wzorów</b>.
          `,
        },
      ],
      8: [
        {
          id: 1,
          text: `
            <b><i>Matlandia 8</i></b> to wciągająca <b>gra matematyczna</b>, która została stworzona z&nbsp;myślą, 
            że najskuteczniejsza nauka to <b>nauka poprzez zabawę</b>. <b>Zadania matematyczne</b> w&nbsp;naszej eduGrze mają 
            zróżnicowany poziom trudności, dlatego są idealne zarówno dla uzdolnionego 
            <b>13-latka</b> i&nbsp;<b>14-latka</b>, jak i&nbsp;takiego, któremu nauka matematyki sprawia trudności. 
            <i>Matlandia 8</i> pomaga dzieciom bezstresowo przećwiczyć zagadnienia, których znajomości wymaga 
            się w&nbsp;<b>8 klasie</b> (m.in. <b>dodawanie i&nbsp;odejmowanie</b>, <b>tabliczka mnożenia</b> i&nbsp;<b>dzielenie</b> 
            – również dzielenie z&nbsp;resztą). Komiksowi bohaterowie uprzyjemnią powtórki z&nbsp;<b>rozkładania liczby na czynniki pierwsze</b> 
            czy <b>wspólnego dzielnika liczb</b>. Rozwiązując interaktywne zadania, 8-klasiści łatwo utrwalą wiedzę 
            dotyczącą <b>systemu rzymskiego</b> lub <b>rozwinięć dziesiętnych</b>. Dzięki zmiennym danym liczbowym w&nbsp;zadaniach 
            dzieci sprawdzą, w&nbsp;jakim stopniu opanowały <b>pierwiastki</b>, <b>potęgi</b>, <b>wyrażenia algebraiczne</b> 
            i&nbsp;<b>równania</b>. Świetnie przygotowane zadania z&nbsp;<b>procentów</b> i&nbsp;<b>rachunku prawdopodobieństwa</b> pomogą uzyskać 
            lepsze wyniki w&nbsp;nauce. Nasz program matematyczny online zawiera również działy z&nbsp;zakresu geometrii: 
            <b>proporcje</b>, <b>kąty</b> i&nbsp;<b>obliczanie miar kątów</b>, <b>twierdzenie Pitagorasa</b>. W&nbsp;zadaniach można 
            przećwiczyć obliczanie <b>pola figur</b>, <b>przekątnych figur</b>, odczytywanie <b>układu współrzędnych</b>, 
            a&nbsp;także udoskonalić znajomość <b>graniastosłupów</b> (siatki graniastosłupów, pole powierzchni graniastosłupa, 
            objętość graniastosłupa), <b>ostrosłupów</b> (siatki ostrosłupów, pole powierzchni ostrosłupa, objętość ostrosłupa), 
            <b>symetrii</b>, <b>koła</b> i&nbsp;<b>okręgów</b> (długość okręgu, pole koła).
          `,
        },
        {
          id: 2,
          text: `
            Z&nbsp;kolei eduGra <b><i>PowtórkoMat 8</i></b> to <b>interaktywne powtórki z&nbsp;matematyki</b>. 
            Umożliwia ona kompletne i&nbsp;bezstresowe <b>przygotowanie do egzaminu ósmoklasisty</b>. W&nbsp;pierwszej części 
            <i>PowtórkoMatu 8</i> ósmoklasiści mogą rozwiązywać <b>ćwiczenia powtórzeniowe z&nbsp;matematyki</b>, 
            które zawierają wskazówki lub <b>filmy z&nbsp;rozwiązaniami krok po kroku</b>. 
            Druga część to <b>próbne zestawy zadań</b> przygotowane na wzór prawdziwego egzaminu.
          `,
        },
      ],
    },
    title: `Matematyka dla dzieci – ${classLevel}&nbsp;klasa`,
  },
  programPresentation: {
    classLevels: {
      4: {
        buyLink: 'https://gwo.pl/ksiegarnia/program-komputerowy/215,matlandia-nowa-wersja-klasa-4',
        demoLink: 'https://matlandia4.apps.gwo.pl/0',
      },
      5: {
        buyLink: 'https://gwo.pl/ksiegarnia/program-komputerowy/235,matlandia-klasa-5',
        demoLink: 'https://matlandia5.apps.gwo.pl/0',
      },
      6: {
        buyLink: 'https://gwo.pl/ksiegarnia/program-komputerowy/17,matlandia-klasa-6',
        demoLink: 'https://matlandia6.apps.gwo.pl/0',
      },
      7: {
        buyLink: 'https://gwo.pl/ksiegarnia/program-komputerowy/228,matlandia-klasa-7',
        demoLink: 'https://matlandia7.apps.gwo.pl/0',
      },
      8: {
        buyLink: 'https://gwo.pl/ksiegarnia/program-komputerowy/258,matlandia-klasa-8',
        demoLink: 'https://matlandia8.apps.gwo.pl/app/0',
      },
    },
    common: {
      classNameHeader: 'math',
      seeMoreLink: `/klasa-${classLevel}/${urls.apps.matlandia}-${classLevel}`,
      seeMoreLinkOnSubjectPage: `/${urls.subjects.root}/${urls.subjects.math}/${urls.apps.matlandia}`,
      description: `Program zapewnia szybki rozwój umiejętności matematycznych oraz pomaga ćwiczyć pamięć 
        i&nbsp;logiczne myślenie. Kładzie nacisk na wykorzystanie matematyki w&nbsp;codziennych sytuacjach.`,
      img: {
        alt: `Zadania matematyczne ${
          classLevel ? `klasa ${classLevel}` : ''
        } z&nbsp;animowanymi bohaterami w&nbsp;grze edukacyjnej dla dzieci Matlandia ${
          classLevel ? `klasa ${classLevel}` : ''
        }`,
        src: MatlandiaImage,
      },
      price: '30 zł na rok | bezpłatna przesyłka',
      subject: 'Matematyka',
      subtitle: 'gra edukacyjna online',
      title: `<i>Matlandia ${classLevel}</i>`,
    },
  },
});
