import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeIntroductionStyles } from 'styles/pages/Classes';
import { texts } from './texts';

const Introduction = ({ subject }) => {
  const { h1, h2 } = makeIntroductionStyles();

  return (
    <Fragment>
      <Grid item>
        <Typography
          className={h1}
          dangerouslySetInnerHTML={{ __html: texts.subjects[subject].h1 }}
          variant="h1"
          align="center"
        />
        <Typography
          className={h2}
          dangerouslySetInnerHTML={{ __html: texts.subjects[subject].h2 }}
          variant="h2"
          align="center"
        />
      </Grid>
    </Fragment>
  );
};

Introduction.propTypes = {
  subject: PropTypes.string.isRequired,
};

export default Introduction;
