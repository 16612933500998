import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { Grid, Typography } from '@material-ui/core';
import { makeAdvantagesStyles } from 'styles/pages/Home';
import { texts } from './texts';
import { urls } from 'common/urls';

const renderAdvantageBox = ({ id, img, text }) => {
  const { advantage, gridItem } = makeAdvantagesStyles();

  return (
    <Grid key={id} item className={gridItem} xs={12} sm={6} lg={4}>
      <Box className={advantage}>
        <img src={img} alt="" />
        <p dangerouslySetInnerHTML={{ __html: text }} />
      </Box>
    </Grid>
  );
};

const renderAdvantageTile = ({ id, additionalText, className, header, text, img }) => {
  const { bigCardFamily, bigCardFamilyLink, explanation, tile } = makeAdvantagesStyles();

  return (
    <Grid key={id} item xs={12} md={12}>
      <Box className={`${tile} ${className}`}>
        <Typography variant="h1" component="h3" dangerouslySetInnerHTML={{ __html: header }} />
        <p dangerouslySetInnerHTML={{ __html: text }} />
        {additionalText && !img ? (
          <p className={explanation} dangerouslySetInnerHTML={{ __html: additionalText }} />
        ) : null}
        {img ? (
          <Box className={bigCardFamily}>
            <img src={img.src} alt={img.alt} />
            <p>
              <b>
                Dodatkowo honorujemy{' '}
                <NavLink className={bigCardFamilyLink} to={urls.largeFamily.root}>
                  Kartę Dużej Rodziny
                </NavLink>
                !
              </b>
            </p>
          </Box>
        ) : null}
      </Box>
    </Grid>
  );
};

const Advantages = () => {
  const { background, h3, tilesContainer } = makeAdvantagesStyles();

  return (
    <Fragment>
      <Container>
        <Grid container justify="center">
          <Grid item xs={11}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h1" component="h3" className={h3}>
                  {texts.advantages.header}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={6}>
              {texts.advantages.blocks.map(renderAdvantageBox)}
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <div className={background}>
        <Container>
          <Grid container justify="center">
            <Grid item xs={12}>
              <Grid container direction="column" className={tilesContainer}>{texts.advantages.tiles.map(renderAdvantageTile)}</Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
    </Fragment>
  );
};

export default Advantages;
