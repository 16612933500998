import ufoImage from 'assets/img/ufo.svg';

export const texts = {
  img: {
    alt: '',
    src: ufoImage,
  },
  text: 'Strona, której szukasz, nie istnieje.',
  title: 'Błąd 404',
};
