import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeShortDescriptionStyles } from 'styles/pages/Application';
import { texts } from './texts';

const ShortDescription = ({ application }) => {
  const { description } = makeShortDescriptionStyles();

  return (
    <Fragment>
      <Grid container>
        <Grid>
          <Typography
            className={description}
            dangerouslySetInnerHTML={{ __html: texts.apps[application].description }}
            component="p"
            align="left"
          />
        </Grid>
        <Box display={{ xs: 'none', sm: 'block' }}>
          <Grid item xs={2}>
            {/*<img src={texts.apps[application].img} className={image} />*/}
          </Grid>
        </Box>

      </Grid>
    </Fragment>
  );
};

ShortDescription.propTypes = {
  application: PropTypes.string.isRequired,
};

export default ShortDescription;
