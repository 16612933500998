import React, { Fragment, useState } from 'react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Snackbar from 'components/Snackbar';
import { makeButtonStyles } from 'styles/Button';
import { makeFormStyles } from 'styles/pages/Contact';
import { texts } from './texts';

const ContactSchema = Yup.object().shape({
  [texts.form.fields.names.dataProcessing]: Yup.bool().oneOf([true], texts.form.errors.checked),
  [texts.form.fields.names.email]: Yup.string()
    .email(texts.form.errors.emailInvalid)
    .required(texts.form.errors.required),
  [texts.form.fields.names.emailMarketing]: Yup.bool().oneOf([true], texts.form.errors.checked),
  [texts.form.fields.names.contactName]: Yup.string().required(texts.form.errors.required),
  [texts.form.fields.names.message]: Yup.string().required(texts.form.errors.required),
});

const CustomCheckboxComponent = ({ field, form: { touched, errors }, ...props }) => (
  <Fragment>
    <FormControlLabel
      className={props.labelClassName}
      control={
        <Checkbox className={props.checkboxClassName} name={field.name} onChange={field.onChange} />
      }
      label={props.label}
      checked={props.value}
    />
    {touched[field.name] && errors[field.name] && <div className="error">{errors[field.name]}</div>}
  </Fragment>
);

const CustomInputComponent = ({ field, form: { touched, errors }, ...props }) => (
  <Fragment>
    <TextField
      className={props.fieldClassName}
      InputLabelProps={{ className: props.inputLabel }}
      InputProps={{ className: props.input }}
      label={props.label}
      margin="normal"
      onChange={field.onChange}
      name={field.name}
      type={props.type}
      variant="outlined"
      value={props.value}
      multiline={props.multiline}
      rows={props.rows}
    />
    {touched[field.name] && errors[field.name] && <div className="error">{errors[field.name]}</div>}
  </Fragment>
);

const Form = () => {
  const [open, setOpen] = useState(false);
  const { buttonPrimary } = makeButtonStyles();
  const {
    checkbox,
    form,
    field,
    fieldRequired,
    input,
    inputLabel,
    label,
    likeUsOnFacebook,
    writeUs,
  } = makeFormStyles();

  const {
    form: { fields, send },
  } = texts;

  const initialValues = {
    [fields.names.contactName]: '',
    [fields.names.dataProcessing]: false,
    [fields.names.email]: '',
    [fields.names.emailMarketing]: false,
    [fields.names.message]: '',
    [fields.names.phoneNumber]: '',
  };

  const handleSubmit = (values, resetForm) => {
    const formData = new FormData();

    const { contact_name, data_processing, email, email_marketing, message, phone_number } = values;

    formData.set([fields.names.contactName], contact_name);
    formData.set([fields.names.dataProcessing], data_processing);
    formData.set([fields.names.email], email);
    formData.set([fields.names.emailMarketing], email_marketing);
    formData.set([fields.names.message], message);
    phone_number && formData.set([fields.names.phoneNumber], phone_number);

    fetch('/sendMail.php', { method: 'POST', body: formData }).then(data => {
      resetForm();
      setOpen(true);
    });
  };

  return (
    <Grid item lg={10}>
      <Typography
        className={writeUs}
        component="p"
        dangerouslySetInnerHTML={{ __html: texts.form.writeUs }}
      />
      <Snackbar message={texts.form.sentSuccessfully} open={open} setOpen={setOpen} />
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { resetForm }) => handleSubmit(values, resetForm)}
        validationSchema={ContactSchema}
        render={({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit} className={form}>
            <Field
              name={fields.names.contactName}
              component={CustomInputComponent}
              fieldClassName={field}
              input={input}
              inputLabel={inputLabel}
              label={fields.labels.contactName}
              type="text"
              value={values.contact_name}
            />
            <Field
              name={fields.names.email}
              component={CustomInputComponent}
              fieldClassName={field}
              input={input}
              inputLabel={inputLabel}
              label={fields.labels.email}
              type="email"
              value={values.email}
            />
            <Field
              name={fields.names.phoneNumber}
              component={CustomInputComponent}
              fieldClassName={field}
              fields={fields}
              input={input}
              inputLabel={inputLabel}
              label={fields.labels.phoneNumber}
              type="tel"
              value={values.phone_number}
            />
            <Field
              name={fields.names.message}
              component={CustomInputComponent}
              fieldClassName={field}
              fields={fields}
              input={input}
              inputLabel={inputLabel}
              label={fields.labels.text}
              type="text"
              multiline
              rows="4"
              value={values.message}
            />
            <Field
              name={fields.names.dataProcessing}
              component={CustomCheckboxComponent}
              checkboxClassName={checkbox}
              input={input}
              inputLabel={inputLabel}
              label={fields.labels.dataProcessing}
              labelClassName={label}
              value={values.data_processing}
            />
            <Field
              name={fields.names.emailMarketing}
              component={CustomCheckboxComponent}
              checkboxClassName={checkbox}
              input={input}
              inputLabel={inputLabel}
              label={fields.labels.emailMarketing}
              labelClassName={label}
              value={values.email_marketing}
            />
            <Typography
              className={fieldRequired}
              component="p"
              dangerouslySetInnerHTML={{ __html: texts.form.fieldRequired }}
            />
            <button className={`${buttonPrimary} contact`} type="submit">
              {send}
            </button>
          </form>
        )}
      />
      <a
        className={likeUsOnFacebook}
        href="https://www.facebook.com/eduGryGWO/"
        rel="noopener noreferrer"
        target="_blank"
      >
      </a>
    </Grid>
  );
};

export default Form;
