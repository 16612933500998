import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Wrapper from 'components/Wrapper';
import Loader from 'components/Loader';
import { makeMainImageStyles } from 'styles/MainImage';
import { getUrlFragment } from 'common/getUrlFragment';
import { texts } from './texts';

const MainImage = ({ location }) => {
  const [loading, setLoading] = useState(true);
  const [loadingImage, setLoadingImage] = useState('');
  const { img, mainImage, mainImageTitle, panel, title: titleClassName } = makeMainImageStyles();
  const pathPrimary = getUrlFragment(location, 1);
  const pathSecondary = getUrlFragment(location, 2);
  let path = pathPrimary;

  if ((pathPrimary === 'porady-dla-rodzicow' || pathPrimary === 'przedmioty') && pathSecondary) {
    path = pathSecondary;
  }

  const imageData = texts[path] || texts.noMatches;

  if (imageData) {
    imageData.img.src !== loadingImage && setLoadingImage(imageData.img.src);
  }

  useEffect(() => {
    setLoading(true);
  }, [loadingImage]);

  const handleLoad = () => setLoading(false);

  return imageData ? (
    <Wrapper>
      <div className={mainImage}>
        <Loader className={`main-image ${loading ? '' : 'loaded'}`} />
        <img className={img} src={imageData.img.src} onLoad={handleLoad} alt={imageData.img.alt} />
        <div className={panel}>
          <h2
            className={`${titleClassName} ${mainImageTitle} ${imageData.className ? imageData.className : ''}`}
            dangerouslySetInnerHTML={{ __html: imageData.title }}
          />
        </div>
      </div>
    </Wrapper>
  ) : null;
};

MainImage.propTypes = {
  location: PropTypes.object.isRequired,
};

export default MainImage;
