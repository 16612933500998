import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeButtonStyles } from 'styles/Button';
import { makePackageDiscountStyles } from 'styles/PackageDiscount';
import heroesClass from 'assets/img/heroes_class.svg';
import { texts } from './texts';

const PackageDiscount = () => {
  const { buttonPrimary } = makeButtonStyles();
  const {
    background,
    description,
    descriptionWrapper,
    h3,
    img,
    link,
  } = makePackageDiscountStyles();

  return (
    <Box className={background}>
      <img src={heroesClass} className={img} alt="Bohaterowie" />
      <Box className={descriptionWrapper}>
        <Typography className={h3} component="h3" variant="h1">
          {texts.title}
        </Typography>
        <Typography
          className={description}
          component="p"
          dangerouslySetInnerHTML={{ __html: texts.description }}
        ></Typography>
        <a
          className={`${buttonPrimary} ${link}`}
          href={texts.bookshopLink}
          rel="noopener noreferrer"
          target="_blank"
        >
          Zobacz
        </a>
      </Box>
    </Box>
  );
};

export default PackageDiscount;
