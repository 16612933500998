import React from 'react';
import { Link, } from "react-router-dom";
import arrowBackIcon from "assets/img/strzalka.svg";
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { makeArrowBackButton } from 'styles/ArrowBackButton';

let historyBack = (event) => {
  const referrerOrigin = document.referrer ? (new URL(document.referrer)).origin : null;
  const actualOrigin = window.location.origin;

  if (referrerOrigin !== actualOrigin  && referrerOrigin !== null) return;

  event.preventDefault();
  window.history.back();
}

const ArrowBackButton = () => {
  const { arrowBack } = makeArrowBackButton();
  return (
    <Container>
      <Link to={''} onClick={historyBack} className="btn btn-primary" style={{ position: 'relative' }}>
        <Box style={{ position: 'absolute', top: '90px', left: '0px' }} className={arrowBack}>
          <img alt="back" src={arrowBackIcon} />
        </Box>
      </Link>
    </Container>
  );
};

export default ArrowBackButton;
