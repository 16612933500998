import React from 'react';
import { Advantages, ChooseClass, } from 'pages/Home';

const Home = () => (
  <div>
    <ChooseClass />
    <Advantages />
  </div>
);

export default Home;
