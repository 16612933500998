import { makeStyles } from '@material-ui/styles';
import {
  breakpointLg,
  breakpointMd,
  breakpointSm,
  chooseClassBar,
  darkSlateBlue,
  tileBackgroundHover,
  transition,
  white,
} from 'styles/common/variables';
import chooseClass1Decoration from 'assets/img/choose_class_1_decoration.svg';
import chooseClass3Decoration from 'assets/img/choose_class_3_decoration.svg';
import chooseClass7Decoration from 'assets/img/choose_class_7_decoration.svg';
import chooseClass8Decoration from 'assets/img/choose_class_8_decoration.svg';
import violetDecoration from 'assets/img/violet_decoration.svg';

const before = {
  content: "''",
  backgroundRepeat: 'no-repeat',
  display: 'block',
  position: 'absolute',
};

const makeChooseClassStyles = makeStyles({
  background: {
    background: `url(${violetDecoration}) ${chooseClassBar}`,
    minHeight: '310px',
    margin: '-5px 0 50px 0',
    paddingBottom: '50px',
  },
  classTile: {
    alignItems: 'center',
    background: white,
    boxShadow: '4px 4px 8px #444444',
    color: darkSlateBlue,
    display: 'flex',
    flexBasis: '100px',
    flexDirection: 'column',
    fontSize: '1.5rem',
    justifyContent: 'center',
    margin: '10px',
    padding: '7px 0',
    position: 'relative',
    textDecoration: 'none',
    transition: `all ${transition}`,
    width: '100px',
    borderRadius: '10px',

    '&:empty': {
      height: 0,
    },

    '&:hover': {
      background: tileBackgroundHover,
      color: white,
      cursor: 'pointer',
    },

    '& .number': {
      fontSize: '3rem',
      lineHeight: '1.1',
      textAlign: 'center',
    },

    [`@media (max-width: ${breakpointLg}px)`]: {
      margin: '5px',
      maxWidth: '85px',
      padding: '0',
    },

    [`@media (max-width: ${breakpointMd}px)`]: {
      margin: 0,
    },

    [`@media (max-width: ${breakpointSm}px)`]: {
      margin: '8px',
      maxWidth: '100px',
      padding: '7px 0',
    },
  },
  classTileWrapper: {
    position: 'relative',

    '&.class-1': {
      '&::before': {
        ...before,
        background: `url(${chooseClass1Decoration})`,
        height: '67px',
        left: '-25px',
        top: '-25px',
        width: '67px',

        [`@media (max-width: ${breakpointSm}px)`]: {
          content: 'none',
        },
      },
    },

    '&.class-3': {
      '&::before': {
        ...before,
        background: `url(${chooseClass3Decoration})`,
        bottom: '-40px',
        height: '75px',
        right: '-30px',
        width: '75px',

        [`@media (max-width: ${breakpointSm}px)`]: {
          content: 'none',
        },
      },
    },

    '&.class-7': {
      '&::before': {
        ...before,
        background: `url(${chooseClass7Decoration})`,
        bottom: '-40px',
        height: '73px',
        right: '-30px',
        width: '73px',

        [`@media (max-width: ${breakpointSm}px)`]: {
          content: 'none',
        },
      },
    },

    '&.class-8': {
      '&::before': {
        ...before,
        background: `url(${chooseClass8Decoration})`,
        height: '47px',
        top: '-12px',
        right: '-12px',
        width: '47px',

        [`@media (max-width: ${breakpointSm}px)`]: {
          content: 'none',
        },
      },
    },
  },
  gridContainer: {
    [`@media (max-width: ${breakpointMd}px)`]: {
      '& .item': {
        padding: '8px',
      },
    },
  },
  h3: {
    color: white,
    fontSize: '1.75rem',
    marginBottom: '50px',

    [`@media (max-width: ${breakpointSm}px)`]: {
      textAlign: 'center',
      fontSize: '1.5rem',
    },
  },
  wrapper: {
    [`@media (max-width: ${breakpointSm}px)`]: {
      justifyContent: 'center',
      margin: '-8px',
    },
  },
});

export default makeChooseClassStyles;
