import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeButtonStyles } from 'styles/Button';
import { makePresentationTileStyles } from 'styles/PresentationTile';
import { Link } from "react-router-dom";
import { urls } from 'common/urls';

const PresentationTile = ({
  seeMoreLink,
  seeMoreLinkOnSubjectPage,
  description,
  img: { alt, src } = {},
  subtitle,
  subtitleExtension,
  title,
}) => {
  const { buttonPrimary } = makeButtonStyles();
  const {
    card,
    cardContent,
    descriptionParagraph,
    gridContainer,
    h3,
    h4,
    img,
    link,
    linksWrapper,
    subtitleExtensionParagraph,
    productTileLink,
  } = makePresentationTileStyles();

  if(window.location.href.split('/')[3] === urls.subjects.root) {
    seeMoreLink = seeMoreLinkOnSubjectPage;
  }

  return (
    <Grid className={gridContainer} item xs={12} sm={6} md={4}>
      <Link className={`${productTileLink}`} to={seeMoreLink}>
        <Card className={card}>
          <img alt={alt} className={img} src={src} />
          <CardContent className={`${cardContent} `}>
            <Typography className={h3} component="h3" align="center" dangerouslySetInnerHTML={{ __html: title }} />
            <Typography
              className={h4}
              component="h4"
              align="center"
              dangerouslySetInnerHTML={{
                __html: subtitle,
              }}
            />
            {subtitleExtension ? (
              <Typography
                className={subtitleExtensionParagraph}
                component="p"
                align="center"
                dangerouslySetInnerHTML={{
                  __html: subtitleExtension,
                }}
              />
            ) : null}
            <Typography
              className={descriptionParagraph}
              component="p"
              align="center"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </CardContent>
        </Card>
      </Link>  
        <Box className={linksWrapper}>
          <Link
            className={`${buttonPrimary} ${link}`}
            to={seeMoreLink}
          >
            Zobacz więcej
          </Link>
        </Box>
    </Grid>
  );
};

PresentationTile.propTypes = {
  classNameHeader: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  img: PropTypes.shape({
    alt: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
  }),
  price: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  subtitleExtension: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default PresentationTile;
