import React, { Fragment } from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { Grid } from '@material-ui/core';
import { makeAdvantagesStyles } from 'styles/pages/Application';

const renderAdvantageBox = ({ id, img, text }) => {
  const { advantage, gridItem } = makeAdvantagesStyles();

  return (
    <Grid key={id} item className={gridItem} xs={12} sm={6} lg={4}>
      <Box className={advantage}>
        <img src={img} alt="" />
        <p dangerouslySetInnerHTML={{ __html: text }} />
      </Box>
    </Grid>
  );
};

const Advantages = ({advantages}) => {
  return (
    <Fragment>
      <Container>
        <Grid container spacing={6} style={{ marginTop: '24px', marginBottom: '24px' }}>
          {advantages.map(renderAdvantageBox)}
        </Grid>
      </Container>
    </Fragment>
  );
};

export default Advantages;
