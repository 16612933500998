import React from 'react';
import { NavLink } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeButtonStyles } from 'styles/Button';
import { makeTilesStyles } from 'styles/pages/Advices';
import { texts } from './texts';

const renderTile = ({ article: { description, title }, id, img: { alt, src }, link }) => {
  const { button } = makeButtonStyles();
  const { articleTitle, card, cardContent, contentWrapper, tile } = makeTilesStyles();

  return (
    <Grid key={id} item xs={12} sm={6} md={4}>
      <Box className={tile}>
        <Card className={card}>
          <CardContent className={cardContent}>
            <NavLink to={link}>
              <img src={src} alt={alt} />
            </NavLink>
            <div className={contentWrapper}>
              <Typography
                component="h3"
                className={articleTitle}
                dangerouslySetInnerHTML={{ __html: title }}
              ></Typography>
              <Typography
                component="p"
                dangerouslySetInnerHTML={{ __html: description }}
              ></Typography>
            </div>
          </CardContent>
        </Card>
        <NavLink className={`${button} full-width`} to={link}>
          <span>Przeczytaj</span>
        </NavLink>
      </Box>
    </Grid>
  );
};

const Tiles = () => {
  const { gridContainer } = makeTilesStyles();

  return (
    <Grid className={gridContainer} container spacing={6}>
      {texts.tiles.map(renderTile)}
    </Grid>
  );
};

export default Tiles;
