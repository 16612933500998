import { makeStyles } from '@material-ui/styles';
import { breakpointMd, darkSlateBlue } from 'styles/common/variables';

const makeTilesStyles = makeStyles({
  articleTitle: {
    fontSize: '1.25rem',
    fontWeight: 600,
    marginBottom: '30px',
  },
  card: {
    height: '100%',
    margin: '0 auto 30px auto',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,

    '&:last-child': {
      paddingBottom: 0,
    },

    '& img': {
      height: '100%',
      width: '100%',
    },
  },
  contentWrapper: {
    color: darkSlateBlue,
    padding: '30px',
  },
  gridContainer: {
    marginBottom: '100px',
  },
  tile: {
    height: '100%',
    margin: '0 auto 100px auto',
    maxWidth: '350px',
    maxHeight: '500px',

    [`@media (max-width: ${breakpointMd}px)`]: {
      margin: '0 auto 75px auto',
    },
  },
});

export default makeTilesStyles;
