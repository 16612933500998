import React, { Fragment } from 'react';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeIntroductionStyles } from 'styles/pages/Download';
import { texts } from './texts';

const Introduction = () => {
  const { h1, h2 } = makeIntroductionStyles();

  return (
    <Fragment>
      <Grid item lg={5}>
        <Typography
          className={h1}
          dangerouslySetInnerHTML={{ __html: texts.introduction.h1 }}
          variant="h1"
        />
      </Grid>
      <Grid item lg={7}>
        <Typography
          className={h2}
          dangerouslySetInnerHTML={{ __html: texts.introduction.h2 }}
          variant="h2"
        />
      </Grid>
    </Fragment>
  );
};

export default Introduction;
