import { makeStyles } from '@material-ui/styles';
import { breakpointMd, darkSlateBlue } from 'styles/common/variables';

const makeIntroductionStyles = makeStyles({
  childrenQuantity: {
    color: darkSlateBlue,
    fontWeight: 800,
    fontSize: '1.25rem',
    marginTop: 0,
  },
  childrenQuantityWrapper: {
    // marginLeft: '200px',
  },
  h1: {
    marginTop: '65px',
  },
  h2: {
    color: darkSlateBlue,
    fontSize: '1.125rem',
    fontWeight: 300,
    lineHeight: '1.8',
    paddingRight: '40px',
    marginTop: '25px',

    [`@media (max-width: ${breakpointMd}px)`]: {
      paddingRight: 0,
    },

    '& b': {
      fontWeight: 800,
    },
  },
  heroes: {
    maxWidth: '100%',
  },
});

export default makeIntroductionStyles;
