export const WaysOfMotivatingChild = {
  articleTitle: `3 skuteczne sposoby na zmotywowanie dziecka do nauki`,
  introduction: [
    {
      id: 1,
      text: `Każdemu rodzicowi zależy na tym, aby jego dziecko uczyło się jak najlepiej 
    (i&nbsp;to bez konieczności ciągłego przypominania o&nbsp;odrabianiu lekcji!). Podpowiadamy, jak mądrze 
    zmotywować pociechę do działania i&nbsp;pomóc jej odnosić sukcesy.`,
    },
  ],
  sections: [
    {
      id: 1,
      paragraphs: [
        {
          id: 1,
          text: `Dzieci chętnie angażują się w&nbsp;naukę rzeczy, które je interesują. 
            Zwróć uwagę na to, co budzi w&nbsp;dziecku radość i&nbsp;wspieraj je w rozwoju pasji.`,
        },
        {
          id: 2,
          text: `Dziecko uwielbia matematykę? A&nbsp;może jego zainteresowania nie są bezpośrednio związane 
            z&nbsp;przedmiotami szkolnymi? Twoja pociecha przepada za zwierzętami lub roślinami? Zna wszystkie 
            gatunki dinozaurów? A&nbsp;może uwielbia gry komputerowe lub jazdę na rowerze? `,
        },
        {
          id: 3,
          text: `Wspieraj rozwój pasji dziecka, poszerzając doświadczenia szkolne. Zachęć córkę lub syna 
            do lektury książek o&nbsp;danej tematyce i&nbsp;do wyszukiwania ciekawostek, np. na temat życia naukowców 
            z&nbsp;danej dziedziny. Odwiedźcie razem wystawę naukową lub muzeum.`,
        },
        {
          id: 4,
          text: `Edukacyjne gry komputerowe to również świetny pomysł na pogłębienie wiedzy dziecka!`,
        },
      ],
      sectionTitle: `1. Pomóż dziecku odkryć jego pasje`,
    },
    {
      id: 2,
      paragraphs: [
        {
          id: 1,
          text: `Tak jak każdy z&nbsp;nas, dzieci chętniej coś robią, gdy mogą podejmować samodzielne decyzje. 
            Kiedy dziecko czuje, że nie ma żadnego wpływu na to, czego się uczy, i&nbsp;że wszystko jest 
            mu narzucane odgórnie, może się łatwo zniechęcać.`,
        },
        {
          id: 2,
          text: `Pozwól, aby Twoja pociecha sama zdecydowała, w&nbsp;jakiej kolejności woli odrobić pracę domową 
            z&nbsp;matematyki i&nbsp;z&nbsp;historii. Daj córce lub synowi szansę na samodzielne wybranie tematu wypracowania 
            czy rodzaju zajęć pozalekcyjnych.`,
        },
        {
          id: 3,
          text: `Przedstawienie dziecku alternatywy i&nbsp;pozwolenie na podjęcie własnej decyzji daje mu poczucie 
          sprawczości i&nbsp;kontroli nad tym, co się z nim dzieje. W&nbsp;efekcie u&nbsp;dziecka pojawiają się poczucie 
          odpowiedzialności oraz motywacja do działania.`,
        },
      ],
      sectionTitle: `2. Daj dziecku wybór`,
    },
    {
      id: 3,
      paragraphs: [
        {
          id: 1,
          text: `Dzieci podświadomie chcą zadowolić swoich rodziców. 
            Bardzo ważne jest dla nich poczucie, że rodzice są po ich stronie. `,
        },
        {
          id: 2,
          text: `Nawet gdy efekt starań Twojego dziecka nie do końca Cię zadowala, 
            doceń wysiłek swojej pociechy oraz pokaż jej, czego się dzięki danemu doświadczeniu 
            nauczyła. Czując Twoje wsparcie, dziecko nie będzie panicznie się obawiało 
            porażki i&nbsp;chętniej zacznie podejmować nowe wyzwania.`,
        },
        {
          id: 3,
          text: `Zachęcaj też dziecko do rozmowy – wykazuj zainteresowanie jego codziennymi 
            problemami i&nbsp;sukcesami. Podpytuj o szczegóły tego, co się działo danego dnia. `,
        },
        {
          id: 4,
          text: `Daj synowi lub córce możliwość wyrażania własnych opinii, 
            nawet jeśli się z&nbsp;nimi nie zgadzasz. Dzieci chętniej się uczą, gdy czują, 
            że ich zdanie się liczy i&nbsp;gdy mogą opowiadać o swoich doświadczeniach bez obaw o&nbsp;to, 
            że zostaną zignorowane lub pochopnie ocenione.`,
        },
      ],
      sectionTitle: `3. Okaż wsparcie`,
    },
  ],
};
