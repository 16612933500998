import WladcySlowImage from 'assets/img/programs/gry-ortograficzne-dla-dzieci.png';
import {urls} from "common/urls";

export const WladcySlow = classLevel => ({
  programDescription: {
    paragraphs: [
      {
        id: 1,
        text: `EduGra <b><i>Władcy Słów</i></b> to animowane <b>ćwiczenia z&nbsp;języka polskiego dla dzieci</b> w&nbsp;wieku 
        <b>9 lat</b>, <b>10 lat</b>, <b>11 lat</b> oraz <b>12 lat</b>. We <i>Władcach Słów</i> dzieci znajdą wciągające 
        <b>gry ortograficzne</b> oraz <b>zabawy ortograficzne</b> (również: <b>dyktanda online</b>). 
        Jest też mnóstwo zadań pomagających przećwiczyć zagadnienia, których znajomości wymaga się w&nbsp;
        <b>4 klasie</b>, <b>5 klasie</b> i&nbsp;<b>6 klasie szkoły podstawowej</b>. Nasze ćwiczenia online obejmują 
        takie tematy, jak: <b>odmiana rzeczownika</b> (również: <b>odmiana przez przypadki</b>), 
        <b>odmiana przymiotnika</b> oraz <b>odmiana czasownika</b>. Rozwiązując animowane zadania, dziecko sprawdzi 
        swoją znajomość oraz umiejętność poprawnego zastosowania <b>liczebników</b>, <b>zaimków rzeczownych</b>, 
        <b>przymiotnych</b>, <b>przysłownych</b> oraz <b>liczebnych</b>, <b>przyimków</b> i&nbsp;<b>wyrażeń przyimkowych</b>, 
        <b>przysłówków</b>. Dzięki interaktywnym zadaniom oraz ciekawym bohaterom gry <b><i>Władcy Słów</i></b> 
        łatwo utrwali materiał z&nbsp;zakresu użycia <b>spójników</b>, <b>wykrzyknika i&nbsp;partykuły</b>. 
        Program w&nbsp;ciekawy sposób prezentuje również ćwiczenia z <b>typów wypowiedzeń</b>, 
        <b>zdań pojedynczych</b> i&nbsp;<b>zdań złożonych</b> oraz <b>rozbioru zdania</b>. Z&nbsp;grą <i>Władcy Słów</i> 
        dziecko powtórzy sobie materiał z&nbsp;zakresu <b>rodziny wyrazów</b>, <b>wyrazów pokrewnych</b>, <b>synonimów</b>, 
        <b>antonimów</b> i&nbsp;<b>homonimów</b> oraz <b>frazeologizmów</b>. <i>Władcy Słów</i> pomagają też poćwiczyć 
        <b>czytanie ze zrozumieniem</b> oraz pisanie różnych tekstów.`,
      },
    ],
    title: `Zabawy słowne i ortograficzne dla dzieci – ${classLevel}&nbsp;klasa`,
  },
  programPresentation: {
    seeMoreLink: `/${urls.subjects.root}/${urls.subjects.polish}/${urls.apps.wladcySlow}`,
    seeMoreLinkOnSubjectPage: `/${urls.subjects.root}/${urls.subjects.polish}/${urls.apps.wladcySlow}`,
    buyLink: 'https://gwo.pl/ksiegarnia/program-komputerowy/203,wladcy-slow',
    classNameHeader: 'polish',
    demoLink: 'https://wladcyslow.apps.gwo.pl/0',
    description: `Program poprzez zabawę pomaga dzieciom w&nbsp;powtórce zasad ortografii i&nbsp;gramatyki, 
      a&nbsp;także rozwija umiejętność czytania ze zrozumieniem oraz pisania.`,
    img: {
      alt:
        'Gry ortograficzne i dyktanda online z animowanymi postaciami z gry edukacyjnej dla dzieci Władcy Słów',
      src: WladcySlowImage,
    },
    price: '30 zł na rok | bezpłatna przesyłka',
    subject: 'Język polski',
    subtitle: 'gra edukacyjna online',
    title: '<i>Władcy Słów</i>',
  },
});
