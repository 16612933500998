import { urls } from 'common/urls';

export const meta = {
  default: {
    
    description:
      'Poznaj sprawdzone gry edukacyjne online z matematyki, j. polskiego oraz informatyki dla dzieci z klas 1–8. Zobacz, jak wygląda nauka poprzez zabawę!',
    title: 'eduGry – najlepsze gry edukacyjne online dla dzieci z klas 1–8',
  },
  [urls.articles.root]: {
    description:
      'Przeczytaj inspirujące porady dla rodziców związane z edukacją dziecka oraz zobacz ciekawe pomysły na spędzanie czasu z dzieckiem.',
    title: 'eduGry – porady dla rodziców i pomysły na spędzanie czasu z dzieckiem',
  },
  [`${urls.schoolClass}-1`]: {
    description:
      'Odkryj sprawdzone gry edukacyjne (matematyka oraz programowanie) dla dzieci w wieku 6 i 7 lat, 1 klasa. Zobacz, jak wygląda nauka poprzez zabawę!',
    title: 'eduGry – najlepsze gry edukacyjne online dla 6-latka i 7-latka',
  },
  [`${urls.schoolClass}-2`]: {
    description:
      'Odkryj sprawdzone gry edukacyjne (matematyka oraz programowanie) dla dzieci w wieku 7 i 8 lat, 2 klasa. Zobacz, jak wygląda nauka poprzez zabawę!',
    title: 'eduGry – najlepsze gry edukacyjne online dla 7-latka i 8-latka',
  },
  [`${urls.schoolClass}-3`]: {
    description:
      'Odkryj sprawdzone gry edukacyjne (matematyka oraz programowanie) dla dzieci w wieku 8 i 9 lat, 3 klasa. Zobacz, jak wygląda nauka poprzez zabawę!',
    title: 'eduGry – najlepsze gry edukacyjne online dla 8-latka i 9-latka',
  },
  [`${urls.schoolClass}-4`]: {
    description:
      'Odkryj sprawdzone gry edukacyjne z matematyki i j. polskiego dla 9-latka i 10-latka (4 klasa). Zobacz, jak wygląda nauka poprzez zabawę!',
    title: 'eduGry – najlepsze gry edukacyjne online dla 9-latka i 10-latka',
  },
  [`${urls.schoolClass}-5`]: {
    description:
      'Odkryj gry edukacyjne dla dziecka, które pomogą uzyskać super wyniki z matematyki i j. polskiego (5 klasa). Zobacz, jak wygląda nauka poprzez zabawę!',
    title: 'eduGry – najlepsze gry edukacyjne online dla 10-latka i 11-latka',
  },
  [`${urls.schoolClass}-6`]: {
    description:
      'Odkryj sprawdzone i bezpieczne gry edukacyjne online dla dziecka, które pomogą uzyskać super wyniki z matematyki i j. polskiego (6 klasa)!',
    title: 'eduGry – najlepsze gry edukacyjne online dla dzieci (6 klasa)',
  },
  [`${urls.schoolClass}-7`]: {
    description:
      'Odkryj sprawdzone gry edukacyjne online dla dziecka, które pomogą uzyskać lepsze wyniki z matematyki (7 klasa)!',
    title: 'eduGry – najlepsze gry edukacyjne online dla dzieci (7 klasa)',
  },
  [`${urls.schoolClass}-8`]: {
    description:
      'Odkryj sprawdzone gry edukacyjne online dla dziecka, które pomogą uzyskać lepsze wyniki z matematyki (8 klasa) i zdać egzamin ósmoklasisty.',
    title: 'eduGry – najlepsze gry edukacyjne online dla dzieci (8 klasa)',
  },
  [urls.download.root]: {
    description:
      'Darmowe materiały dla dzieci do wydrukowania. Pobierz plany lekcji, kolorowanki, zadania matematyczne i zabawy wspierające naukę j. polskiego.',
    title: 'eduGry – darmowe materiały dla dzieci do wydrukowania',
  },
  [`${urls.subjects.root}/${urls.subjects.math}`]: {
    description: 
      `Odkryj gry matematyczne online (klasy 1–8), które pomogą dziecku uzyskać lepsze wyniki z matematyki oraz uwierzyć we własne siły!`,
    title: `Sprawdzone gry matematyczne online dla dzieci – klasy 1–8 askldmaklsdmklams`,
  },
  [`${urls.subjects.root}/${urls.subjects.math}/${urls.apps.matlandia}`]: {
    description: 
      `Odkryj sprawdzoną grę matematyczną online (klasy 4, 5, 6, 7 i 8), która pomoże dziecku uzyskać lepsze wyniki z matematyki oraz uwierzyć we własne siły!`,
    title: `Matematyczna gra edukacyjna dla dzieci (online) – klasy 4–8`,
  },
  [`${urls.subjects.root}/${urls.subjects.math}/${urls.apps.matlandiaJunior}`]: {
    description: 
      `Odkryj sprawdzone zadania matematyczne dla 6-latka, 7-latka, 8-latka i 9-latka (klasy 1, 2 i 3). Zobacz, jak wygląda nauka poprzez zabawę!`,
    title: `Matematyczna gra edukacyjna dla dzieci (online) – klasy 1–3`,
  },
  [`${urls.subjects.root}/${urls.subjects.math}/${urls.apps.powtorkomat}`]: {
    description: 
      `Poznaj sprawdzone gry matematyczne online z zadaniami powtórkowymi dla 6-klasisty oraz przed egzaminem ósmoklasisty!`,
    title: `Zadania powtórkowe z matematyki: klasy 6 i 8 | eduGry`,
  },
  [`${urls.subjects.root}/${urls.subjects.polish}`]: {
    description: 
      `Poznaj atrakcyjne ćwiczenia ortograficzne online dla 9-latka, 10-latka, 11-latka i 12-latka. Zobacz, jak wygląda nauka poprzez zabawę!`,
    title: `Sprawdzone ćwiczenia z języka polskiego dla dzieci | eduGry`,
  },
  [`${urls.subjects.root}/${urls.subjects.polish}/${urls.apps.wladcySlow}`]: {
    description: 
      `Odkryj atrakcyjne gry ortograficzne online (klasy 4–6), które pomogą dziecku uzyskać lepsze wyniki z języka polskiego i uwierzyć we własne siły!`,
    title: `Sprawdzone ćwiczenia ortograficzne online dla dzieci | eduGry`,
  },
  [`${urls.subjects.root}/${urls.subjects.informatics}`]: {
    description: 
      `Poznaj animowane ćwiczenia z kodowania dla dzieci (online). Zobacz, jak wygląda nauka poprzez zabawę dla dzieci w wieku 6-9 lat!`,
    title: `Nauka programowania dla dzieci | eduGry`,
  },
  [`${urls.schoolClass}-4/${urls.apps.matlandia}-4`]: {
    description:
      'Poznaj sprawdzone zadania matematyczne (klasa 4), które pomogą dziecku uzyskać lepsze wyniki z matematyki oraz uwierzyć we własne siły!',
    title: 'Matematyczna gra edukacyjna dla dzieci (online) – klasa 4',
  }, 
  [`${urls.schoolClass}-5/${urls.apps.matlandia}-5`]: {
    description:
      'Poznaj sprawdzone zadania matematyczne (klasa 5), które pomogą dziecku uzyskać lepsze wyniki z matematyki oraz uwierzyć we własne siły!',
    title: 'Matematyczna gra edukacyjna dla dzieci (online) – klasa 5',
  }, 
  [`${urls.schoolClass}-6/${urls.apps.matlandia}-6`]: {
    description:
      'Poznaj sprawdzone zadania matematyczne (klasa 6), które pomogą dziecku uzyskać lepsze wyniki z matematyki oraz uwierzyć we własne siły!',
    title: 'Matematyczna gra edukacyjna dla dzieci (online) – klasa 6',
  }, 
  [`${urls.schoolClass}-7/${urls.apps.matlandia}-7`]: {
    description:
      'Poznaj sprawdzone zadania matematyczne (klasa 7), które pomogą dziecku uzyskać lepsze wyniki z matematyki oraz uwierzyć we własne siły!',
    title: 'Matematyczna gra edukacyjna dla dzieci (online) – klasa 7',
  }, 
  [`${urls.schoolClass}-8/${urls.apps.matlandia}-8`]: {
    description:
      'Poznaj sprawdzone zadania matematyczne (klasa 8), które pomogą dziecku uzyskać lepsze wyniki z matematyki oraz uwierzyć we własne siły!',
    title: 'Matematyczna gra edukacyjna dla dzieci (online) – klasa 8',
  },
  [`${urls.schoolClass}-6/${urls.apps.powtorkomat}-6`]: {
    description:
      'Poznaj sprawdzone zadania matematyczne (klasa 8), które pomogą dziecku uzyskać lepsze wyniki z matematyki oraz uwierzyć we własne siły!',
    title: 'Matematyczna gra edukacyjna dla dzieci (online) – klasa 8',
  }, 
  [`${urls.schoolClass}-8/${urls.apps.powtorkomat}-8`]: {
    description:
      'Odkryj interaktywne zadania powtórkowe oraz zadania egzaminacyjne z matematyki (klasa 8), które pomogą dziecku przygotować się do egzaminu ósmoklasisty!',
    title: 'Zadania powtórkowe do egzaminu ósmoklasisty – matematyka',
  },
  [`${urls.schoolClass}-1/${urls.apps.informatyka}-1`]: {
    description:
      'Zobacz, jak wygląda dostosowana do wieku interakwtyna nauka programowania dla dzieci oraz nauka pisania na klawiaturze dla dzieci w wieku 6 i 7 lat.',
    title: 'Nauka programowania, kodowanie dla dzieci (online) |  eduGry',
  }, 
  [`${urls.schoolClass}-2/${urls.apps.informatyka}-2`]: {
    description:
      'Zobacz, jak wygląda dostosowana do wieku interaktywna nauka programowania dla dzieci oraz nauka pisania na klawiaturze dla dzieci w wieku 7 i 8 lat.',
    title: 'Nauka programowania, kodowanie dla dzieci (online) | eduGry',
  },
  [`${urls.schoolClass}-3/${urls.apps.informatyka}-3`]: {
    description:
      'Zobacz, jak wygląda dostosowana do wieku interaktywna nauka programowania dla dzieci oraz nauka pisania na klawiaturze dla dzieci w wieku 8 i 9 lat.',
    title: 'Nauka programowania, kodowanie dla dzieci (online) | eduGry',
  },                
};
