export const urls = {
  articles: {
    creativeIdeas: '4-kreatywne-pomysły-na-ferie-w-domu',
    root: 'porady-dla-rodzicow',
    spendingTimeWithChild: 'dlaczego-warto-spedzac-czas-z-dzieckiem',
    waysOfMotivatingChild: '3-sposoby-na-zmotywowanie-dziecka-do-nauki',
  },
  schoolClass: 'klasa',
  contact: 'kontakt',
  download: {
    coloring: 'kolorowanki',
    excersises: 'cwiczenia',
    lessonPlan: 'plan-lekcji',
    root: 'do-wydrukowania',
  },
  largeFamily: {
    root: 'karta-duzej-rodziny',
    statute: 'regulamin',
  },
  subjects: {
    root: 'przedmioty',
    math: 'matematyka',
    polish: 'jezyk-polski',
    history: 'historia',
    informatics: 'informatyka',
  },
  apps: {
    matlandiaJunior: 'matlandia-junior',
    matlandia: 'matlandia',
    powtorkomat: 'powtorkomat',
    wladcySlow: 'wladcy-slow',
    informatyka: 'informatyka',
  },
};
