import { breakpointSm, darkSlateBlue, white } from './variables';
import { createMuiTheme } from '@material-ui/core/styles';
import OpenSansLightTtf from 'assets/fonts/OpenSans/OpenSans-Light.ttf';
import OpenSansRegularTtf from 'assets/fonts/OpenSans/OpenSans-Regular.ttf';
import OpenSansSemiBoldTtf from 'assets/fonts/OpenSans/OpenSans-SemiBold.ttf';
import OpenSansBoldTtf from 'assets/fonts/OpenSans/OpenSans-Bold.ttf';
import RobotoSlabLightTtf from 'assets/fonts/RobotoSlab/RobotoSlab-Light.ttf';
import RobotoSlabRegularTtf from 'assets/fonts/RobotoSlab/RobotoSlab-Regular.ttf';
import RobotoSlabBoldTtf from 'assets/fonts/RobotoSlab/RobotoSlab-Bold.ttf';

const openSansLight = {
  fontDisplay: 'block',
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 300,
  src: `url(${OpenSansLightTtf}) format('truetype')`,
};

const openSansRegular = {
  fontDisplay: 'block',
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `url(${OpenSansRegularTtf}) format('truetype')`,
};

const openSansSemiBold = {
  fontDisplay: 'block',
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 600,
  src: `url(${OpenSansSemiBoldTtf}) format('truetype')`,
};

const openSansBold = {
  fontDisplay: 'block',
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 800,
  src: `url(${OpenSansBoldTtf}) format('truetype')`,
};

const robotoSlabLight = {
  fontDisplay: 'block',
  fontFamily: 'Roboto Slab',
  fontStyle: 'normal',
  fontWeight: 300,
  src: `url(${RobotoSlabLightTtf}) format('truetype')`,
};

const robotoSlabRegular = {
  fontDisplay: 'block',
  fontFamily: 'Roboto Slab',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `url(${RobotoSlabRegularTtf}) format('truetype')`,
};

const robotoSlabBold = {
  fontDisplay: 'block',
  fontFamily: 'Roboto Slab',
  fontStyle: 'normal',
  fontWeight: 600,
  src: `url(${RobotoSlabBoldTtf}) format('truetype')`,
};

const breakpoints = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
};

const containerWidths = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1365,
  xl: 1920,
};

export const theme = createMuiTheme({
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: containerWidths,
    up: key => `@media (min-width:${breakpoints[key]}px)`,
  },
  palette: {
    background: {
      default: white,
    },
  },
  typography: {
    fontFamily: [
      'Open Sans',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [
          openSansLight,
          openSansRegular,
          openSansSemiBold,
          openSansBold,
          robotoSlabLight,
          robotoSlabRegular,
          robotoSlabBold,
        ],
        a: {
          outline: 0,
        },
      },
    },
    MuiExpansionPanelSummary: {
      expandIcon: {
        '& svg': {
          fill: darkSlateBlue,
        },
      },
    },
    MuiTypography: {
      h1: {
        color: darkSlateBlue,
        fontSize: '1.65rem',
        fontWeight: '800',
        lineHeight: '1.4',

        [`@media (max-width: ${breakpointSm}px)`]: {
          fontSize: '1.5rem',
        },
      },
    },
  },
});
