import { makeStyles } from '@material-ui/styles';

export const makeArrowBackButton = makeStyles({
    arrowBack: {
        height: '43px',
        top: '90px',

        '&:hover img': {
            transform: 'scale(0.7)'
        }
    },
});
