import React from "react";
import { Helmet as ReactHelmet } from "react-helmet";
import { meta } from "common/meta";

const Helmet = ({ location }) => {

  const urlPath = location.pathname;
  const path = urlPath.substring(1);

  const { description, title } = meta[path] || meta.default;

  return (
    <ReactHelmet>
      <title>{title}</title>
      <meta name="description" content={description} />
    </ReactHelmet>
  );
};

export default Helmet;
