import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeIntroductionStyles } from 'styles/pages/Classes';
import { texts } from './texts';

const Introduction = ({ classLevel }) => {
  const { h1 } = makeIntroductionStyles();

  return (
    <Fragment>
      <Grid item lg={5} style={{ margin: 'auto' }}>
        <Typography
          className={h1}
          dangerouslySetInnerHTML={{ __html: texts.classLevels[classLevel].h1 }}
          variant="h1"
          align="center"
        />
      </Grid>
    </Fragment>
  )
};

Introduction.propTypes = {
  classLevel: PropTypes.string.isRequired,
};

export default Introduction;
