import { makeStyles } from '@material-ui/styles';
import { darkSlateBlue } from 'styles/common/variables';

const makeIntroductionStyles = makeStyles({
  h1: {
    marginTop: '65px',
    display: 'block',
  },
  h2: {
    color: darkSlateBlue,
    fontSize: '1.125rem',
    fontWeight: 600,
    lineHeight: '1.8',
    display: 'block',
  },
  h3: {
    color: darkSlateBlue,
    fontSize: '1.125rem',
    fontWeight: 900,
    lineHeight: '1.8',
    display: 'block',
  },
});

export default makeIntroductionStyles;
