import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { default as MaterialSnackbar } from '@material-ui/core/Snackbar';
import { default as MaterialSnackbarContent } from '@material-ui/core/SnackbarContent';
import { makeSnackbarStyles } from 'styles/Snackbar';

const Snackbar = ({ message, open = false, setOpen }) => {
  const handleClose = (e, reason) => {
    if (reason === 'clickaway') return;

    setOpen(false);
  };

  const { snackbarContent } = makeSnackbarStyles();

  return (
    <div>
      <MaterialSnackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        autoHideDuration={3500}
        className="success"
        open={open}
        onClose={handleClose}
      >
        <MaterialSnackbarContent
          className={snackbarContent}
          aria-describedby="client-snackbar"
          message={<span id="client-snackbar">{message}</span>}
          action={[
            <IconButton key="close" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </MaterialSnackbar>
    </div>
  );
};

export default Snackbar;
