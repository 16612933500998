export const texts = {
  title: 'Polityka wykorzystania plików Cookies',
  paragraphs: [
    {
      id: 1,
      text: `
        1. Serwis nie zbiera w&nbsp;sposób automatyczny żadnych informacji, 
        z&nbsp;wyjątkiem informacji zawartych w&nbsp;plikach cookies.
      `,
    },
    {
      id: 2,
      text: `
        2.	Pliki cookies (tzw. „ciasteczka”) stanowią dane informatyczne, 
        w&nbsp;szczególności pliki tekstowe, które przechowywane są w&nbsp;urządzeniu końcowym 
        Użytkownika Serwisu i&nbsp;przeznaczone są do korzystania ze stron internetowych Serwisu. 
        Cookies zazwyczaj zawierają nazwę strony internetowej, z&nbsp;której pochodzą, 
        czas przechowywania ich na urządzeniu końcowym oraz unikalny numer.
      `,
    },
    {
      id: 3,
      text: `
          3. Podmiotem zamieszczającym na urządzeniu końcowym Użytkownika 
          Serwisu pliki cookies oraz uzyskującym do nich dostęp jest operator 
          Serwisu GWO.PL Gdańskie Wydawnictwo Oświatowe spółka 
          z&nbsp;ograniczoną odpowiedzialnością spółka komandytowa z&nbsp;siedzibą 
          pod adresem: Gdańsk, ul. Grunwaldzka 413.
        `,
    },
    {
      id: 4,
      text: `
          4. Pliki cookies wykorzystywane są w&nbsp;celu:
          <ul>
            <li>
              a) dostosowania zawartości stron internetowych Serwisu do preferencji 
              Użytkownika oraz optymalizacji korzystania ze stron internetowych; 
              w&nbsp;szczególności pliki te pozwalają rozpoznać urządzenie 
              Użytkownika Serwisu i&nbsp;odpowiednio wyświetlić stronę internetową, 
              dostosowaną do jego indywidualnych potrzeb;
            </li>
            <li>
              b) tworzenia statystyk, które pomagają zrozumieć, 
              w&nbsp;jaki sposób Użytkownicy Serwisu korzystają ze stron internetowych, 
              co umożliwia ulepszanie ich struktury i&nbsp;zawartości;
            </li>
            <li>
              c) utrzymanie sesji Użytkownika Serwisu (po zalogowaniu), 
              dzięki której Użytkownik nie musi na każdej podstronie 
              Serwisu ponownie wpisywać loginu i&nbsp;hasła;
            </li>
          </ul>
        `,
    },
    {
      id: 5,
      text: `
          5. W&nbsp;ramach Serwisu stosowane są dwa zasadnicze rodzaje plików cookies: 
          „sesyjne” (session cookies) oraz „stałe” (persistent cookies). 
          Cookies „sesyjne” są plikami tymczasowymi, które przechowywane 
          są w&nbsp;urządzeniu końcowym Użytkownika do czasu wylogowania, 
          opuszczenia strony internetowej lub wyłączenia oprogramowania (przeglądarki internetowej). 
          „Stałe” pliki cookies przechowywane są w&nbsp;urządzeniu końcowym Użytkownika 
          przez czas określony w&nbsp;parametrach plików cookies lub do czasu ich usunięcia przez Użytkownika.
        `,
    },
    {
      id: 6,
      text: `
        6. W&nbsp;ramach Serwisu stosowane są następujące rodzaje plików cookies:
          <ul>
            <li>
              a) „niezbędne” pliki cookies, umożliwiające korzystanie 
              z&nbsp;usług dostępnych w&nbsp;ramach Serwisu, np. uwierzytelniające 
              pliki cookies wykorzystywane do usług wymagających uwierzytelniania w&nbsp;ramach Serwisu;
            </li>
            <li>
              b) pliki cookies służące do zapewnienia bezpieczeństwa, 
              np. wykorzystywane do wykrywania nadużyć w&nbsp;zakresie 
              uwierzytelniania w&nbsp;ramach Serwisu;
            </li>
            <li>
              c) „funkcjonalne” pliki cookies, umożliwiające „zapamiętanie” 
              wybranych przez Użytkownika ustawień i&nbsp;personalizację 
              interfejsu Użytkownika, np. w&nbsp;zakresie wybranego języka 
              lub regionu, z&nbsp;którego pochodzi Użytkownik, rozmiaru czcionki, 
              wyglądu strony internetowej itp.;
            </li>
          </ul>
        `,
    },
    {
      id: 7,
      text: `
          7. W&nbsp;wielu przypadkach oprogramowanie służące do przeglądania stron 
          internetowych (przeglądarka internetowa) domyślnie dopuszcza 
          przechowywanie plików cookies w&nbsp;urządzeniu końcowym Użytkownika. 
          Użytkownicy Serwisu mogą dokonać w&nbsp;każdym czasie zmiany ustawień 
          dotyczących plików cookies. Ustawienia te mogą zostać zmienione 
          w&nbsp;szczególności w&nbsp;taki sposób, aby blokować automatyczną obsługę 
          plików cookies w&nbsp;ustawieniach przeglądarki internetowej bądź 
          informować o&nbsp;ich każdorazowym zamieszczeniu w&nbsp;urządzeniu Użytkownika Serwisu. 
          Szczegółowe informacje o&nbsp;możliwości i&nbsp;sposobach obsługi plików cookies 
          dostępne są w&nbsp;ustawieniach oprogramowania (przeglądarki internetowej).
        `,
    },
    {
      id: 8,
      text: `
          8. Operator Serwisu informuje, że ograniczenia stosowania plików 
          cookies mogą wpłynąć na niektóre funkcjonalności dostępne 
          na stronach internetowych Serwisu.
        `,
    },
    {
      id: 9,
      text: `
          9. Pliki cookies zamieszczane w&nbsp;urządzeniu końcowym Użytkownika Serwisu 
          i&nbsp;wykorzystywane mogą być również przez współpracujących 
          z&nbsp;operatorem Serwisu reklamodawców oraz partnerów.
        `,
    },
  ],
};
