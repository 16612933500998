import React from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppRouter from 'AppRouter';
import { theme } from 'styles/common/theme';

const App = () => (
  <MuiThemeProvider theme={theme}>
    <React.Fragment>
      <CssBaseline />
      <AppRouter />
    </React.Fragment>
  </MuiThemeProvider>
);

export default App;
