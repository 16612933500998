import MatlandiaJuniorImage from 'assets/img/programs/zadania-matematyczne-dla-dzieci.png';
import { urls } from 'common/urls';

export const MatlandiaJunior = classLevel => ({
  programDescription: {
    paragraphs: [
      {
        id: 1,
        text: `
          Równie ważną umiejętnością, co programowanie, jest umiejętność szybkiego liczenia. <b><i>Matlandia Junior</i></b> to 
          wciągająca <b>gra matematyczna i logiczna dla dzieci</b> z <b>1 klasy</b>, <b>2 klasy</b> i <b>3 klasy</b> szkoły podstawowej 
          (chociaż dzieciaki chodzące do <b>zerówki</b> też powinny sobie z&nbsp;wieloma zadaniami doskonale poradzić!). 
          Z&nbsp;<i>Matlandią Junior</i> dzieci przećwiczą takie zagadnienia, jak <b>rozpoznawanie liczb</b>, <b>dodawanie</b>, <b>odejmowanie</b> 
          oraz <b>mnożenie i dzielenie</b> (również: <b>tabliczka mnożenia</b> i&nbsp;<b>dzielenie z&nbsp;resztą</b>). Z&nbsp;naszymi interaktywnymi 
          zadaniami łatwo też można utrwalać umiejętności z zakresu <b>osi liczbowej</b> i&nbsp;<b>cyfr rzymskich</b> czy doskonalić 
          rozpoznawanie <b>liczb parzystych i&nbsp;nieparzystych</b>. W&nbsp;<i>Matlandii Junior</i> znajdują się także praktyczne 
          zadania ćwiczące orientację przestrzenną (m.in. <b>prawa i&nbsp;lewa strona</b>, <b>kierunki świata</b>), <b>odczytywanie 
          godzin na zegarze wskazówkowym i&nbsp;elektronicznym</b>, jak również przybliżające <b>jednostki czasu</b> i&nbsp;<b>zapisywanie 
          dat</b>. W animowanych zadaniach w&nbsp;atrakcyjny dla dziecka sposób poćwiczyć można <b>obliczenia pieniężne</b>, 
          przeliczanie <b>litrów</b>, <b>kilogramów i&nbsp;dekagramów</b> oraz mierzenie <b>centymetrów i&nbsp;milimetrów</b> na linijce.
        `,
      },
      {
        id: 2,
        text: `
          W obu eduGrach <b>czytane na głos polecenia</b> ułatwiają dzieciom zrozumienie treści ćwiczeń.
        `,
      },
    ],
    title: `Matematyka dla dzieci – ${classLevel}&nbsp;klasa`,
  },
  programPresentation: {
    seeMoreLink: `/${urls.subjects.root}/${urls.subjects.math}/${urls.apps.matlandiaJunior}`,
    seeMoreLinkOnSubjectPage: `/${urls.subjects.root}/${urls.subjects.math}/${urls.apps.matlandiaJunior}`,
    classNameHeader: 'math',
    description: `Matematyka towarzyszy dzieciom na każdym kroku, a&nbsp;z <i>Matlandią Junior</i> 
          liczenie staje się wciągającą zabawą! Nasza eduGra spodoba się zarówno uzdolnionym, 
          jak i&nbsp;tym, którym nauka sprawia trudności.`,
    img: {
      alt:
        'Gry i zadania matematyczne z animowanymi bohaterami w grze edukacyjnej dla dzieci Matlandia Junior',
      src: MatlandiaJuniorImage,
    },
    price: '21 zł na rok | bezpłatna przesyłka',
    subject: 'Matematyka',
    subtitle: 'gra edukacyjna online',
    title: '<i>Matlandia Junior</i>',
  },
});
