export const Math = [
  {
    id: 1,
    paragraphs: [
      {
        id: 1,
        subheading: `Gry matematyczne dla dzieci (online)`,
        text: `<b><i>Matlandia Junior</i></b> oraz <b><i>Matlandia</i></b> to wciągające <b>gry matematyczne online</b> na komputer, które zostały 
        stworzone z myślą, że najskuteczniejsza nauka to <b>nauka poprzez zabawę</b>. <b>Zadania matematyczne</b> w naszych 
        eduGrach są dostosowane do wieku i mają zróżnicowany poziom trudności, dlatego są idealne dla <b>6&nbsp;latka</b>, 
        <b>7&nbsp;latka</b>, <b>8&nbsp;latka</b> (<i>Matlandia Junior</i>), <b>9&nbsp;latka</b>, <b>10&nbsp;latka</b>, <b>11&nbsp;latka</b>, 
        <b>12&nbsp;latka</b>, <b>13&nbsp;latka</b> oraz <b>14&nbsp;latka</b> (<i>Matlandia</i> oraz <i>PowtórkoMat</i>). 
        `,
      },
      {
        id: 2,
        subheading: null,
        text: `Każda część <i>Matlandii</i> pomaga dzieciom bezstresowo przećwiczyć zagadnienia, których znajomości 
        wymaga się w szkole podstawowej. Nasze gry matematyczne online podzielone są na działy i tematy, dzięki 
        czemu łatwo odnaleźć materiał, który chce się przećwiczyć.
        `,
      },
      {
        id: 3,
        subheading: null,
        text: `W aplikacjach można udoskonalić swoje umiejętności rachunkowe z zakresu <b>dodawania i&nbsp;odejmowania</b> 
        oraz <b>mnożenia i&nbsp;dzielenia</b> (również: tabliczka mnożenia i&nbsp;dzielenie z&nbsp;resztą). Z&nbsp;<i>Matlandią</i> dziecko w&nbsp;atrakcyjny 
        sposób utrwala sobie <b>kolejność wykonywania działań</b> oraz ćwiczy rozpoznawanie <b>liczb parzystych i&nbsp;nieparzystych</b>. 
        Interaktywne zadania pozwolą na przyjemną powtórkę wiedzy z&nbsp;zakresu <b>liczb dodatnich i&nbsp;liczby ujemnych</b> czy <b>osi 
        liczbowej</b>. Z&nbsp;animowanymi bohaterami naszych eduGier dziecko pozna i&nbsp;opanuje <b>cyfry rzymskie</b>, <b>system dziesiątkowy</b>, 
        <b>ułamki zwykłe i&nbsp;dziesiętne</b>. Powtórki materiału zawierającego <b>potęgi, procenty</b> oraz <b>pierwiastki</b> staną się 
        kształcącą zabawą. Matlandiowe gry matematyczne online obejmują również zagadnienia z&nbsp;geometrii i&nbsp;ćwiczenia 
        związane z <b>polami i&nbsp;obwodami figur geometrycznych</b> (pole trójkąta, pole prostokąta, pole trapezu, pole równoległoboku, 
        pole rombu). Dzieci odnajdą tu zadania utrwalające wiedzę na temat  <b>graniastosłupów</b> (siatki graniastosłupów, pole 
        powierzchni graniastosłupa, objętość graniastosłupa) i&nbsp;<b>ostrosłupów</b> (siatki ostrosłupów, pole powierzchni ostrosłupa, 
        objętość ostrosłupa). W&nbsp;zależności od poziomu, program zawiera również ćwiczenia odnośnie takich zagadnień 
        jak  <b>wyrażenia algebraiczne, równania, wyrażenia arytmetyczne</b>, czy <b>rachunek prawdopodobieństwa</b>. Animowane 
        zadania uławiają zrozumienie oraz utrwalenie wiedzy z&nbsp;zakresu <b>twierdzenia Pitagorasa</b>, <b>liczby Pi</b> oraz <b>symetrii</b>. 
        W&nbsp;serii <i>Matlandia</i> znaleźć można wiele zadań koncentrujących się na używaniu matematyki na co dzień. Gry 
        matematyczne online w formie interaktywnych ćwiczeń udoskonalają umiejętność <b>obliczeń pieniężnych</b>, 
        <b>odczytywania godzin na zegarze wskazówkowym i&nbsp;elektronicznym</b>, operowania <b>jednostkami czasu</b> oraz 
        <b>zapisywania dat</b>. W&nbsp;grze znajdują się również takie tematy jak <b>skala i&nbsp;plan</b>, <b>prawa i&nbsp;lewa strona</b>, <b>kierunki 
        świata</b>, a&nbsp;także zadania skłaniające do operowania różnymi miarami takimi jak <b>litry</b>, <b>kilogramy i&nbsp;dekagramy</b>, 
        <b>centymetry i&nbsp;milimetry</b>.
        `,
      },
      {
        id: 4,
        subheading: `Przygotowanie do egzaminu ósmoklasisty`,
        text: `EduGra <b><i>PowtórkoMat</i></b> to <b>interaktywne powtórki z matematyki</b>. Programy podzielone są na dwie części. 
        W pierwszej części <i>PowtórkoMatu</i> dzieci mogą rozwiązywać <b>ćwiczenia powtórzeniowe z matematyki</b>, które 
        zawierają wskazówki lub <b>filmy z rozwiązaniami krok po kroku</b>. Druga część to <b>testy sprawdzające</b> zdobytą wiedzę. 
        <i>PowtórkoMat 8</i> zawiera zadania powtórkowe do egzaminu ósmoklasisty oraz próbne zestawy zadań przygotowanych 
        na wzór prawdziwego egzaminu.
        `,
      },
    ],
    title: ``,
  },
];
