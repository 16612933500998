import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeProgramDescriptionSectionStyles } from 'styles/ProgramDescriptionSection';

const ProgramDescriptionSection = ({
  paragraphs,
  descriptionImage,
  showDescriptionImage,
  title,
}) => {
  const {
    container,
    description,
    h3,
    h4,
    image,
    imageWrapper,
    maxWidth,
  } = makeProgramDescriptionSectionStyles();

  return (
    <section className={container}>
      {showDescriptionImage ? (
        <Box className={imageWrapper}>
          <img className={image} src={descriptionImage} alt="" />
        </Box>
      ) : null}
      <Box className={maxWidth}>
        <Typography
          component="h3"
          className={h3}
          variant="h1"
          dangerouslySetInnerHTML={{ __html: title }}
        ></Typography>
        {paragraphs.map(({ id, text, subheading }) => (
          <Box key={id}>
          <Typography
          component="h3"
          className={h4}
          variant="h1"
          dangerouslySetInnerHTML={{ __html: subheading }}
          ></Typography>
          <Typography
          component="p"
          className={description}
          dangerouslySetInnerHTML={{ __html: text }}
          ></Typography>
        </Box>
        ))}
      </Box>
    </section>
  );
};

ProgramDescriptionSection.propTypes = {
  paragraphs: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};

export default ProgramDescriptionSection;
