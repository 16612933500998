import React, { Fragment } from 'react';
import { FacebookIcon, FacebookShareButton, TwitterIcon, TwitterShareButton } from 'react-share';
import Box from '@material-ui/core/Box';
import { Grid } from '@material-ui/core';
import { makeSocialPanelStyles } from 'styles/pages/Article';

const SocialPanel = ({ direction = 'left' }) => {
  const path = window.location.href;
  const { shareButton, socialPanel, socialPanelWrapper } = makeSocialPanelStyles();

  return (
    <Fragment>
      <Grid item xs={12}>
        <Box className={`${socialPanelWrapper} ${direction}`}>
          <Box className={socialPanel}>
            <FacebookShareButton url={path} className={shareButton}>
              <FacebookIcon size={25}></FacebookIcon>
            </FacebookShareButton>
            <TwitterShareButton url={path} className={shareButton}>
              <TwitterIcon size={25}></TwitterIcon>
            </TwitterShareButton>
          </Box>
        </Box>
      </Grid>
    </Fragment>
  );
};

export default SocialPanel;
