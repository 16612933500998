import React from 'react';
import { BrowserRouter as Router, Route, Switch, } from 'react-router-dom';
import {
  Advices,
  Article,
  Classes,
  Subjects,
  Contact,
  Download,
  Home,
  LargeFamily,
  LargeFamilyStatute,
  Materials,
  Subject,
  Application,
  ApplicationClassSelection
} from 'routes';
import Footer from 'components/Footer';
import Header from 'components/Header';
import Helmet from 'components/Helmet';
import MainImage from 'components/MainImage';
import NotFoundPage from 'pages/NotFound';
import ScrollToTop from 'components/ScrollToTop';
import { makeMainStyles } from 'styles/Main';
import { urls } from 'common/urls';
import ArrowBackButton from "./components/ArrowBackButton";

const AppRouter = () => {
  const subjectsPaths = 'matematyka|jezyk-polski|informatyka';
  const { main } = makeMainStyles();

  const { articles, contact, download, schoolClass, subjects, apps } = urls;

  return (
    <Router>
      <ScrollToTop>
        <Header />
        <main className={main}>
          <Route path="/" component={Helmet} />
          <Route path="/" exact component={MainImage} />
          <Route path="/:anything" component={ArrowBackButton} />
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path={`/${contact}`} exact component={Contact} />
            <Route path={`/${download.root}`} exact component={Download} />
            <Route path={`/${download.root}/:type`} exact component={Materials} />
            <Route path={`/${articles.root}`} exact component={Advices} />
            <Route path={`/${articles.root}/:title`} exact component={Article} />
            <Route path={`/${schoolClass}-:classNumber([1-8])`} exact component={Classes} />
            <Route path={`/${subjects.root}`} exact component={Subjects} />
            <Route path={[
              `/${subjects.root}/:application(${apps.informatyka})`,
              `/${subjects.root}/${subjects.math}/:application(${apps.matlandia}|${apps.powtorkomat})`
            ]} exact component={ApplicationClassSelection} />
            <Route path={`/${subjects.root}/:subject(${subjectsPaths})`} exact component={Subject} />
            <Route path={[
              `/${subjects.root}/${subjects.math}/${apps.matlandiaJunior}`,
              `/${subjects.root}/${subjects.polish}/${apps.wladcySlow}`,
              `/${schoolClass}-:classNumber(4)/${apps.matlandia}-(4)`,
              `/${schoolClass}-:classNumber(5)/${apps.matlandia}-(5)`,
              `/${schoolClass}-:classNumber(6)/${apps.matlandia}-(6)`,
              `/${schoolClass}-:classNumber(7)/${apps.matlandia}-(7)`,
              `/${schoolClass}-:classNumber(8)/${apps.matlandia}-(8)`,
              `/${schoolClass}-:classNumber(6)/${apps.powtorkomat}-(6)`,
              `/${schoolClass}-:classNumber(7)/${apps.powtorkomat}-(7)`,
              `/${schoolClass}-:classNumber(8)/${apps.powtorkomat}-(8)`,
              `/${schoolClass}-:classNumber(1)/${apps.informatyka}-(1)`,
              `/${schoolClass}-:classNumber(2)/${apps.informatyka}-(2)`,
              `/${schoolClass}-:classNumber(3)/${apps.informatyka}-(3)`,
            ]} exact component={Application} />
            <Route path={`/${urls.largeFamily.root}`} exact component={LargeFamily} />
            <Route
              path={`/${urls.largeFamily.root}/${urls.largeFamily.statute}`}
              exact
              component={LargeFamilyStatute}
            />
            <Route component={NotFoundPage} />
          </Switch>
        </main>
        <Footer />
      </ScrollToTop>
    </Router>
  );
};

export default AppRouter;
