import React from 'react';
import PropTypes from 'prop-types';
import { ArticleContent, Introduction, SocialPanel } from 'pages/Article';
import Container from '@material-ui/core/Container';
import { Grid } from '@material-ui/core';
import { getUrlFragment } from 'common/getUrlFragment';

const Article = ({ location }) => {
  const articleTitle = getUrlFragment(location, 2);

  return (
    <Container>
      <Grid container justify="center">
        <Grid item xs={11}>
          <Introduction articleTitle={articleTitle} />
          <SocialPanel location={location} />
          <ArticleContent articleTitle={articleTitle} />
        </Grid>
      </Grid>
    </Container>
  );
};

Article.propTypes = {
  location: PropTypes.object.isRequired,
};

export default Article;
