import React, { Fragment } from 'react';
import { Introduction } from 'pages/Application';
import { ShortDescription, Gallery, ProgramDescriptionSection } from 'pages/Application';
import Container from '@material-ui/core/Container';
import { Grid } from '@material-ui/core';
import { getUrlLastFragment } from 'common/getUrlFragment';
import PackageDiscount from 'components/PackageDiscount';
import Wrapper from 'components/Wrapper';
import Typography from "@material-ui/core/Typography";
import { texts } from "pages/Application/texts";
import { makeButtonsSectionStyles, makeShortDescriptionStyles } from "styles/pages/Application";
import { makeButtonStyles } from 'styles/Button';
import Advantages from 'pages/Application/Advantages';
import Box from "@material-ui/core/Box";
import arrowBackIcon from "assets/img/strzalka-link.svg";

const Application = ({ location }) => {
  const { description, advantagesDescriptionTitle } = makeShortDescriptionStyles();
  const { linksWrapper, link, violetLight, violetDark, textLink } = makeButtonsSectionStyles();
  const { buttonPrimary, }  = makeButtonStyles();
  const applicationName = getUrlLastFragment(location);

  return (
    <Fragment>
      <Container>
        <Grid container justify="center">
          <Grid item xs={8}>
            <Box mb={3}>
              <Introduction application={applicationName} />
            </Box>
            <ShortDescription application={applicationName} />
            {texts.apps[applicationName].pdfButton &&
              <Box mt={4}>
                <a
                  className={`${textLink}`}
                  href={texts.apps[applicationName].pdfButton}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Zobacz działy i tematy dostępne w grze
                  <img alt="back" src={arrowBackIcon} />
                </a>
              </Box>
            }
            <Gallery gallery={ texts.apps[applicationName].gallery } />
            <Typography
              className={advantagesDescriptionTitle}
              dangerouslySetInnerHTML={{ __html: texts.apps[applicationName].advantagesDescriptionTitle }}
              variant="h1"
              component="h2"
              align="center"
            />
            <Typography
              className={description}
              dangerouslySetInnerHTML={{ __html: texts.apps[applicationName].advantagesDescription }}
              component="p"
              align="left"
            />
            {texts.apps[applicationName].reviewLink &&
            <Box mt={4}>
              <a
                className={`${textLink}`}
                href={texts.apps[applicationName].reviewLink}
                rel="noopener noreferrer"
                target="_blank"
              >
                Zobacz recenzję gry
                <img alt="back" src={arrowBackIcon} />
              </a>
            </Box>
            }
            <Box mt={9} mb={6}>
              <Advantages advantages={ texts.apps[applicationName].advantages } />
            </Box>
            <Box className={linksWrapper}>
              <a
                className={`${buttonPrimary} ${link} ${violetLight}`}
                href={texts.apps[applicationName].demoLink}
                rel="noopener noreferrer"
                target="_blank"
              >
                Wypróbuj wersję demo
              </a>
              <a
                className={`${buttonPrimary} ${link} ${violetDark}`}
                href={texts.apps[applicationName].buyLink}
                rel="noopener noreferrer"
                target="_blank"
              >
                Kup
              </a>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Wrapper>
        <PackageDiscount />
      </Wrapper>
      <Wrapper>
        <ProgramDescriptionSection application={applicationName} />
      </Wrapper>
    </Fragment>
  );
};

export default Application;
