import React from 'react';
import { Introduction, Tiles } from 'pages/Materials';
import Container from '@material-ui/core/Container';
import { Grid } from '@material-ui/core';
import { getUrlFragment } from 'common/getUrlFragment';

const Materials = ({ location }) => {
  const path = getUrlFragment(location, 2);

  return (
    <Container>
      <Grid container justify="center">
        <Grid item xs={11}>
          <Introduction path={path} /> <Tiles path={path} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Materials;
