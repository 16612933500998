import class1 from 'assets/img/class_1.svg';
import class2 from 'assets/img/class_2.svg';
import class3 from 'assets/img/class_3.svg';
import class4 from 'assets/img/class_4.svg';
import class5 from 'assets/img/class_5.svg';
import class6 from 'assets/img/class_6.svg';
// import class7 from 'assets/img/class_7.svg';
// import class8 from 'assets/img/class_8.svg';
// import { Histlandia } from 'common/texts';
import { Informatyka } from 'common/texts';
import { Matlandia } from 'common/texts';
import { MatlandiaJunior } from 'common/texts';
import { PowtorkoMat } from 'common/texts';
import { WladcySlow } from 'common/texts';

export const texts = {
  classLevels: {
    1: {
      h1: 'Poznaj najlepsze gry edukacyjne dla 6-latka i 7-latka',
      programsDescriptions: [
        {
          id: 1,
          title: Informatyka(1).programDescription.title,
          paragraphs: Informatyka().programDescription.classLevels[1],
        },
        {
          id: 2,
          ...MatlandiaJunior(1).programDescription,
          descriptionImage: class1,
        },
      ],
      tiles: [
        {
          id: 1,
          ...Informatyka(1).programPresentation.common,
          ...Informatyka().programPresentation.classLevels[1],
        },
        {
          id: 2,
          ...MatlandiaJunior().programPresentation,
        },
      ],
    },
    2: {
      h1: 'Poznaj najlepsze gry edukacyjne dla 7-latka i 8-latka',
      programsDescriptions: [
        {
          id: 1,
          title: Informatyka(2).programDescription.title,
          paragraphs: Informatyka().programDescription.classLevels[2],
        },
        {
          id: 2,
          ...MatlandiaJunior(2).programDescription,
          descriptionImage: class2,
        },
      ],
      tiles: [
        {
          id: 1,
          ...Informatyka(2).programPresentation.common,
          ...Informatyka().programPresentation.classLevels[2],
        },
        {
          id: 2,
          ...MatlandiaJunior().programPresentation,
        },
      ],
    },
    3: {
      h1: 'Poznaj najlepsze gry edukacyjne dla 8-latka i 9-latka',
      programsDescriptions: [
        {
          id: 1,
          title: Informatyka(3).programDescription.title,
          paragraphs: Informatyka().programDescription.classLevels[3],
        },
        {
          id: 2,
          ...MatlandiaJunior(3).programDescription,
          descriptionImage: class3,
        },
      ],
      tiles: [
        {
          id: 1,
          ...Informatyka(3).programPresentation.common,
          ...Informatyka().programPresentation.classLevels[3],
        },
        {
          id: 2,
          ...MatlandiaJunior().programPresentation,
        },
      ],
    },
    4: {
      h1: 'Poznaj najlepsze gry edukacyjne dla 9-latka i 10-latka',
      programsDescriptions: [
        {
          id: 1,
          title: Matlandia(4).programDescription.title,
          paragraphs: Matlandia().programDescription.classLevels[4],
        },
        {
          id: 2,
          ...WladcySlow(4).programDescription,
          descriptionImage: class4,
        },
        // {
        //   id: 3,
        //   ...Histlandia(4).programDescription,
        // },
      ],
      tiles: [
        {
          id: 1,
          ...Matlandia(4).programPresentation.common,
          ...Matlandia().programPresentation.classLevels[4],
        },
        {
          id: 2,
          ...WladcySlow().programPresentation,
        },
        // {
        //   id: 3,
        //   ...Histlandia().programPresentation,
        // },
      ],
    },
    5: {
      h1: 'Poznaj najlepsze gry edukacyjne dla 10-latka i 11-latka',
      programsDescriptions: [
        {
          id: 1,
          title: Matlandia(5).programDescription.title,
          paragraphs: Matlandia().programDescription.classLevels[5],
        },
        {
          id: 2,
          ...WladcySlow(5).programDescription,
          descriptionImage: class5,
        },
        // {
        //   id: 3,
        //   ...Histlandia(5).programDescription,
        // },
      ],
      tiles: [
        {
          id: 1,
          ...Matlandia(5).programPresentation.common,
          ...Matlandia().programPresentation.classLevels[5],
        },
        {
          id: 2,
          ...WladcySlow().programPresentation,
        },
        // {
        //   id: 3,
        //   ...Histlandia().programPresentation,
        // },
      ],
    },
    6: {
      h1: 'Poznaj najlepsze gry edukacyjne dla 11-latka i 12-latka',
      programsDescriptions: [
        {
          id: 1,
          title: Matlandia(6).programDescription.title,
          paragraphs: Matlandia().programDescription.classLevels[6],
        },
        {
          id: 2,
          ...WladcySlow(6).programDescription,
          descriptionImage: class6,
        },
        // {
        //   id: 3,
        //   ...Histlandia(6).programDescription,
        // },
      ],
      tiles: [
        {
          id: 1,
          ...Matlandia(6).programPresentation.common,
          ...Matlandia().programPresentation.classLevels[6],
        },
        {
          id: 2,
          ...PowtorkoMat(6).programPresentation.common,
          ...PowtorkoMat().programPresentation.classLevels[6],
        },
        {
          id: 3,
          ...WladcySlow().programPresentation,
        },
        // {
        //   id: 4,
        //   ...Histlandia().programPresentation,
        // },
      ],
    },
    7: {
      h1: 'Poznaj najlepsze gry edukacyjne dla 12-latka i 13-latka',
      programsDescriptions: [
        {
          id: 1,
          title: Matlandia(7).programDescription.title,
          paragraphs: Matlandia().programDescription.classLevels[7],
        },
        // {
        //   id: 2,
        //   ...Histlandia(7).programDescription,
        //   descriptionImage: class7,
        // },
      ],
      tiles: [
        {
          id: 1,
          ...Matlandia(7).programPresentation.common,
          ...Matlandia().programPresentation.classLevels[7],
        },
        // {
        //   id: 2,
        //   ...Histlandia().programPresentation,
        // },
      ],
    },
    8: {
      h1: 'Poznaj najlepsze gry edukacyjne dla 13-latka i 14-latka',
      programsDescriptions: [
        {
          id: 1,
          title: Matlandia(8).programDescription.title,
          paragraphs: Matlandia().programDescription.classLevels[8],
        },
        // {
        //   id: 2,
        //   ...Histlandia(8).programDescription,
        //   descriptionImage: class8,
        // },
      ],
      tiles: [
        {
          id: 1,
          ...Matlandia(8).programPresentation.common,
          ...Matlandia().programPresentation.classLevels[8],
        },
        {
          id: 2,
          ...PowtorkoMat(8).programPresentation.common,
          ...PowtorkoMat().programPresentation.classLevels[8],
        },
        // {
        //   id: 3,
        //   ...Histlandia().programPresentation,
        // },
      ],
    },
  },
};
