import coloring from 'assets/img/do-pobrania-kolorowanki.png';
import lessonPlan from 'assets/img/do-pobrania-plan-lekcji.png';
import exercises from 'assets/img/do-pobrania-cwiczenia.png';
import { urls } from 'common/urls';

const { download } = urls;

export const texts = {
  introduction: {
    h1: 'Darmowe materiały edukacyjne dla Twojego dziecka',
    h2: `Tutaj znajdziesz bezpłatne materiały edukacyjne dla dzieci – do pobrania 
    i&nbsp;wydruku: m.in. plan lekcji, kolorowanki oraz zabawy wspierające naukę 
    matematyki, języka polskiego i&nbsp;historii.`,
  },
  tiles: [
    {
      id: 1,
      img: {
        alt: 'Plan lekcji do pobrania',
        src: lessonPlan,
      },
      link: {
        text: 'Plan lekcji',
        to: `${download.root}/plan-lekcji`,
      },
    },

    {
      id: 2,
      img: {
        alt: 'Kolorowanki do pobrania',
        src: coloring,
      },
      link: {
        text: 'Kolorowanki',
        to: `${download.root}/kolorowanki`,
      },
    },
    {
      id: 3,
      img: {
        alt: 'Ćwiczenia do pobrania',
        src: exercises,
      },
      link: {
        text: 'Ćwiczenia',
        to: `${download.root}/cwiczenia`,
      },
    },
  ],
};
