import { makeStyles } from '@material-ui/styles';
import { breakpointMd, darkSlateBlue } from 'styles/common/variables';

const makeStatuteStyles = makeStyles({
  h1: {
    margin: '65px 0 40px',
    fontSize: '1.25rem',
    textAlign: 'center',
  },
  h2: {
    marginTop: '25px',
    textAlign: 'center',
  },
  paragraph: {
    color: darkSlateBlue,
    fontSize: '.875rem',
    fontWeight: 300,
    lineHeight: '1.6',
    margin: '15px auto 0',
    width: '70%',

    '& a': {
      color: darkSlateBlue,
    },

    [`@media (max-width: ${breakpointMd}px)`]: {
      width: '100%',
    },
  },
  statuteWrapper: {
    color: darkSlateBlue,
    marginBottom: '100px',
  },
});

export default makeStatuteStyles;
