import advicesPageImage from 'assets/img/porady-dla-rodzicow.png';
import articleCreativeIdeasImage from 'assets/img/articles/4-kreatywne-pomysły-na-ferie-w-domu.png';
import articleSpendingTimeWithChildImage from 'assets/img/articles/dlaczego-warto-spedzac-czas-z-dzieckiem.png';
import articleWaysOfMotivatingChildImage from 'assets/img/articles/3-sposoby-na-zmotywowanie-dziecka-do-nauki.png';
import class1PageImage from 'assets/img/gry-edukacyjne-dla-6-latka-7-latka.png';
import class2PageImage from 'assets/img/gry-edukacyjne-dla-7-latka-8-latka.png';
import class3PageImage from 'assets/img/gry-edukacyjne-dla-8-latka-9-latka.png';
import class4PageImage from 'assets/img/gry-edukacyjne-dla-9-latka-10-latka.png';
import class5PageImage from 'assets/img/gry-edukacyjne-dla-10-latka-11-latka.png';
import class6PageImage from 'assets/img/gry-edukacyjne-dla-11-latka-12-latka.png';
import class7PageImage from 'assets/img/gry-edukacyjne-dla-12-latka-13-latka.png';
import class8PageImage from 'assets/img/gry-edukacyjne-dla-13-latka-14-latka.png';
import downloadPageImage from 'assets/img/materialy-do-pobrania.png';
import mathPageImage from 'assets/img/matematyka.png';
import homePageImage from 'assets/img/gry-edukacyjne-online-dla-dzieci.png';
import informaticsPageImage from 'assets/img/informatyka.png';
import historyPageImage from 'assets/img/historia.png';
import polishPageImage from 'assets/img/jezyk-polski.png';
import subjectsPageImage from 'assets/img/edukacyjne-gry-dla-dzieci.png';
import { urls } from 'common/urls';

const { articles, schoolClass, download, subjects } = urls;

export const texts = {
  '': {
    img: {
      alt:
        'Dziewczynka gra na komputerze w gry edukacyjne online dla dzieci i uczy się w towarzystwie animowanych bohaterów eduGier',
      src: homePageImage,
    },
        title: `<span class="mainImage"">
            <h1>Poznaj najlepsze gry edukacyjne online dla dzieci</h1>
            <h2>Nasze eduGry to animowane zadania z <span style="font-weight:800;">matematyki, języka polskiego</span> oraz <span style="font-weight:800;">informatyki</span> dla dzieci z klas 1–8 (wiek 6–14 lat). Każda eduGra zamienia naukę w pełną emocji zabawę i pomaga uzyskać lepsze wyniki.</h2>
        </span>`,
  },
  [download.root]: {
    className: 'font-bold',
    img: {
      alt:
        'Dzieci pod opieką mamy odrabiają lekcje z pomocą gier edukacyjnych online dla dzieci eduGry',
      src: downloadPageImage,
    },
    title: 'Do pobrania',
  },
  [`${schoolClass}-1`]: {
    className: 'font-bold',
    img: {
      alt:
        '7-latki grają na komputerze w gry edukacyjne online dla dzieci i uczą się w towarzystwie animowanych bohaterów eduGier',
      src: class1PageImage,
    },
    title: '1 klasa',
  },
  [`${schoolClass}-2`]: {
    className: 'font-bold',
    img: {
      alt:
        '8-latek gra na komputerze w gry edukacyjne online dla dzieci i uczy się w towarzystwie animowanych bohaterów eduGier',
      src: class2PageImage,
    },
    title: '2 klasa',
  },
  [`${schoolClass}-3`]: {
    className: 'font-bold',
    img: {
      alt:
        '9-latka gra na komputerze w gry edukacyjne online dla dzieci i uczy się w towarzystwie animowanych bohaterów eduGier',
      src: class3PageImage,
    },
    title: '3 klasa',
  },
  [`${schoolClass}-4`]: {
    className: 'font-bold',
    img: {
      alt:
        '10-latka gra na komputerze w gry edukacyjne online dla dzieci i uczy się w towarzystwie animowanych bohaterów eduGier',
      src: class4PageImage,
    },
    title: '4 klasa',
  },
  [`${schoolClass}-5`]: {
    className: 'font-bold',
    img: {
      alt:
        '11-latek gra na komputerze w gry edukacyjne online dla dzieci i uczy się w towarzystwie animowanych bohaterów eduGier',
      src: class5PageImage,
    },
    title: '5 klasa',
  },
  [`${schoolClass}-6`]: {
    className: 'font-bold',
    img: {
      alt:
        '12-latek gra na komputerze w gry edukacyjne online dla dzieci i uczy się w towarzystwie animowanych bohaterów eduGier',
      src: class6PageImage,
    },
    title: '6 klasa',
  },
  [`${schoolClass}-7`]: {
    className: 'font-bold',
    img: {
      alt:
        '13-latka gra na komputerze w gry edukacyjne online dla dzieci i uczy się w towarzystwie animowanych bohaterów eduGier',
      src: class7PageImage,
    },
    title: '7 klasa',
  },
  [`${schoolClass}-8`]: {
    className: 'font-bold',
    img: {
      alt:
        '14-latek gra na komputerze w gry edukacyjne online dla dzieci i uczy się w towarzystwie bohaterów eduGier',
      src: class8PageImage,
    },
    title: '8 klasa',
  },
  noMatches: null,
  [articles.creativeIdeas]: {
    className: 'font-bold',
    img: {
      alt: '',
      src: articleCreativeIdeasImage,
    },
    title: 'Do poczytania',
  },
  [articles.spendingTimeWithChild]: {
    className: 'font-bold',
    img: {
      alt: '',
      src: articleSpendingTimeWithChildImage,
    },
    title: 'Do poczytania',
  },
  [articles.waysOfMotivatingChild]: {
    className: 'font-bold',
    img: {
      alt: '',
      src: articleWaysOfMotivatingChildImage,
    },
    title: 'Do poczytania',
  },
  [articles.root]: {
    className: 'font-bold',
    img: {
      alt:
        'Dziewczynka pod opieką mamy gra na komputerze w gry edukacyjne online dla dzieci eduGry',
      src: advicesPageImage,
    },
    title: 'Do poczytania',
  },
  [subjects.root]: {
    className: 'font-bold',
    img: {
      alt:
        'Dziewczynka pod opieką mamy gra na komputerze w gry edukacyjne online dla dzieci eduGry',
      src: subjectsPageImage,
    },
    title: 'Przedmioty',
  },
  [subjects.math]: {
    className: 'font-bold',
    img: {
      alt: 'Matematyka',
      src: mathPageImage,
    },
    title: 'Matematyka',
  },
  [subjects.history]: {
    className: 'font-bold',
    img: {
      alt: 'Historia',
      src: historyPageImage,
    },
    title: 'Historia',
  },
  [subjects.polish]: {
    className: 'font-bold',
    img: {
      alt: 'Język polski',
      src: polishPageImage,
    },
    title: 'Język polski',
  },
  [subjects.informatics]: {
    className: 'font-bold',
    img: {
      alt: 'Informatyka',
      src: informaticsPageImage,
    },
    title: 'Informatyka',
  },
};
